import React from "react";
import { Text, View } from "react-native";
import Style from "src/globalStyles";
import Message from "src/components/MessageHistoryItem";

export default function MessageHistory({
  history,
  navigation,
  latestMessageJustUpdated = false,
  isNotifications = false
}) {
  return (
    <>
      <Text style={Style.get("headerText")}>
        {isNotifications ? "Notification" : "Message"} History
      </Text>
      <View
        style={{
          marginTop: 3,
          paddingBottom: 20,
          borderTopWidth: 1,
          borderTopColor: "black"
        }}
      >
        {Object.keys(history || {})?.length < 1 ? (
          <Text
            style={{
              textAlign: "center",
              marginTop: 30,
              color: "gray"
            }}
          >
            No messages sent yet.{"\n"}Try sending one!
          </Text>
        ) : (
          <>
            {Object.values(history || {})
              .sort((a, b) => {
                const aVal =
                  a.timestamp && new Date(a.timestamp)
                    ? new Date(a.timestamp).valueOf()
                    : a.key;
                const bVal =
                  b.timestamp && new Date(b.timestamp)
                    ? new Date(b.timestamp).valueOf()
                    : b.key;
                return bVal - aVal;
              })
              .map((msg, idx) => (
                <Message
                  key={msg.key}
                  msg={msg}
                  navigation={navigation}
                  isNotifications={isNotifications}
                  justUpdated={latestMessageJustUpdated && idx === 0}
                />
              ))}
          </>
        )}
      </View>
    </>
  );
}
