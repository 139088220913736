import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import UserList from "src/components/UserList";

export default function SelectUsersForChat({ navigation }) {
  useEffect(() => {
    Analytics.logEvent("view_selectUsersForChat");
  }, []);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="New Message" />
      <UserList
        navigation={navigation}
        hideInvitations
        hideTopSummary
        shouldShowShareOptions={false}
        onPress={({ user }) => {
          navigation.push("notificationDetails", { chatRecipient: user });
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
