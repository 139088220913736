import React, { useState, useEffect } from "react";
import { StyleSheet, View, ScrollView, Text } from "react-native";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import AlertModal from "src/components/AlertModal";
import StatusMessage from "src/components/StatusMessage";
import HelpText from "src/components/HelpText";
import UserEditor from "src/components/UserEditor";
import WebPageMetaTitle from "src/components/WebPageMetaTitle";

const { width } = Glob.get("dimensions");

const TopContent = ({ user }) => {
  if (user.isInvitation)
    return (
      <>
        <View style={{ alignItems: "center" }}>
          <View
            style={{
              borderColor: Glob.get("onespotOrange"),
              borderWidth: 1,
              borderRadius: 5,
              paddingHorizontal: 8,
              paddingVertical: 2,
              marginVertical: 10,
              alignItems: "center"
            }}
          >
            <Text style={{ fontSize: 16, color: Glob.get("onespotOrange") }}>
              Invited
              {user?.invitedAtTimestamp
                ? `: ${Util.friendlyDate(user.invitedAtTimestamp, {
                    isLong: true
                  })}`
                : ""}
            </Text>
          </View>
        </View>
        <HelpText
          text="This user received an invitation code via email, but they haven't yet activated their account. Once they join, this account will become theirs. In the meantime, they'll still receive any relevant email notifications you send."
          noteStyle
        />
      </>
    );
  const userSilencedNotifications =
    !!user?.notifications?.preferences &&
    !user?.notifications?.preferences?.receivePushNotifications;
  const userDevices = user?.devices;
  const hasDeviceRegisteredForNotifications =
    !!userDevices &&
    Object.values(userDevices).some((device) => "pushToken" in device);
  const userIsRegisteredForNotifications =
    (hasDeviceRegisteredForNotifications || user?.pushToken) &&
    !userSilencedNotifications;
  return (
    <>
      {userIsRegisteredForNotifications ? (
        <StatusMessage
          type="success"
          message="User is registered for push notifications"
        />
      ) : (
        <StatusMessage
          type="warning"
          message={`User has ${
            userSilencedNotifications ? "silenced" : "not registered for"
          } push notifications`}
        />
      )}
      {user?.createdAtTimestamp ? (
        <Text style={[Style.get("subheaderText"), { marginVertical: 15 }]}>
          Joined this app:{" "}
          {Util.friendlyDate(user.createdAtTimestamp, { isLong: true })}
        </Text>
      ) : (
        <Text style={[Style.get("subheaderText"), { marginVertical: 15 }]}>
          Note: We don't have data on when this user joined the app.
        </Text>
      )}
      {!!user?.lastViewedRootAtTimestamp && (
        <Text
          style={[
            Style.get("subheaderText"),
            { marginBottom: 15, marginTop: 0 }
          ]}
        >
          Last opened this app:{" "}
          {Util.friendlyDate(user.lastViewedRootAtTimestamp, {
            isLong: true
          })}
        </Text>
      )}
      {user?.uid === Glob.get("demoAccountUserID") && (
        <HelpText
          text="This is a demo account used for app store review purposes. You cannot edit or message this account."
          noteStyle
        />
      )}
    </>
  );
};

export default function ManageUser({ route, navigation }) {
  const {
    params: {
      user,
      onUserUpdated = () => {},
      onUserDeleted = () => {},
      isFromChat = false,
      disableUserEditing = false,
      renderCustomTopContent = null
    } = {}
  } = route || {};
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_manageUser", {
      email: user?.email,
      uid: user?.uid
    });
  }, []);

  const screenTitle =
    user?.firstName || user?.lastName
      ? `${user?.firstName} ${user?.lastName}`
      : "Manage Member";
  return (
    <View style={styles.pageContent}>
      <WebPageMetaTitle title={screenTitle} />
      <NavBar navigation={navigation} text={screenTitle} />
      <ScrollView
        style={{ width: 0.9 * width }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        {renderCustomTopContent ? (
          renderCustomTopContent(user)
        ) : (
          <TopContent user={user} />
        )}
        <UserEditor
          user={user}
          onUserUpdated={onUserUpdated}
          onUserDeleted={onUserDeleted}
          navigation={navigation}
          isFromChat={isFromChat}
          isInvitation={user.isInvitation}
          disableEditing={
            disableUserEditing || user?.uid === Glob.get("demoAccountUserID")
          }
        />
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
