/*
 * This portal displays the content of a website, title, back button, and back/forward buttons.
 * Use the WebStatic screen if you want a portal that can't navigate back and forth within a site.
 * Content for this portal is pulled from Firebase, from portal "web_PORTALNAME" (specified in index.ios.js)
 */

import React, { useEffect, useState } from "react";
import { View, Platform } from "react-native";

import NavWebView from "src/components/navWebView";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import Firebase from "src/backend/firebase";
import Glob from "src/globalConstants";
import Util from "src/utility";

const PAGE_NOT_FOUND_URL =
  "https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NkLnasEU6mzfCJfiyQQ";

export default function WebNav({ route, navigation }) {
  const { params: { title, url, onPickURL, navName } = {} } = route || {};

  const [webTitle, setWebTitle] = useState(title);
  const [webUrl, setWebUrl] = useState(url);

  useEffect(() => {
    const userID = Firebase.getUserID();
    const portalName = navName;
    // If a title and url weren't passed, then try to fetch this info from the database
    if (!webTitle || !webUrl) {
      Database.getPortalContentNew(portalName).then((value) => {
        let formattedURL = (value.url || PAGE_NOT_FOUND_URL).trim();
        if (!formattedURL.includes("http"))
          formattedURL = `https://${formattedURL}`;
        setWebTitle(value.title);
        setWebUrl(formattedURL);
        Analytics.logEvent("view_webNav", {
          key: portalName,
          title: value.title,
          url: formattedURL
        });
        if (Platform.OS === "web" || userID === Glob.get("demoAccountUserID")) {
          Util.openURL(formattedURL);
          navigation.goBack();
        }
      });
    } else {
      let formattedURL = (url || "").trim();
      if (!formattedURL.includes("http"))
        formattedURL = `https://${formattedURL}`;
      Analytics.logEvent("view_webNav", {
        key: portalName,
        title,
        url: formattedURL,
        dataPassedFromPreviousScreen: true
      });
      if (Platform.OS === "web" || userID === Glob.get("demoAccountUserID")) {
        Util.openURL(formattedURL);
        navigation.goBack();
      }
    }
  }, []);

  // Still waiting for a response from the database
  if (!webUrl) return null;

  return (
    <View style={{ flex: 1, backgroundColor: "white" }}>
      <NavWebView
        URL={webUrl}
        title={webTitle}
        navigation={navigation}
        onPickURL={onPickURL}
      />
    </View>
  );
}
