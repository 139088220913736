import React, { useState } from "react";
import { View, Text, ActivityIndicator, Alert } from "react-native";
import * as DocumentPicker from "expo-document-picker";
import Firebase from "src/backend/firebase";
import Button from "src/components/Button";

export default function CustomDocumentPicker({ isNew, onUpload }) {
  const [isUploadingPDF, setIsUploadingPDF] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const pickDocument = (type = "application/pdf") => {
    return new Promise(async (resolve) => {
      const documents = await DocumentPicker.getDocumentAsync({ type });
      resolve(
        documents?.type === "cancel" ||
          !documents?.assets ||
          documents.assets?.length !== 1
          ? null
          : documents.assets[0]
      );
    });
  };

  const onPressUploadPDF = () => {
    pickDocument().then((document) => {
      if (document) {
        const { name, size, uri } = document;
        const sizeInMB = size / 1000000;
        if (sizeInMB <= 10) {
          setIsUploadingPDF(true);
          Firebase.uploadMedia(uri, {
            title: name,
            onProgressUpdate: setUploadProgress
          }).then((url) => {
            setIsUploadingPDF(false);
            setUploadProgress(0);
            onUpload(url, name);
          });
        } else {
          Alert.alert(
            "File too large",
            "Please upload a file smaller than 10MB."
          );
        }
      }
    });
  };

  if (isUploadingPDF)
    return (
      <View
        style={{
          alignItems: "center",
          padding: 10,
          flexDirection: "row"
        }}
      >
        <ActivityIndicator size="small" />
        <Text style={{ fontSize: 16, color: "#aaa", marginLeft: 10 }}>
          {uploadProgress}% uploaded...
        </Text>
      </View>
    );
  return (
    <Button
      small
      textStyle={{ fontWeight: "bold" }}
      align="left"
      text={isNew ? "Upload PDF" : "Replace PDF"}
      onPress={onPressUploadPDF}
    />
  );
}
