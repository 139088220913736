/* global fetch */

/**
 * @class Database
 */

// TODO: Lots of functions get the user's ID from the database and make a path.
// ...That should be done ONCE, and stored in Rex for all future uses.

import {
  getDatabase,
  ref,
  query,
  limitToLast,
  onValue,
  update,
  set,
  push,
  remove,
  off
} from "firebase/database";
import {
  updateEmail,
  reauthenticateWithCredential,
  updatePassword
} from "firebase/auth/react-native";
import Moment from "moment";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import Firebase from "src/backend/firebase";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import School from "school/school";
import iCalParser from "cal-parser";

const FIREBASE_CONFIG = Constants.expoConfig.web.config.firebase;
const IS_DEVELOPMENT_MODE = FIREBASE_CONFIG.projectId !== "seabirdmain";
const ONESPOT_MONTESSORI_ID = "montessori";

export default class Database {
  static baseURL() {
    return Firebase.baseDbRoute();
  }

  static generateUniqueID() {
    return push(ref(getDatabase(), `${this.baseURL()}`)).key;
  }

  static fetchValueOnce(appPath) {
    return new Promise((resolve) => {
      onValue(
        Firebase.getDbRef(appPath),
        (snapshot) => resolve(snapshot.val()),
        {
          onlyOnce: true
        }
      );
    });
  }

  static fetchLastValuesOnce(appPath, n = 1) {
    return new Promise((resolve) => {
      const recentPostRef = query(Firebase.getDbRef(appPath), limitToLast(n));
      onValue(
        recentPostRef,
        (snapshot) => {
          resolve(snapshot.val());
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  static apiPostAuthenticated(endpoint, body) {
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/${endpoint}`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static onespotFetchAllAppsMetadata() {
    return new Promise((resolve, reject) => {
      onValue(
        ref(getDatabase(), "/appsMetadata"),
        (snapshot) => resolve(snapshot.val()),
        {
          onlyOnce: true
        }
      );
    });
  }

  /**
   * Example appData:
   * {
   *    "name": "Seabird University",
   *    "color": "pink",
   *    "communityType": "school",
   *    "userAccountTypes": ["student", "alum"]
   * }
   */
  static onespotCreateNewApp(appData) {
    return new Promise((resolve, reject) => {
      Util.localStorageGetItemAsync("metaAppID").then((storedMetaAppID) => {
        const metaAppIDToFetch = Glob.get("metaAppID") || storedMetaAppID;
        const metaApp = metaAppIDToFetch ? { metaAppID: metaAppIDToFetch } : {};
        fetch(`${Firebase.baseAPIRoute()}/create-new-app`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          // Specify `empty` to handle legacy code (Dec 8 2022; delete eventually)
          body: JSON.stringify({ ...appData, ...metaApp, empty: true })
        })
          .then((response) => response.json())
          .then(resolve)
          .catch(reject);
      });
    });
  }

  // Fetch all template portals for a given community type
  static fetchAllTemplatePortals(communityType) {
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/get-template-portals`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ communityType })
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static fetchAppCommunityType() {
    return new Promise((resolve, reject) => {
      this.fetchAppMetadata().then((appMetadata) => {
        const { communityType } = appMetadata || {};
        // If this is a school
        if (communityType === "school") {
          // Check whether it's a Montessori school
          this.appIsInMetaApp(ONESPOT_MONTESSORI_ID).then((isInMontessori) => {
            if (isInMontessori)
              resolve(Glob.getCommunityType("montessoriSchool"));
            else resolve(Glob.getCommunityType(communityType));
          });
        } else {
          resolve(Glob.getCommunityType(communityType));
        }
      });
    });
  }

  // Test whether this passcode is the correct one for the current app ID
  static tryToUnlockApp(passcode) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/try-to-unlock-app`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ passcode, databaseAppID })
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  // This should only ever be called by users who have the "Publish" admin privilege
  static fetchSecurityInfo() {
    return this.fetchValueOnce("security");
  }

  // This should only ever be called by users who have the "Publish" admin privilege
  static setSecurityInfo(newSecurityInfo) {
    this.fetchSecurityInfo().then((securityInfo) => {
      const updates = {};
      updates[`${this.baseURL()}/security`] = {
        ...securityInfo,
        ...newSecurityInfo
      };
      return update(ref(getDatabase()), updates);
    });
  }

  // This should only ever be called by users who have the "Publish" admin privilege
  static fetchBillingInfo() {
    return this.fetchValueOnce("billing");
  }

  // This should only ever be called by users who have the "TextAndCall" admin privilege
  static subscribeToTextsAndCalls(onChange = () => {}) {
    onValue(Firebase.getDbRef("textsAndCalls"), (snapshot) =>
      onChange(snapshot.val())
    );
  }

  static unsubscribeFromTextsAndCalls() {
    off(Firebase.getDbRef("textsAndCalls"));
  }

  // This should only ever be called by users who have the "PushNotification" admin privilege
  static subscribeToGlobalNotifications(onChange = () => {}) {
    onValue(Firebase.getDbRef("notifications"), (snapshot) =>
      onChange(snapshot.val())
    );
  }

  static unsubscribeFromGlobalNotifications() {
    off(Firebase.getDbRef("notifications"));
  }

  // This should only ever be called by users who have the "TextAndCall" admin privilege
  static sendTextsAndCalls(phoneNumbers, message, shouldText, shouldCall) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise(async (resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(
            `${Firebase.baseAPIRoute()}/send-text-messages-and-phone-calls`,
            {
              method: "post",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                databaseAppID,
                commaSeparatedPhoneNumbers: (phoneNumbers || []).join(","),
                message,
                shouldText,
                shouldCall
              })
            }
          )
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static userIsSuperAdmin() {
    return Glob.get("superAdminUserIDs").includes(Firebase.getUserID());
  }

  static superAdminUpdatePhoneCredits(credits) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise(async (resolve, reject) => {
      if (!this.userIsSuperAdmin()) reject();
      else
        Firebase.getAuth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            fetch(`${Firebase.baseAPIRoute()}/update-phone-credits`, {
              method: "post",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ databaseAppID, credits })
            })
              .then((response) => response.json())
              .then(resolve)
              .catch(reject);
          })
          .catch(reject);
    });
  }

  // Fetch the total number of apps on Onespot
  static fetchTotalAppsCreated() {
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/get-total-apps-created`, {
        method: "get",
        headers: { "Content-Type": "application/json" }
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static generateScreenWithAI(messages) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/ai-generate-portal`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ messages, databaseAppID })
          })
            .then((response) => response.text())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static generateAppCreationLoadingMessageFromWebsite(website) {
    return new Promise((resolve, reject) => {
      fetch(
        `${Firebase.baseAPIRoute()}/get-app-creation-loading-message-from-website-url`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ website })
        }
      )
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static generateAppCreationLoadingMessageFromDescription(
    name,
    description,
    type
  ) {
    return new Promise((resolve, reject) => {
      fetch(
        `${Firebase.baseAPIRoute()}/get-app-creation-loading-message-from-description`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name, description, type })
        }
      )
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static onespotCreateNewAppFromWebsiteWithAI(website) {
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/create-new-app-from-website-url`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ website })
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  // Test whether this passcode is the correct one for a given account type in the current app ID
  static tryToUnlockAccountType(accountType, passcode) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/try-to-unlock-accountType`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ passcode, accountType, databaseAppID })
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static checkIfPendingUserInvitationsExist() {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      fetch(
        `${Firebase.baseAPIRoute()}/check-if-pending-user-invitations-exist`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ databaseAppID })
        }
      )
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static getUserFromInvitationCode(code) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/get-user-from-invitation-code`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ databaseAppID, code })
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static redeemUserInvitation(invitationKey) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/redeem-user-invitation`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ databaseAppID, invitationKey })
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static markUserInvitationAsUnused(email) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/mark-user-invitation-as-unused`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ databaseAppID, email })
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  // Remove the "unclaimed" global config variable so that no other users can join & gain admin privileges
  static markAppAsClaimed() {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      fetch(`${Firebase.baseAPIRoute()}/mark-app-as-claimed`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ databaseAppID })
      })
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  static fetchAllUsersAnonymized() {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/get-anonymized-users`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ databaseAppID })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  // This should only ever be called by users who have the "ManageUsers" admin privilege
  static fetchAllUsers(
    includeSuperadminsForSuperadmins = true,
    includeSuperadminsForNonSuperadmins = false
  ) {
    const userIsSuperAdmin =
      includeSuperadminsForNonSuperadmins ||
      (includeSuperadminsForSuperadmins && this.userIsSuperAdmin());
    return new Promise((resolve) => {
      onValue(
        Firebase.getDbRef("users"),
        (snapshot) => {
          const value = snapshot.val();
          if (!value) return resolve(null);
          const users = Object.entries(value)
            .map(([k, v]) => ({
              ...v,
              uid: k,
              isSuperAdmin:
                !!userIsSuperAdmin &&
                !!Glob.get("superAdminUserIDs").includes(k)
            }))
            // filter out the Seabird Apps superadmin accounts, unless you ARE a superadmin
            .filter(
              (u) =>
                userIsSuperAdmin ||
                !Glob.get("superAdminUserIDs").includes(u.uid)
            )
            .sort((a1, a2) => {
              // sort alphabetically
              if (a1.firstName < a2.firstName) return -1;
              if (a1.firstName > a2.firstName) return 1;
              return 0;
            });
          return resolve(users);
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  // This should only ever be called by users who have the "ManageUsers" admin privilege
  static fetchAllUserInvitations() {
    return new Promise((resolve) => {
      onValue(
        Firebase.getDbRef("userInvitations/users"),
        (snapshot) => {
          const invitations = snapshot.val();
          if (!invitations) return resolve(null);
          const userInvitations = Object.entries(invitations)
            .map(([k, v]) => ({ ...v, uid: k })) // note: uid is actually invitationKey
            .sort((a1, a2) => {
              // sort alphabetically
              if (a1.firstName < a2.firstName) return -1;
              if (a1.firstName > a2.firstName) return 1;
              return 0;
            });
          return resolve(userInvitations);
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  // This should only ever be called by users who have the "ManageUsers" admin privilege
  static fetchUserByID(userID) {
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(`users/${userID}`),
        (snapshot) => {
          return resolve(snapshot.val());
        },
        { onlyOnce: true }
      );
    });
  }

  static fetchGlobalUser() {
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbGlobalUserRef(),
        (snapshot) => {
          return resolve(snapshot.val());
        },
        { onlyOnce: true }
      );
    });
  }

  static subscribeToGlobalUser(onChange = () => {}) {
    onValue(Firebase.getDbGlobalUserRef(), (snapshot) =>
      onChange(snapshot.val())
    );
  }

  static safelySetGlobalUser(fields = {}) {
    const { email, firstName, lastName, createdAtTimestamp } = fields;
    return new Promise((resolve, reject) => {
      this.fetchGlobalUser()
        .then((globalUser) => {
          const appsJoined = globalUser?.appsJoined || {};
          const appsCreated = globalUser?.appsCreated || {};
          const newFields = {};
          if (email) newFields.email = email;
          if (firstName) newFields.firstName = firstName;
          if (lastName) newFields.lastName = lastName;
          // if this is the first time it's being created
          if (!globalUser && createdAtTimestamp) {
            newFields.createdAtTimestamp = createdAtTimestamp;
          }
          newFields.mostRecentApp = School.getDatabaseAppID();
          newFields.mostRecentStandaloneAppSlug = Constants.expoConfig.slug;
          newFields.appsJoined = {
            ...appsJoined,
            [School.getDatabaseAppID()]: true
          };
          Util.localStorageGetItemAsync("createdAppIDs")
            .then((ids) => {
              const createdAppIDs = !ids ? [] : JSON.parse(ids) || [];
              const legacyAppsCreated = {};
              createdAppIDs.forEach((id) => {
                legacyAppsCreated[id] = true;
              });
              newFields.appsCreated = {
                ...appsCreated,
                ...legacyAppsCreated
              };
              const finalGlobalUser = { ...globalUser, ...newFields };
              this.setGlobalUser(newFields);
              resolve(finalGlobalUser);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static removeJoinedAppIDFromGlobalUser(appID) {
    this.fetchGlobalUser().then((globalUser) => {
      if (globalUser?.appsJoined && appID in globalUser.appsJoined) {
        const newAppsJoined = { ...globalUser.appsJoined };
        delete newAppsJoined[appID];
        update(Firebase.getDbGlobalUserRef(), { appsJoined: newAppsJoined });
      }
    });
  }

  static setGlobalUser(fields) {
    return update(Firebase.getDbGlobalUserRef(), fields);
  }

  /**
   * Write user data to the database.
   * @param fields: { firstName, lastName, email, year, type, ... }
   */
  static writeUserData(fields) {
    const userId = Firebase.getUserID();

    this.safelySetGlobalUser(fields);

    return new Promise((resolve, reject) => {
      if ("email" in fields) {
        updateEmail(Firebase.getUser(), fields.email)
          .then(() => {
            // update was successful, so update the user's info
            update(Firebase.getDbRef(`users/${userId}`), fields);
            resolve();
          })
          .catch((error) => {
            let errorMessage =
              "We were able to update everything except your email. To update your email, log out and back in, and then try again.";
            if (error.code === "auth/requires-recent-login") {
              errorMessage =
                "We were able to update everything except your email. To update your email, you'll need to reauthenticate yourself first. Log out and log back in, and then try again.";
            } else if (error.code === "auth/email-already-in-use") {
              errorMessage = `We were able to update everything except your email. Someone has already registered an account with the email "${fields.email}". If you're the one who registered with that email, try logging out and log back in under that account instead. Or feel free to message us for more help—you can ask for help from the notifications screen.`;
            }
            Util.alert("Uh oh! 😕", errorMessage, [
              {
                text: "OK",
                onPress: () => {},
                style: "cancel"
              }
            ]);
            // update was unsuccessful, so update the user's info minus the email
            const newFields = { ...fields };
            delete newFields.email;
            update(Firebase.getDbRef(`users/${userId}`), newFields);
            resolve();
          });
      } else {
        // Update the user's fields without updating the email
        update(Firebase.getDbRef(`users/${userId}`), fields).then(() => {
          resolve();
        });
      }
    });
  }

  static setUserPassword(newPassword) {
    updatePassword(Firebase.getUser(), newPassword)
      .then(() => {
        // Update successful.
      })
      .catch((error) => {
        Util.alert(error);
      });
  }

  static updateUserPassword(oldPassword, newPassword) {
    const credential = Firebase.getUserCredential(oldPassword);
    return new Promise((resolve, reject) => {
      reauthenticateWithCredential(Firebase.getUser(), credential)
        .then(() => {
          // User re-authenticated, so set their password
          updatePassword(Firebase.getUser(), newPassword)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static sendPasswordResetEmail(email = null) {
    return Firebase.sendPasswordResetEmail(email);
  }

  static logoutUser() {
    Util.localStorageDeleteItemAsync("metaAppID");
    return Firebase.logoutUser();
  }

  /**
   * Get whether or not the user has a new notification in a specific app since they last viewed their notifications in that app
   */
  static listenIsNotificationsUnopenedInApp(
    databaseAppID,
    onChange = () => {}
  ) {
    const userID = Firebase.getUserID();
    const path = `/apps/${databaseAppID}/users/${userID}/notifications/unopened`;
    onValue(ref(getDatabase(), path), (snapshot) => onChange(snapshot.val()));
  }

  /**
   * Get content for a portal
   * @param portalName
   */
  static getPortalContent(portalName, callbackFunc = console.log) {
    const path = `/content/${portalName}`;

    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        callbackFunc(snapshot.val());
      },
      {
        onlyOnce: true
      }
    );
  }

  /**
   * Get content for a portal
   * TODO: RENAME
   */
  static getPortalContentNew(portalName) {
    const path = `/content/allPortals/content/${portalName}`;
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(path),
        (snapshot) => {
          resolve(snapshot.val());
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  /**
   * Get metadata for a portal
   */
  static getPortalMetadata(portalName, callbackFunc = console.log) {
    const path = `/content/allPortals/metadata/${portalName}`;
    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        callbackFunc(snapshot.val());
      },
      {
        onlyOnce: true
      }
    );
  }

  static fetchPortalEditors(portalName) {
    return this.fetchValueOnce(
      `content/allPortals/admins/${portalName}/editors`
    );
  }

  static subscribeToPortalEditors(portalID, onChange = () => {}) {
    const editors = Firebase.getDbRef(
      `content/allPortals/admins/${portalID}/editors`
    );
    onValue(editors, (snapshot) => onChange(snapshot.val() || null));
  }

  static unsubscribeFromPortalEditors(portalID) {
    off(Firebase.getDbRef(`content/allPortals/admins/${portalID}/editors`));
  }

  static setPortalEditor(portalID, userID, canEdit = true) {
    return set(
      Firebase.getDbRef(
        `content/allPortals/admins/${portalID}/editors/${userID}`
      ),
      canEdit
    );
  }

  /**
   * Get the current banner message (for top of Root), if there is one
   */
  static getHomescreenBanner(callbackFunc = () => {}) {
    const path = `/content/bannerMessage`;
    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        const bannerMessage = snapshot.val();
        callbackFunc(bannerMessage);
      },
      {
        onlyOnce: true
      }
    );

    // // TODO: Maybe re-enable this so it won't impact Five Towns
    // const googleSheetID = School.get("homescreen banner sheet id");
    // if (!googleSheetID) return callbackFunc(null);
    // Util.fetchGoogleSheetData(googleSheetID, "Banner")
    //   .then((data) => {
    //     if (data
    //       && data.length > 0
    //       && Object.keys(data[0]).includes("Active")
    //       && Object.keys(data[0]).includes("Text")
    //       && Object.keys(data[0]).includes("Type")
    //     ) {
    //       callbackFunc({
    //         active: data[0]["Active"],
    //         text: data[0]["Text"],
    //         type: data[0]["Type"]
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     callbackFunc(null);
    //   });
  }

  /**
   * Set the current banner message (for top of Root)
   */
  static setHomescreenBanner(message) {
    const path = `/content/bannerMessage`;
    update(Firebase.getDbRef(path), message);
  }

  static fetchMetaApp(metaAppID = null, overwriteLocalMetaAppID = true) {
    return new Promise((resolve, reject) => {
      Util.localStorageGetItemAsync("metaAppID").then((storedMetaAppID) => {
        let metaAppIDToFetch = metaAppID || Glob.get("metaAppID");
        if (!metaAppIDToFetch && Rex.getLoginStatus() && storedMetaAppID) {
          metaAppIDToFetch = storedMetaAppID;
        }
        if (metaAppIDToFetch) {
          if (overwriteLocalMetaAppID)
            Util.localStorageSetItemAsync("metaAppID", metaAppIDToFetch);
          onValue(
            Firebase.getDbMetaAppsRef(metaAppIDToFetch),
            (snapshot) => resolve(snapshot.val()),
            {
              onlyOnce: true
            }
          );
        } else resolve(null);
      });
    });
  }

  static appIsInMetaApp(metaAppID) {
    const appID = School.getDatabaseAppID();
    return new Promise((resolve) => {
      onValue(
        Firebase.getDbMetaAppsRef(`${metaAppID}/apps/${appID}`),
        (snapshot) => resolve(!!snapshot.val()),
        {
          onlyOnce: true
        }
      );
    });
  }

  /**
   * Get the global config info about this app, like colors, organization name, etc.
   */
  static fetchGlobalConfig() {
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef("/content/globalConfig"),
        (snapshot) => resolve(snapshot.val()),
        {
          onlyOnce: true
        }
      );
    });
  }

  /**
   * Set the global config info about this app, like colors, organization name, etc.
   */
  static setGlobalConfig(newGlobalConfig) {
    update(Firebase.getDbRef("/content"), { globalConfig: newGlobalConfig });
  }

  /**
   * Update parts of the global config info
   */
  static updateGlobalConfig(updatedGlobalConfig) {
    update(Firebase.getDbRef("/content/globalConfig"), updatedGlobalConfig);
  }

  static fetchAppMetadata() {
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbMetadataRef(),
        (snapshot) => resolve(snapshot.val()),
        {
          onlyOnce: true
        }
      );
    });
  }

  static setAppMetadata(appMetadata) {
    update(Firebase.getDbMetadataRef(), appMetadata);
  }

  static setSelfAsAppCreator() {
    const userID = Firebase.getUserID();
    this.setAppMetadata({ creator: userID });
    this.setDefaultPortalsConfig({
      trackOneHomeScreenForAllDefaults: true,
      userToTrack: userID
    });
  }

  static toggleAppInMetaApp(metaAppID, includeApp = true) {
    const appID = School.getDatabaseAppID();
    if (includeApp) {
      update(Firebase.getDbMetaAppsRef(`${metaAppID}/apps`), { [appID]: true });
    } else {
      const appRef = Firebase.getDbMetaAppsRef(`${metaAppID}/apps/${appID}`);
      remove(appRef);
    }
  }

  // Should only be called by super admins
  static updateMetaAppCreationSettings(metaAppID, updates = {}) {
    if (!this.userIsSuperAdmin()) return;
    update(Firebase.getDbMetaAppsRef(`${metaAppID}/appCreation`), updates);
  }

  /**
   * Get the user fields that should be shown, such as class year
   */
  static fetchUserAccountFields() {
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(`/userAccountConfig/fields`),
        (snapshot) => {
          const fields = snapshot.val() || {};
          const fieldsArray =
            Object.entries(fields)
              .map(([key, val]) => ({ ...val, key }))
              .sort((field1, field2) => {
                const sortOrder1 =
                  field1.sortOrder !== undefined ? field1.sortOrder : Infinity;
                const sortOrder2 =
                  field2.sortOrder !== undefined ? field2.sortOrder : Infinity;
                return sortOrder1 - sortOrder2;
              }) || [];
          resolve({ fields, fieldsArray });
        },
        { onlyOnce: true }
      );
    });
  }

  static updateUserAccountFields(fields) {
    const updates = {};
    updates[`${this.baseURL()}/userAccountConfig/fields`] = fields;
    update(ref(getDatabase()), updates);
  }

  /**
   * Sets a user's last name
   */
  static setUserSchoolID(schoolID) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;
    update(Firebase.getDbRef(path), { schoolID });
  }

  /**
   * Sets a user's email
   */
  static setUserEmail(userEmail) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;
    // set email
    return new Promise((resolve, reject) => {
      updateEmail(Firebase.getUser(), userEmail)
        .then(() => {
          // update was successful, so update the user's info
          set(Firebase.getDbRef(path), { email: userEmail }).then(resolve);
        })
        .catch((error) => {
          // an error happened, so exit and don't save
          Util.alert(error);
          reject(error);
        });
    });
  }

  /**
   * Sets a user's customized (sorted) portals list
   */
  static setUserPortals(order, uid = null) {
    const portals = order.map((portal) => portal.navName);
    const userID = uid || Firebase.getUserID();
    if (!userID) return;
    const path = `/users/${userID}`;
    update(Firebase.getDbRef(path), { portals });
    if (
      Rex.getConfig()?.defaultPortalsConfig?.trackOneHomeScreenForAllDefaults &&
      Rex.getConfig().defaultPortalsConfig.userToTrack === userID
    ) {
      this.setAllDefaultPortals(portals);
    }
  }

  /**
   * Sets a user's groups
   */
  static setUserGroups(groupIDs, uid = null, isUserInvitation = false) {
    const userID = uid || Firebase.getUserID();
    if (!userID) return;
    const path = `${
      isUserInvitation ? "/userInvitations" : ""
    }/users/${userID}`;
    const groups = {};
    groupIDs.forEach((i) => {
      groups[i] = true;
    });
    update(Firebase.getDbRef(path), { groups });
  }

  /**
   * Sets a user's administrative privileges
   */
  static setUserPrivileges(privileges, userID, isUserInvitation = false) {
    if (!userID) return;
    const path = `${
      isUserInvitation ? "/userInvitations" : ""
    }/users/${userID}`;
    update(Firebase.getDbRef(path), { privileges });
  }

  /**
   * Sets a user's account type
   */
  static setUserType(type, uid = null, isUserInvitation = false) {
    const userID = uid || Firebase.getUserID();
    if (!userID) return;
    const path = `${
      isUserInvitation ? "/userInvitations" : ""
    }/users/${userID}`;
    update(Firebase.getDbRef(path), { type });
  }

  /**
   * Completely delete a user from our database
   */
  static deleteUser(userID, isUserInvitation = false) {
    if (!userID) return;
    const path = `${
      isUserInvitation ? "/userInvitations" : ""
    }/users/${userID}`;
    const userRef = Firebase.getDbRef(path);
    remove(userRef);
  }

  /**
   * Listen for changes to the app minimum version (both minimum recommended & minimum required)
   */
  static listenAppVersion(callbackFunc) {
    const path = "/content/aa_version";
    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let recommended = "";
        let required = "";
        if (snapshot.val()) {
          recommended = snapshot.val().recommended;
          required = snapshot.val().required;
        }
        callbackFunc(recommended, required);
      },
      {
        onlyOnce: true
      }
    );
  }

  static fetchPrimaryMetaApp(databaseAppID = School.getDatabaseAppID()) {
    return new Promise(async (resolve) => {
      const montessoriMetaApp = await this.fetchMetaApp("montessori", false);
      if (Object.keys(montessoriMetaApp?.apps || {}).includes(databaseAppID))
        return resolve({
          key: "montessori",
          appName: "The Montessori App",
          appLinks: Glob.get("montessoriAppStoreLinks"),
          webLink: Util.webURL({ baseURL: "https://themontessoriapp.com" }),
          webLinkBase: "themontessoriapp.com"
        });
      const onespotk12MetaApp = await this.fetchMetaApp("onespotk12", false);
      if (Object.keys(onespotk12MetaApp?.apps || {}).includes(databaseAppID))
        return resolve({
          key: "onespotk12",
          appName: "Onespot K12",
          appLinks: Glob.get("onespotk12AppStoreLinks"),
          webLink: Util.webURL({ baseURL: "https://onespotk12.com" }),
          webLinkBase: "onespotk12.com"
        });
      const dartmouthMetaApp = await this.fetchMetaApp("dartmouth", false);
      if (Object.keys(dartmouthMetaApp?.apps || {}).includes(databaseAppID))
        return resolve({
          key: "onespotcampus",
          appName: "Onespot Campus",
          appLinks: Glob.get("onespotCampusAppStoreLinks"),
          webLink: Util.webURL({ baseURL: "https://onespotcampus.com" }),
          webLinkBase: "onespotcampus.com"
        });
      return resolve({
        key: "onespot",
        appName: "Onespot",
        appLinks: Glob.get("onespotAppStoreLinks"),
        webLink: Util.webURL({ baseURL: "https://1spot.app" }),
        webLinkBase: "www.1spot.app"
      });
    });
  }

  static fetchAppStoreInfo(databaseAppID = null) {
    return new Promise((resolve) => {
      const dbRef = databaseAppID
        ? ref(getDatabase(), `/apps/${databaseAppID}/content/appStoreInfo`)
        : Firebase.getDbRef("/content/appStoreInfo");
      onValue(
        dbRef,
        (snapshot) => {
          const info = snapshot.val();
          const { appLinks: { android, ios } = {} } = info || {};
          const { android: onespotAndroid, ios: onespotIOS } = Glob.get(
            "onespotAppStoreLinks"
          );
          const useDefaults =
            (!ios && !android) ||
            (android === onespotAndroid && ios === onespotIOS);
          if (useDefaults && databaseAppID) {
            this.fetchPrimaryMetaApp(databaseAppID).then((metaApp) => {
              resolve({ ...info, ...metaApp });
            });
          } else {
            resolve({
              ...info,
              appName:
                Rex.getConfig()?.names?.full ||
                Rex.getConfig()?.names?.nickname ||
                "Onespot"
            });
          }
        },
        { onlyOnce: true }
      );
    });
  }

  static setAppStoreLinks(appLinks = {}) {
    const { android, ios } = appLinks;
    const updates = {};
    if (android)
      updates[
        `${this.baseURL()}/content/appStoreInfo/appLinks/android`
      ] = android;
    if (ios)
      updates[`${this.baseURL()}/content/appStoreInfo/appLinks/ios`] = ios;
    return update(ref(getDatabase()), updates);
  }

  /**
   * Compare two app version strings, returning true iff v1 >= v2
   *  Return FALSE only if the second argument is strictly higher than the first.
   *
   * Note that inputs must be standard version strings, for example 13.0 or 14.1.1 or 15.0.9.1
   *  (Input strings cannot contain letters or any other characters. Must consist only of numerical digits and periods.)
   * Examples of results:  (2.9.0, 3.0.1)->F ; (3.0.1, 3.0.2)->F ; (3.9.2, 3.2.9)->T ; (3.3, 3.2.9)->T ; (14.0, 14.0)->T
   *  Note an unintuitive but to-be-avoided-anyway result of this function: (3.1, 3.1.0)->F
   *  ^ On a similar note, (3.030, 3.10->T, so don't start any version substring with 0 unless the whole substring is just 0
   * Our version advancement could, after 13.1.9, go to: 13.1.9.0, 13.1.9.1, 13.1.10, 13.2, or 14.0
   *  ^ In all of those cases, the latter string will return true if given as v1 to this function, with 13.1.9 as v2
   */
  // Compare two version strings. Must be strings containing numbers and periods only.
  // ** Return true if and only if v1 is greater than or equal to v2 **
  static compareVersions(v1, v2) {
    const v1Pieces = v1.split(".");
    const v2Pieces = v2.split(".");

    for (let i = 0; i < v1Pieces.length; i += 1) {
      if (v2Pieces.length === i) {
        return true; // Since v2 has run out of pieces, v1 must be higher (ex: 3.4.1 > 3.4)
      }

      if (parseInt(v1Pieces[i], 10) > parseInt(v2Pieces[i], 10)) {
        return true;
      }

      if (parseInt(v1Pieces[i], 10) < parseInt(v2Pieces[i], 10)) {
        return false;
      }
    }

    if (v1Pieces.length === v2Pieces.length) {
      return true; // All pieces were identical, and the piece lists are the same length
    }

    return false; // Since v1 ran out of pieces, but v2 hasn't, v2 must be higher (ex: 3.4 < 3.4.1)
  }

  /**
   * Get all user data
   */
  static fetchAllUserData() {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;
    return new Promise((resolve, reject) => {
      onValue(Firebase.getDbRef(path), (snapshot) => resolve(snapshot.val()), {
        onlyOnce: true
      });
    });
  }

  /**
   * Listen for changes to a user's first name
   * @param callbackFunc
   */
  static listenUserFirstName(callbackFunc) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;

    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let firstName = "";
        if (snapshot.val()) {
          firstName = snapshot.val().firstName;
        }
        callbackFunc(firstName);
      },
      {
        onlyOnce: true
      }
    );
  }

  /**
   * Listen for changes to a user's last name
   */
  static listenUserLastName(callbackFunc) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;

    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let lastName = "";
        if (snapshot.val()) {
          lastName = snapshot.val().lastName;
        }
        callbackFunc(lastName);
      },
      {
        onlyOnce: true
      }
    );
  }

  /**
   * Listen for changes to a user's year
   */
  static listenUserYear(callbackFunc) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;

    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let year = "";
        if (snapshot.val()) {
          year = snapshot.val().year;
        }
        callbackFunc(year);
      },
      {
        onlyOnce: true
      }
    );
  }

  /**
   * Listen for changes to a user's school ID
   */
  static listenUserSchoolID(callbackFunc) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;

    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let schoolID = "";
        if (snapshot.val()) {
          schoolID = snapshot.val().schoolID;
        }
        callbackFunc(schoolID);
      },
      {
        onlyOnce: true
      }
    );
  }

  /**
   * Listen for changes to a user's email
   * @param callbackFunc
   */
  static listenUserEmail(callbackFunc) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;

    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let email = "";
        if (snapshot.val()) {
          email = snapshot.val().email;
        }
        callbackFunc(email);
      },
      {
        onlyOnce: true
      }
    );
  }

  /**
   * Listen for changes to a user's type
   * @param callbackFunc
   */
  static listenUserType(callbackFunc) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;

    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let type = "";
        if (snapshot.val()) {
          type = snapshot.val().type;
        }
        callbackFunc(type);
      },
      {
        onlyOnce: true
      }
    );
  }

  /**
   * Fetch a user's privileges
   */
  static fetchUserPrivileges(asList = true) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}/privileges`;
    return new Promise((resolve) => {
      onValue(
        Firebase.getDbRef(path),
        (snapshot) => {
          const privileges = snapshot.val();
          if (!asList) resolve(privileges);
          else resolve(privileges ? Object.keys(privileges) : []);
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  /**
   * Listen for changes to a user's home order
   * @param homeOrder
   * @returns {firebase.Promise<any>|!firebase.Promise.<void>}
   */
  static listenUserHomeOrder(callbackFunc) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;

    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let homeOrder = [];
        // SMC: I think this tree of ifs fixes a warning (to make sure nothing is null)
        if (snapshot) {
          if (snapshot.val()) {
            if (snapshot.val().homeOrder) {
              homeOrder = snapshot.val().homeOrder;
            }
          }
        }
        callbackFunc(homeOrder);
      },
      {
        onlyOnce: true
      }
    );
  }

  // /**
  //  * TODO: Make sure this is backwards-compatible
  //  * Get order of user's portals
  //  */
  // static getUserPortals(callbackFunc) {
  //   const userID = Firebase.getUserID();
  //   const path = `/users/${userID}/portals`;

  //   Firebase.getDbRef(path).once('value').then(
  //     (snapshot) => callbackFunc(snapshot.val()),
  //     (error) => console.log(error)
  //   );
  // }

  static subscribeToTasks(onChange = () => {}) {
    const userID = Firebase.getUserID();
    const tasksRef = Firebase.getDbRef(`/users/${userID}/tasks`);
    onValue(tasksRef, (snapshot) => {
      onChange(snapshot.val());
    });
  }

  static unsubscribeFromTasks() {
    const userID = Firebase.getUserID();
    off(Firebase.getDbRef(`/users/${userID}/tasks`));
  }

  static subscribeToNotificationPreferences(onChange = () => {}) {
    const userID = Firebase.getUserID();
    const preferencesRef = Firebase.getDbRef(
      `/users/${userID}/notifications/preferences`
    );
    onValue(preferencesRef, (snapshot) => {
      onChange(snapshot.val());
    });
  }

  static unsubscribeFromNotificationPreferences() {
    const userID = Firebase.getUserID();
    off(Firebase.getDbRef(`/users/${userID}/notifications/preferences`));
  }

  static setNotificationPreferences(
    newPreferences,
    uid,
    isUserInvitation = false
  ) {
    const userID = uid || Firebase.getUserID();
    const preferencesRef = Firebase.getDbRef(
      `${
        isUserInvitation ? "/userInvitations" : ""
      }/users/${userID}/notifications/preferences`
    );
    update(preferencesRef, newPreferences);
  }

  static setUserInternalAdminNote(notes, userID, isUserInvitation = false) {
    if (!userID) return;
    const internalAdminNoteRef = Firebase.getDbRef(
      `${
        isUserInvitation ? "/userInvitations" : ""
      }/users/${userID}/internalAdminNote`
    );
    set(internalAdminNoteRef, notes);
  }

  static setUserInvitationName(firstName, lastName, userID) {
    if (!userID) return;
    const firstNameRef = Firebase.getDbRef(
      `/userInvitations/users/${userID}/firstName`
    );
    const lastNameRef = Firebase.getDbRef(
      `/userInvitations/users/${userID}/lastName`
    );
    set(firstNameRef, firstName);
    set(lastNameRef, lastName);
  }

  static setUserInvitationEmail(email, userID) {
    if (!userID || !email) return;
    const emailRef = Firebase.getDbRef(
      `/userInvitations/users/${userID}/email`
    );
    set(emailRef, email);
  }

  static setUserPhoneNumber(phoneNumber, userID, isUserInvitation = false) {
    if (!userID) return;
    const phoneNumberRef = Firebase.getDbRef(
      `${
        isUserInvitation ? "/userInvitations" : ""
      }/users/${userID}/phoneNumber`
    );
    set(phoneNumberRef, phoneNumber);
  }

  static subscribeToDefaultPortalsConfig(onChange = () => {}) {
    const defaultPortalsConfigRef = Firebase.getDbRef(
      "/content/globalConfig/defaultPortalsConfig"
    );
    onValue(defaultPortalsConfigRef, (snapshot) => {
      onChange(snapshot.val());
    });
  }

  static unsubscribeFromDefaultPortalsConfig() {
    off(Firebase.getDbRef("/content/globalConfig/defaultPortalsConfig"));
  }

  static setDefaultPortalsConfig(newConfig) {
    const updates = {};
    updates[
      `${this.baseURL()}/content/globalConfig/defaultPortalsConfig`
    ] = newConfig;
    update(ref(getDatabase()), updates);
    Rex.setConfig({ ...Rex.getConfig(), defaultPortalsConfig: newConfig });
  }

  static subscribeToAccountTypes(onChange = () => {}) {
    const accountTypesRef = Firebase.getDbRef(
      "/userAccountConfig/accountTypes"
    );
    onValue(accountTypesRef, (snapshot) => {
      onChange(snapshot.val());
    });
  }

  static unsubscribeFromAccountTypes() {
    off(Firebase.getDbRef("/userAccountConfig/accountTypes"));
  }

  static fetchAllAccountTypeDetails() {
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef("/userAccountConfig/accountTypes"),
        (snapshot) => {
          const out = snapshot.val();
          resolve(out);
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  static fetchAccountTypeDetails(accountType) {
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(`/userAccountConfig/accountTypes/${accountType}`),
        (snapshot) => {
          const out = snapshot.val();
          resolve(out);
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  static setAccountTypeDetails(accountType, details) {
    this.setAccountTypesIfLegacyDb().then(() => {
      const updates = {};
      updates[
        `${this.baseURL()}/userAccountConfig/accountTypes/${accountType}`
      ] = details;
      update(ref(getDatabase()), updates);
    });
  }

  static addAccountType(details) {
    this.setAccountTypesIfLegacyDb().then(() => {
      const allAccountTypesRef = ref(
        getDatabase(),
        `${this.baseURL()}/userAccountConfig/accountTypes`
      );
      const newGroupRef = push(allAccountTypesRef);
      set(newGroupRef, details);
    });
  }

  /**
   * Get all portal keys for the default portal orders
   */
  static getAllDefaultPortals(callbackFunc) {
    this.fetchAllAccountTypeDetails().then((accountTypes) => {
      if (accountTypes) {
        const portalsObj = {};
        Object.entries(accountTypes).forEach(([accountType, content]) => {
          const { defaultPortals } = content;
          portalsObj[accountType] = defaultPortals;
        });
        callbackFunc(portalsObj);
      } else {
        // Handle legacy database structure
        onValue(
          Firebase.getDbRef("/content/defaultPortals"),
          (snapshot) => {
            const portalsObj = snapshot.val();
            if (!portalsObj) return callbackFunc(portalsObj);
            Object.entries(portalsObj).forEach(
              ([accountType, orderedPortalIDs]) => {
                portalsObj[accountType] = orderedPortalIDs;
              }
            );
            callbackFunc(portalsObj);
          },
          {
            onlyOnce: true
          }
        );
      }
    });
  }

  /**
   * Get all portal metadata for a specific user type
   * TODO: TESTING
   */
  static getDefaultPortalMetadata(accountType, callbackFunc) {
    this.getAllPortalMetadata().then((metadata) => {
      if (!metadata) return callbackFunc([]);
      return this.fetchAccountTypeDetails(accountType).then((details) => {
        if (details?.defaultPortals) {
          const portalIDs = details?.defaultPortals;
          const portals = portalIDs
            .map((id) => ({
              ...metadata[`${id}`],
              navName: `${id}`
            }))
            .filter((p) => p?.portalType); // in case there's a fake portal key in defaultPortals
          callbackFunc(portals);
        } else {
          // Handle legacy database structure
          onValue(
            Firebase.getDbRef(`/content/defaultPortals/${accountType}`),
            (snapshot) => {
              if (snapshot) {
                const portalIDs = snapshot.val();
                if (portalIDs) {
                  const portals = portalIDs
                    .map((id) => ({
                      ...metadata[`${id}`],
                      navName: `${id}`
                    }))
                    .filter((p) => p?.portalType); // in case there's a fake portal key in defaultPortals
                  callbackFunc(portals);
                } else callbackFunc([]);
              } else callbackFunc([]);
            },
            {
              onlyOnce: true
            }
          );
        }
      });
    });
  }

  static setAccountTypesIfLegacyDb() {
    return new Promise((resolve, reject) => {
      this.fetchAllAccountTypeDetails().then((accountTypes) => {
        if (accountTypes) resolve();
        // Otherwise, the database is in the legacy format
        // So pull in all account types from content/defaultPortals
        else
          this.getAllDefaultPortals((defaultPortals) => {
            const newAccountTypes = {};
            Object.entries(defaultPortals).forEach(([accountType, portals]) => {
              const defaultDetails = Glob.getAccountType(accountType);
              let details = {};
              if (defaultDetails)
                details = {
                  title: defaultDetails?.buttonText,
                  icon: defaultDetails?.iconName
                };
              newAccountTypes[accountType] = {
                defaultPortals: portals,
                ...details
              };
            });
            const updates = {};
            updates[
              `${this.baseURL()}/userAccountConfig/accountTypes`
            ] = newAccountTypes;
            update(ref(getDatabase()), updates).then(() => {
              resolve();
            });
          });
      });
    });
  }

  /**
   * Set default portal keys for a specific user type (e.g. 'alum')
   * TODO: TESTING
   */
  static setDefaultPortals(accountType, newDefaultPortalKeys) {
    this.setAccountTypesIfLegacyDb().then(() => {
      const updates = {};
      updates[
        `${this.baseURL()}/userAccountConfig/accountTypes/${accountType}/defaultPortals`
      ] = newDefaultPortalKeys;
      // This line below is to update the legacy database format. Consider removing after a while (added June, 2022)
      updates[
        `${this.baseURL()}/content/defaultPortals/${accountType}`
      ] = newDefaultPortalKeys;
      update(ref(getDatabase()), updates);
    });
  }

  static setAllDefaultPortals(portals) {
    this.getAllDefaultPortals((defaultPortalsObj) => {
      const accountTypeIDs = Object.keys(defaultPortalsObj);
      accountTypeIDs.forEach((type) => {
        this.setDefaultPortals(type, portals);
      });
    });
  }

  /**
   * Get all metadata associated with all portals
   * TODO: Have this include navName as part of the output, rather than adding it (check everywhere we call Database.getAllPortalMetadata)
   */
  static getAllPortalMetadata() {
    const path = "/content/allPortals/metadata";
    return new Promise((resolve, reject) => {
      try {
        onValue(
          Firebase.getDbRef(path),
          (snapshot) => {
            if (snapshot.exists()) return resolve(snapshot.val());
            return resolve(null);
          },
          (error) => reject(error),
          {
            onlyOnce: true
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * TODO: TESTING
   */
  static addPortal(metadata = {}, content) {
    let cleanAllowedAccountTypes = null;
    if (
      metadata.allowedAccountTypes &&
      Object.values(metadata.allowedAccountTypes).some((allowed) => allowed)
    ) {
      cleanAllowedAccountTypes = metadata.allowedAccountTypes;
    }
    const newMetadata = {
      txtName: metadata.txtName,
      imgName: "web", // backward-compatible
      icon: metadata.icon || metadata.imgName, // backward-compatible
      portalType: metadata.portalType,
      allowedAccountTypes: cleanAllowedAccountTypes,
      restrictedToFeedSubscribers: metadata?.restrictedToFeedSubscribers || null
    };

    const contentListRef = ref(
      getDatabase(),
      `${this.baseURL()}/content/allPortals/content`
    );
    const newContentRef = push(contentListRef);
    const { key } = newContentRef;
    set(newContentRef, content);

    return new Promise((resolve) => {
      set(
        ref(
          getDatabase(),
          `${this.baseURL()}/content/allPortals/metadata/${key}`
        ),
        newMetadata
      ).then(() => resolve({ ...newMetadata, navName: key }));
    });
  }

  /**
   * TODO: TESTING
   */
  static updatePortal(metadata, content, navName = null) {
    const navNamePath = navName || metadata.navName;
    const updates = {};
    if (metadata) {
      let cleanAllowedAccountTypes = null;
      if (
        metadata.allowedAccountTypes &&
        Object.values(metadata.allowedAccountTypes).some((allowed) => allowed)
      ) {
        cleanAllowedAccountTypes = metadata.allowedAccountTypes;
      }
      const newMetadata = {
        txtName: metadata.txtName,
        imgName: "web", // backward-compatible
        icon: metadata.icon || metadata.imgName, // backward-compatible
        portalType: metadata.portalType,
        allowedAccountTypes: cleanAllowedAccountTypes,
        restrictedToFeedSubscribers:
          metadata?.restrictedToFeedSubscribers || null
      };
      updates[
        `${this.baseURL()}/content/allPortals/metadata/${navNamePath}`
      ] = newMetadata;
    }
    if (content) {
      updates[
        `${this.baseURL()}/content/allPortals/content/${navNamePath}`
      ] = content;
    }
    return update(ref(getDatabase()), updates);
  }

  /**
   * TODO: TESTING
   * Todo: Handle deleted portals in user's homeorder (portals)
   */
  static deletePortal(portalKey) {
    this.getAllDefaultPortals((defaultPortals) => {
      const newDefaultPortals = defaultPortals;
      const accountTypesThatFailed = [];
      let defaultPortalsExist = false;
      Object.entries(defaultPortals).forEach(([accountType, portals]) => {
        if (portals) {
          defaultPortalsExist = true;
          const newPortalList = portals.filter((key) => key !== portalKey);
          if (newPortalList.length < 1)
            accountTypesThatFailed.push(accountType);
          newDefaultPortals[accountType] = newPortalList;
        }
      });
      if (accountTypesThatFailed.length > 0) {
        const accountTypesThatFailedPhrase =
          accountTypesThatFailed.length > 1
            ? `any of these account types: ${accountTypesThatFailed.join(", ")}`
            : `the account type "${accountTypesThatFailed[0]}"`;
        Util.alert(
          "Uh oh!",
          `The screen you just tried to delete is the only screen that's active by default for new users who sign up with ${accountTypesThatFailedPhrase}. Before you can delete this screen, please go to "Default portals" to add more screens that are active by default.`
        );
        return;
      }

      if (defaultPortalsExist)
        Object.entries(newDefaultPortals).forEach(([accountType, portals]) => {
          this.setDefaultPortals(accountType, portals);
        });

      const contentRef = ref(
        getDatabase(),
        `${this.baseURL()}/content/allPortals/content/${portalKey}`
      );
      const metadataRef = ref(
        getDatabase(),
        `${this.baseURL()}/content/allPortals/metadata/${portalKey}`
      );
      remove(contentRef);
      remove(metadataRef);
    });
  }

  /**
   * Get the custom-sorted list of user's portals (and metadata)
   * This is the new-and-improved version of listenUserHomeOrder
   */
  static getUserPortals() {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}/portals`;
    const backupPath = `/users/${userID}/homeOrder`;

    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(path),
        (snapshot) => {
          this.getAllPortalMetadata()
            .then((metadata) => {
              if (snapshot.exists()) {
                const portalIDs = snapshot.val();
                if (portalIDs && metadata) {
                  const portals = portalIDs
                    .filter((id) => metadata[`${id}`])
                    .map((id) => ({
                      ...metadata[`${id}`],
                      navName: `${id}`
                    }));
                  resolve(portals);
                } else resolve([]);
              }
              // This makes our database backwards compatible for existing users
              // todo: Consider removing this in the future once all users/apps are updated
              else {
                onValue(
                  Firebase.getDbRef(backupPath),
                  (backupSnapshot) => {
                    if (backupSnapshot) {
                      const portalIDs = (
                        JSON.parse(backupSnapshot.val()) || []
                      ).map((p) => p.navName);
                      if (portalIDs) {
                        const portals = portalIDs
                          .filter((id) => metadata[`${id}`])
                          .map((id) => ({
                            ...metadata[`${id}`],
                            navName: `${id}`
                          }));
                        this.setUserPortals(portals);
                        resolve(portals);
                      }
                    } else resolve([]);
                  },
                  { onlyOnce: true }
                );
              }
            })
            .catch((error) => reject(error));
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  /**
   * Get all groups for all users
   */
  static getAllUserGroups() {
    const path = "/userGroups/all";
    return new Promise((resolve, reject) => {
      onValue(Firebase.getDbRef(path), (snapshot) => resolve(snapshot.val()), {
        onlyOnce: true
      });
    });
  }

  /**
   * Get all groups for all users
   */
  static getAllUserGroupsDisplayOrder() {
    const path = "/userGroups/displayOrder";
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(path),
        (snapshot) => resolve(snapshot.val() || []),
        {
          onlyOnce: true
        }
      );
    });
  }

  /**
   * Get all groups for all users
   */
  static updateAllUserGroupsDisplayOrder(newOrder) {
    const updates = {};
    updates[`${this.baseURL()}/userGroups/displayOrder`] = newOrder;
    return update(ref(getDatabase()), updates);
  }

  /**
   * Get all groups for all users
   */
  static updateUserGroupName(groupId, name) {
    const updates = {};
    updates[`${this.baseURL()}/userGroups/all/${groupId}/name`] = name;
    return update(ref(getDatabase()), updates);
  }

  /**
   * Add a new user group
   */
  static addUserGroup(group) {
    const newGroup = {
      name: group.name
    };

    const allGroupsListRef = ref(
      getDatabase(),
      `${this.baseURL()}/userGroups/all`
    );
    const newGroupRef = push(allGroupsListRef);
    const { key } = newGroupRef;
    set(newGroupRef, newGroup);

    return new Promise((resolve, reject) => {
      this.getAllUserGroupsDisplayOrder().then((oldDisplayOrder) => {
        this.updateAllUserGroupsDisplayOrder([key, ...oldDisplayOrder]);
        resolve();
      });
    });
  }

  /**
   * Admin Feature: Delete a user group
   */
  static deleteUserGroup(groupId) {
    const groupRef = ref(
      getDatabase(),
      `${this.baseURL()}/userGroups/all/${groupId}`
    );
    remove(groupRef);

    return new Promise((resolve, reject) => {
      this.getAllUserGroupsDisplayOrder().then((oldDisplayOrder) => {
        const newDisplayOrder = [...oldDisplayOrder];
        newDisplayOrder.splice(oldDisplayOrder.indexOf(groupId), 1);
        this.updateAllUserGroupsDisplayOrder(newDisplayOrder);
        resolve();
      });
    });
  }

  /**
   * Get active groups for this users
   */
  static getActiveUserGroups() {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}/groups`;
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(path),
        (snapshot) => {
          resolve(Object.keys(snapshot.val() || {}));
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  static subscribeToUserNotifications(onChange = () => {}) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}/notifications/all`;
    // Firebase.getDbRef(path).on("value", (snapshot) => {
    onValue(Firebase.getDbRef(path), (snapshot) => {
      const allNotifications = snapshot.val() || {};
      // Convert to an array of objects, sorted by ID (which is the timestamp in milliseconds)
      const notifications = Object.entries(allNotifications)
        .map((keyVal) => ({
          key: keyVal[0],
          // use the timestamp, otherwise try to convert the key into a datetime
          date: keyVal[1].timestamp || new Date(parseInt(keyVal[0], 10)),
          ...keyVal[1]
        }))
        .sort((a, b) => {
          const aVal =
            a.timestamp && new Date(a.timestamp)
              ? new Date(a.timestamp).valueOf()
              : a.key;
          const bVal =
            b.timestamp && new Date(b.timestamp)
              ? new Date(b.timestamp).valueOf()
              : b.key;
          return bVal - aVal;
        });
      onChange(notifications);
    });
  }

  static unsubscribeFromUserNotifications() {
    const userID = Firebase.getUserID();
    off(Firebase.getDbRef(`/users/${userID}/notifications/all`));
  }

  /**
   * Get notification details
   */
  static fetchNotificationDetails(globalNotificationID) {
    const path = `/notifications/all/${globalNotificationID}`;
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(path),
        (snapshot) => {
          const out = snapshot.val();
          resolve(out);
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  // Mark their notifications node as having been opened
  static markUserNotificationsAsOpened = () => {
    const userID = Firebase.getUserID();
    const unopenedRef = ref(
      getDatabase(),
      `${this.baseURL()}/users/${userID}/notifications/unopened`
    );
    Notifications.setBadgeCountAsync(0);
    remove(unopenedRef);
  };

  // Mark their notification node as having been opened
  static markUserNotificationAsOpened(personalNotificationID) {
    const userID = Firebase.getUserID();
    const unopenedRef = Firebase.getDbRef(
      `/users/${userID}/notifications/all/${personalNotificationID}/unopened`
    );
    remove(unopenedRef);
  }

  // Delete one of their notifications
  static deleteUserNotification(personalNotificationID) {
    const userID = Firebase.getUserID();
    const unopenedRef = Firebase.getDbRef(
      `/users/${userID}/notifications/all/${personalNotificationID}`
    );
    remove(unopenedRef);
  }

  // Delete ALL of their notifications
  static deleteAllUserNotifications() {
    const userID = Firebase.getUserID();
    const unopenedRef = Firebase.getDbRef(`/users/${userID}/notifications`);
    remove(unopenedRef);
  }

  static fetchUserNotificationByChat(chatKey) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}/notifications/all`;
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(path),
        (snapshot) => {
          const allNotifications = snapshot.val() || {};
          const matchingNotificationKeyVals = Object.entries(
            allNotifications
          ).filter((keyVal) => keyVal[1]?.chat === chatKey);
          if (
            matchingNotificationKeyVals &&
            matchingNotificationKeyVals.length > 0
          ) {
            // Return the key
            resolve(matchingNotificationKeyVals[0][0]);
          }
          resolve(null);
        },
        {
          onlyOnce: true
        }
      );
    });
  }

  static fetchEventsFromGoogleCalendars(googleCalendarIDs, hoursOffset = 0) {
    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);
    const promises = googleCalendarIDs.map((calendarID) => {
      return new Promise((resolve, reject) => {
        fetch(
          `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events?key=${Glob.get(
            "googleAPIKey"
          )}&maxResults=100&singleEvents=true&orderBy=startTime&timeMin=${yesterday.toISOString()}`
        )
          .then((response) => response.json())
          .then((calendarData) => {
            const allEvents = calendarData.items
              .filter(
                (item) =>
                  item.kind === "calendar#event" &&
                  item.summary !== "Warning: No events available"
              )
              // Filter out events 1 year from now
              // Not doing this can cause a bug that shows duplicate events if they fall on
              // the same day + month + day-of-week (e.g. Saturday, January 20, 2024 and Saturday, January 20, 2029)
              .filter((item) => {
                const startTime = item.start?.dateTime || item.start?.date;
                const startTimeIsWithin1Year = Moment(startTime).isBetween(
                  Moment(),
                  Moment()
                    .add(1, "years")
                    .subtract(1, "days")
                );
                return startTimeIsWithin1Year;
              })
              .map((e) => {
                let start = e.start?.dateTime || e.start?.date;
                let end = e.end?.dateTime || e.end?.date;
                if (hoursOffset) {
                  start = Util.addHours(
                    new Date(e.start?.dateTime || e.start?.date),
                    hoursOffset
                  );
                  end = Util.addHours(
                    new Date(e.end?.dateTime || e.end?.date),
                    hoursOffset
                  );
                }
                return {
                  day: start,
                  details: e.description,
                  endTime: end,
                  event: e.summary,
                  key: e.id,
                  location: e.location || "",
                  startTime: start
                };
              });
            resolve(allEvents);
          })
          .catch((error) => reject(error));
      });
    });

    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then((results) => {
          const allEvents = results.flat();
          allEvents.sort((a, b) => new Date(a.day) - new Date(b.day));
          resolve(allEvents);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Fetch and format events from any of a few different types of sources
   * Priority: googleCalendarIDs > googleCalendarID > iCalURL > googleSheetID
   */
  static fetchEvents({
    googleCalendarIDs = null,
    googleCalendarID = null,
    iCalURL = null,
    googleSheetID = null,
    hoursOffset = 0
  }) {
    return new Promise(async (resolve, reject) => {
      if (googleCalendarIDs) {
        this.fetchEventsFromGoogleCalendars(googleCalendarIDs, hoursOffset)
          .then(resolve)
          .catch(reject);
      } else if (googleCalendarID) {
        this.fetchEventsFromGoogleCalendars([googleCalendarID], hoursOffset)
          .then(resolve)
          .catch(reject);
      } else if (iCalURL) {
        fetch(iCalURL)
          .then((response) => response.text())
          .then((calendarData) => {
            const parsedCalendarData = iCalParser.parseString(calendarData);
            const parsedEvents = parsedCalendarData?.events || [];
            const allEvents = parsedEvents.map((e) => ({
              day: e.dtstart?.value,
              details: e.description?.value,
              endTime: e.dtend?.value,
              event: e.summary?.value,
              key: e.uid?.value,
              location: e.location?.value || "",
              startTime: e.dtstart?.value
            }));
            allEvents.sort((a, b) => new Date(a.day) - new Date(b.day));
            resolve(allEvents);
          })
          .catch((error) => reject(error));
      } else {
        // todo: remove the backup "events sheet id" once this is always in the new database
        Util.fetchGoogleSheetData(
          googleSheetID || School.get("events sheet id"),
          "events"
        )
          .then((data) => {
            const allEvents = data.map((event, idx) => ({
              day: event.start,
              details: event.description || "",
              event: event.title,
              key: `${idx}`,
              location: event.location || "",
              startTime: event.start,
              endTime: event.end
            }));
            allEvents.sort((a, b) => new Date(a.day) - new Date(b.day));
            resolve(allEvents);
          })
          .catch((error) => reject(error));
      }
    });
  }

  /**
   * Listens for changes to user's building hours
   * @returns {firebase.Promise<any>|!firebase.Promise.<void>}
   */
  static listenUserBuildingSettings(callbackFunc) {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}`;
    onValue(
      Firebase.getDbRef(path),
      (snapshot) => {
        let buildings = [];
        if (snapshot.val().buildingSettings) {
          buildings = snapshot.val().buildingSettings;
        }
        callbackFunc(buildings);
      },
      {
        onlyOnce: true
      }
    );
  }

  // /**
  //  * Listens for changes to the accordion style content
  //  * @returns {firebase.Promise<any>|!firebase.Promise.<void>}
  //  */
  // static listenContentAccordion(modulePath, callbackFunc) {
  //   const path = `/content/${modulePath}`;
  //   Firebase.getDbRef(path).once('value').then((snapshot) => {
  //     const moduleContents = [];
  //     snapshot.forEach((childSnapshot) => {
  //       moduleContents.push({ title: childSnapshot.key, content: childSnapshot.val() })
  //     });
  //     callbackFunc(moduleContents);
  //   }, (error) => {
  //     console.log(error);
  //   });
  // }

  // /**
  //  * Listens for changes in the dining locations
  //  * @returns {firebase.Promise<any>|!firebase.Promise.<void>}
  //  */
  // static listenContentDining(modulePath, callbackFunc) {
  //   const path = `/content/${modulePath}`;
  //   Firebase.getDbRef(path).once('value').then((snapshot) => {
  //     const moduleContents = [];
  //     snapshot.forEach((childSnapshot) => {
  //       moduleContents.push({ key: childSnapshot.key, title: childSnapshot.val().title, location: childSnapshot.val().location, time: childSnapshot.val().time, status: childSnapshot.val().status })
  //     });
  //     callbackFunc(moduleContents);
  //   }, (error) => {
  //     console.log(error);
  //   });
  // }

  /**
   * Gets a set of all encrypted approved student/employee IDs
   */
  static getCOVID19ApprovedIDsEncrypted(callbackFunc = () => {}) {
    Util.fetchGoogleSheetData(
      School.get("covid19 approved ids sheet id"),
      "IDs"
    )
      .then((data) => {
        // Convert to just a list of IDs
        callbackFunc(data.map((obj) => Object.values(obj)[0]));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Gets a set of all encrypted student/employee IDs of people vaccinated, along with their expiration dates
   */
  static getCOVID19VaccinatedIDsEncrypted(callbackFunc = () => {}) {
    Util.fetchGoogleSheetData(
      School.get("covid19 vaccinations sheet id"),
      "IDs",
      false
    )
      .then((data) => {
        // Convert to an object mapping IDs to dates
        const idToDate = {};
        data.forEach((row) => {
          idToDate[row[0]] = new Date(row[1]);
        });
        callbackFunc(idToDate);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Example notificationObject:
   * {
   *    "title": "School cancelled!",
   *    "body": "School is cancelled tomorrow.",
   *    "databaseAppID": "aa-dev-app",
   *    "url": "https://en.wikipedia.org/wiki/Association_football",
   *    "audience": { "groups": ["-Mf3ZOzKSJZLO33-dkyR"], "accountTypes": ["alum"], "users": [] }
   * }
   */
  static sendPushNotification(notificationObject) {
    const formattedNotification = {
      ...notificationObject,
      databaseAppID: School.getDatabaseAppID(),
      appIsWithinOnespot: Glob.get("appIsOnespotlike")
    };
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/send-push-notification`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(formattedNotification)
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static schedulePushNotification(
    notificationObject,
    timestamp,
    scheduler = null
  ) {
    const databaseAppID = School.getDatabaseAppID();
    const formattedNotification = {
      ...notificationObject,
      databaseAppID,
      appIsWithinOnespot: Glob.get("appIsOnespotlike")
    };
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/create-scheduled-notification`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              databaseAppID,
              timestamp,
              schedulerUserID: scheduler || Firebase.getUserID(),
              notification: formattedNotification
            })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static deleteAllScheduledPushNotifications() {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(
            `${Firebase.baseAPIRoute()}/delete-all-scheduled-notifications`,
            {
              method: "post",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ databaseAppID })
            }
          )
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static subscribeToChat(chatID, onChange = () => {}) {
    const chatRef = Firebase.getDbRef(`/chats/${chatID}`);
    onValue(chatRef, (snapshot) => {
      onChange(snapshot.val());
    });
  }

  static unsubscribeFromChat(chatID) {
    off(Firebase.getDbRef(`/chats/${chatID}`));
  }

  static fetchChat(chatID) {
    const path = `/chats/${chatID}`;
    return new Promise((resolve, reject) => {
      onValue(Firebase.getDbRef(path), (snapshot) => resolve(snapshot.val()), {
        onlyOnce: true
      });
    });
  }

  // Calculate the chatID that should be used for any two users
  static membersToDirectChatID(uid0, uid1) {
    if (uid0 < uid1) return `${uid0}-${uid1}`;
    return `${uid1}-${uid0}`;
  }

  static checkIfChatExistsByID(chatID) {
    // Check members, so we don't have to retrieve the entire thread
    const path = `/chats/${chatID}/members`;
    return new Promise((resolve, reject) => {
      onValue(
        Firebase.getDbRef(path),
        (snapshot) => resolve(snapshot.exists()),
        {
          onlyOnce: true
        }
      );
    });
  }

  static postChatMessage(
    chatID,
    text,
    notificationKey,
    notificationTitle,
    overwriteTitle = false
  ) {
    return new Promise((resolve, reject) => {
      this.listenUserFirstName((firstName) => {
        this.listenUserLastName((lastName) => {
          const title = notificationTitle
            ? `${firstName} ${lastName} (Re: ${notificationTitle})`
            : `${firstName} ${lastName}`;
          if (notificationKey) {
            // Write the message body to user's own notification
            update(
              Firebase.getDbRef(
                `/users/${Firebase.getUserID()}/notifications/all/${notificationKey}`
              ),
              {
                body: text.truncate(100),
                timestamp: new Date().toISOString(),
                chat: chatID
              }
            );
          }
          Firebase.getAuth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              fetch(`${Firebase.baseAPIRoute()}/post-chat-message`, {
                method: "post",
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  title,
                  overwriteTitle,
                  message: text,
                  databaseAppID: School.getDatabaseAppID(),
                  chatID,
                  notificationKey,
                  appIsWithinOnespot: Glob.get("appIsOnespotlike")
                })
              })
                .then(() => resolve(chatID))
                .catch(reject);
            })
            .catch(reject);
        });
      });
    });
  }

  // members: [uid0, uid1]
  static createNewChat(members, firstMessage, notification, notificationTitle) {
    const userID = Firebase.getUserID();
    let notificationKey = notification?.key;
    let chatKey;

    const timestamp = new Date();
    const newMembers = {};
    members.forEach((memberID) => {
      newMembers[memberID] = true;
    });
    const newChat = {
      members: newMembers,
      notification:
        notification?.notificationDetails || notification?.key || {},
      // Note: we don't set userNotificationKey, so there could be an error if...
      // ...User1 direct-messages U2 for the first time, then U2 deletes notification, then U2 trieds to DM U1.
      thread: {
        [`${timestamp.valueOf()}`]: {
          message: firstMessage,
          sender: userID,
          timestamp: timestamp.toISOString()
        }
      }
    };

    // If this is a pure 2-person chat with no associated notification (note: it'll be always be 2-person)
    if (!notification && members.length === 2) {
      // Set its chat key (for easy indexing)
      chatKey = this.membersToDirectChatID(members[0], members[1]);
      // Note that this could overwrite an existing chat if we don't check carefully before calling createNewChat
      update(Firebase.getDbRef("/chats"), { [chatKey]: newChat });
    } else {
      const newChatRef = push(Firebase.getDbRef("/chats"));
      const { key: newChatKey } = newChatRef;
      set(newChatRef, newChat);
      chatKey = newChatKey;
    }

    // If this is a pure chat with no associated notification
    if (!notification) {
      const notificationsAllRef = Firebase.getDbRef(
        `/users/${userID}/notifications/all`
      );
      const newNotificationRef = push(notificationsAllRef);
      const { key: newNotificationKey } = newNotificationRef;
      notificationKey = newNotificationKey;
      set(newNotificationRef, {
        body: firstMessage,
        chat: chatKey,
        timestamp: timestamp.toISOString(),
        title: notificationTitle
      });
    } else if (notification?.key) {
      update(
        Firebase.getDbRef(
          `/users/${userID}/notifications/all/${notification?.key}`
        ),
        { chat: chatKey }
      );
    }

    return this.postChatMessage(
      chatKey,
      firstMessage,
      notificationKey,
      // if this is a pure chat with no associated notification, default to the title being the sender's name
      !!notification && notificationTitle,
      true
    );
  }

  // Triggered when someone replies to a chat
  static replyToChatThread(chatID, text, notificationKey, notificationTitle) {
    const userID = Firebase.getUserID();
    const threadPath = `/chats/${chatID}/thread`;
    const chatPath = `/chats/${chatID}`;
    const timestamp = new Date();
    const newMessage = {
      message: text,
      sender: userID,
      timestamp: timestamp.toISOString()
    };

    update(Firebase.getDbRef(threadPath), {
      [`${timestamp.valueOf()}`]: newMessage
    });
    if (notificationKey)
      update(Firebase.getDbRef(chatPath), {
        userNotificationKey: notificationKey
      });

    return this.postChatMessage(
      chatID,
      text,
      notificationKey,
      notificationTitle
    );
  }

  static subscribeToActivityFeed(activityFeedID, onChange = () => {}) {
    const feedRef = Firebase.getDbRef(`/activityFeeds/${activityFeedID}`);
    onValue(feedRef, (snapshot) => onChange(snapshot.val()));
  }

  static unsubscribeFromActivityFeed(activityFeedID) {
    off(Firebase.getDbRef(`/activityFeeds/${activityFeedID}`));
  }

  static subscribeToActivityFeedPost(
    activityFeedID,
    postID,
    onChange = () => {}
  ) {
    const postRef = Firebase.getDbRef(
      `/activityFeeds/${activityFeedID}/posts/${postID}`
    );
    onValue(postRef, (snapshot) => {
      onChange(snapshot.val());
    });
  }

  static unsubscribeFromActivityFeedPost(activityFeedID, postID) {
    off(Firebase.getDbRef(`/activityFeeds/${activityFeedID}/posts/${postID}`));
  }

  static subscribeToActivityFeedSubscribers(
    activityFeedID,
    onChange = () => {}
  ) {
    const feedRef = Firebase.getDbRef(
      `/activityFeeds/${activityFeedID}/subscribers`
    );
    onValue(feedRef, (snapshot) => onChange(Object.keys(snapshot.val() || {})));
  }

  static unsubscribeFromActivityFeedSubscribers(activityFeedID) {
    off(Firebase.getDbRef(`/activityFeeds/${activityFeedID}/subscribers`));
  }

  static fetchAllActivityFeeds(options) {
    const { showRestrictedFeeds = false, sorted = false } = options || {};
    const userID = Firebase.getUserID();
    return new Promise(async (resolve) => {
      const allPortals = await this.getAllPortalMetadata();
      // Get all activity feed portals
      const activityFeedPortals = Object.entries(allPortals)
        .map(([key, value]) => ({ key, ...value }))
        .filter((portal) => portal.portalType === "activityFeed");
      // Get the important data from each activity feed
      const activityFeeds = await activityFeedPortals.asyncMap(
        async (portal) => {
          const portalContent = await this.fetchValueOnce(
            `content/allPortals/content/${portal.key}`
          );
          const { activityFeed: activityFeedID, feedType, title } =
            portalContent || {};
          const userIsSubscribed = !!(await this.fetchValueOnce(
            `activityFeeds/${activityFeedID}/subscribers/${userID}`
          ));
          const mostRecentPostsObject = await this.fetchLastValuesOnce(
            `activityFeeds/${activityFeedID}/posts`
          );
          let mostRecentPost = null;
          if (mostRecentPostsObject) {
            const [post] = Object.entries(
              mostRecentPostsObject
            ).map(([key, value]) => ({ postKey: key, ...value }));
            mostRecentPost = post;
          }
          return {
            activityFeedID,
            title,
            feedType,
            portalID: portal.key,
            portalMetadata: portal,
            userIsSubscribed,
            mostRecentPost,
            isRestrictedToFeedSubscribers:
              portal?.restrictedToFeedSubscribers === true
          };
        }
      );
      // If user can edit all portals, then all activity feeds are visible
      let visibleActivityFeeds = (activityFeeds || []).filter(
        (feed) =>
          showRestrictedFeeds ||
          !feed.isRestrictedToFeedSubscribers ||
          feed.userIsSubscribed
      );
      const hiddenActivityFeedPortals = activityFeeds
        .filter(
          (feed) =>
            !showRestrictedFeeds &&
            feed.isRestrictedToFeedSubscribers &&
            !feed.userIsSubscribed
        )
        .map((feed) => feed.portalID);
      if (sorted) {
        const activityFeedsSorted = [...visibleActivityFeeds].sort((a, b) => {
          const postATimestamp = a?.mostRecentPost?.timestamp;
          const postBTimestamp = b?.mostRecentPost?.timestamp;
          return new Date(postBTimestamp) - new Date(postATimestamp);
        });
        visibleActivityFeeds = activityFeedsSorted;
      }
      return resolve({
        activityFeeds: visibleActivityFeeds,
        hiddenActivityFeedPortals
      });
    });
  }

  static postToActivityFeed(activityFeedID, portalID, post) {
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/post-activity-feed-post`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              activityFeedID,
              post,
              databaseAppID: School.getDatabaseAppID(),
              portalID,
              appIsWithinOnespot: Glob.get("appIsOnespotlike")
            })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static postCommentToActivityFeed(activityFeedID, postID, portalID, comment) {
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/post-activity-feed-comment`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              activityFeedID,
              postID,
              portalID,
              comment,
              databaseAppID: School.getDatabaseAppID(),
              appIsWithinOnespot: Glob.get("appIsOnespotlike")
            })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static likeActivityFeedPost(activityFeedID, postID, like) {
    const userID = Firebase.getUserID();
    if (like) {
      update(
        Firebase.getDbRef(
          `/activityFeeds/${activityFeedID}/posts/${postID}/likes`
        ),
        {
          [userID]: true
        }
      );
    } else {
      remove(
        Firebase.getDbRef(
          `/activityFeeds/${activityFeedID}/posts/${postID}/likes/${userID}`
        )
      );
    }
  }

  static likeActivityFeedPostComment(activityFeedID, postID, commentID, like) {
    const userID = Firebase.getUserID();
    if (like) {
      update(
        Firebase.getDbRef(
          `/activityFeeds/${activityFeedID}/posts/${postID}/comments/${commentID}/likes`
        ),
        {
          [userID]: true
        }
      );
    } else {
      remove(
        Firebase.getDbRef(
          `/activityFeeds/${activityFeedID}/posts/${postID}/comments/${commentID}/likes/${userID}`
        )
      );
    }
  }

  static deleteActivityFeedPost(activityFeedID, postID) {
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/delete-activity-feed-post`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              activityFeedID,
              postID,
              databaseAppID: School.getDatabaseAppID()
            })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static deleteActivityFeedComment(activityFeedID, postID, commentID) {
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/delete-activity-feed-comment`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              activityFeedID,
              postID,
              commentID,
              databaseAppID: School.getDatabaseAppID()
            })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static subscribeToReceiveActivityFeedNotifications(
    activityFeedID,
    subscribe = true,
    usersToSubscribe = null // array of user IDs
  ) {
    const userIDs = usersToSubscribe || [Firebase.getUserID()];
    userIDs.forEach((userID) => {
      if (subscribe) {
        update(
          Firebase.getDbRef(`/activityFeeds/${activityFeedID}/subscribers`),
          {
            [userID]: true
          }
        );
      } else {
        remove(
          Firebase.getDbRef(
            `/activityFeeds/${activityFeedID}/subscribers/${userID}`
          )
        );
      }
    });
  }

  /**
   * Trigger a welcome email to the user
   */
  static triggerWelcomeEmail(fields) {
    if (IS_DEVELOPMENT_MODE) return null;
    const formattedEmailData = {
      ...fields,
      databaseAppID: School.getDatabaseAppID(),
      appIsWithinOnespot: Glob.get("appIsOnespotlike")
    };
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/send-welcome-email`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(formattedEmailData)
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  /**
   * Add a task to all user nodes (task = "todo" or "done")
   */
  static addTaskForAllUsers(task, type = "todo") {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/add-task-for-all-users`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ task, databaseAppID, type })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  /**
   * Add a task to current user's node (task = "todo" or "done")
   */
  static addTask(task, type = "todo") {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}/tasks/${type}`;
    update(Firebase.getDbRef(path), { [task]: true });
  }

  // uid should only ever be passed if the user has the "ManageUsers" admin privilege
  static fetchTasks(uid = null) {
    let userID = Firebase.getUserID();
    if (uid) userID = uid;
    const path = `/users/${userID}/tasks`;
    return new Promise((resolve, reject) => {
      onValue(Firebase.getDbRef(path), (snapshot) => resolve(snapshot.val()), {
        onlyOnce: true
      });
    });
  }

  static removeTask(task, type = "todo") {
    const userID = Firebase.getUserID();
    const path = `/users/${userID}/tasks/${type}/${task}`;
    remove(Firebase.getDbRef(path));
  }

  static setDeviceData(device) {
    const userID = Firebase.getUserID();
    const devicesPath = `/users/${userID}/devices`;
    const deviceData = { ...device, lastUsedAtTimestamp: new Date() };
    Util.localStorageGetItemAsync("deviceID").then((deviceID) => {
      if (deviceID) {
        update(Firebase.getDbRef(`${devicesPath}/${deviceID}`), deviceData);
      } else {
        const devicesRef = Firebase.getDbRef(devicesPath);
        const newDeviceRef = push(devicesRef);
        const { key } = newDeviceRef;
        Util.localStorageSetItemAsync("deviceID", key);
        set(newDeviceRef, deviceData);
      }
    });
  }

  static setLastViewedRootAtTimestamp() {
    const userID = Firebase.getUserID();
    update(Firebase.getDbRef(`/users/${userID}`), {
      lastViewedRootAtTimestamp: new Date()
    });
  }

  static addPortalForAllUsers(portalID, index = null) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/add-portal-for-all-users`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ portalID, databaseAppID, index })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  // Returns the checkout session's URL
  // params:
  //    { type, students, email, includeStandaloneFee, includeFreeTrial, includeBuildFeeDiscount }
  //    See request.body in /create-checkout-session-unauthenticated in index.js for more info
  static createStripeCheckoutSessionUnauthenticated(params) {
    return new Promise((resolve, reject) => {
      fetch(
        `${Firebase.baseAPIRoute()}/create-checkout-session-unauthenticated`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(params)
        }
      )
        .then((response) => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  // Returns the checkout session's URL
  static createStripeCheckoutSession({ type = "school", students = 100 }) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/create-checkout-session`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ students, databaseAppID, type })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  // Returns the checkout session's URL
  static createStripeCheckoutSessionToBuyPhoneCredits(credits = 1000) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(
            `${Firebase.baseAPIRoute()}/create-checkout-session-to-buy-phone-credits`,
            {
              method: "post",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({ databaseAppID, credits })
            }
          )
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static createStripeConnectedAccount() {
    const databaseAppID = School.getDatabaseAppID();
    return this.apiPostAuthenticated("stripe-create-connected-account", {
      databaseAppID
    });
  }

  static createStripeConnectedAccountLink() {
    const databaseAppID = School.getDatabaseAppID();
    return this.apiPostAuthenticated("stripe-create-connected-account-link", {
      databaseAppID
    });
  }

  static fetchStripeConnectedAccount() {
    const databaseAppID = School.getDatabaseAppID();
    return this.apiPostAuthenticated("stripe-fetch-connected-account", {
      databaseAppID
    });
  }

  static fetchStripeConnectedAccountProducts() {
    const databaseAppID = School.getDatabaseAppID();
    return this.apiPostAuthenticated(
      "stripe-connected-account-fetch-products",
      { databaseAppID }
    );
  }

  static fetchStripeConnectedAccountProduct(productID) {
    const databaseAppID = School.getDatabaseAppID();
    return this.apiPostAuthenticated("stripe-connected-account-fetch-product", {
      databaseAppID,
      productID
    });
  }

  // docs.stripe.com/api/products/create
  static stripeConnectedAccountCreateProduct(product) {
    const databaseAppID = School.getDatabaseAppID();
    return this.apiPostAuthenticated(
      "stripe-connected-account-create-product",
      { databaseAppID, product }
    );
  }

  // docs.stripe.com/api/products/update
  // Only include `price` if you want to update price (i.e. create a new default price)
  static stripeConnectedAccountEditProduct(productID, product) {
    const databaseAppID = School.getDatabaseAppID();
    return this.apiPostAuthenticated("stripe-connected-account-edit-product", {
      databaseAppID,
      product,
      productID
    });
  }

  static stripeConnectedAccountCreateCheckoutSession(productID) {
    const databaseAppID = School.getDatabaseAppID();
    return this.apiPostAuthenticated(
      "stripe-connected-account-create-checkout-session",
      { databaseAppID, productID }
    );
  }

  // This should only ever be called by users who have the "ManageUsers" admin privilege
  static fetchCommerce() {
    return this.fetchValueOnce("commerce");
  }

  // This should only ever be called by users who have the "ManageUsers" admin privilege
  static subscribeToCommerce(onChange = () => {}) {
    onValue(Firebase.getDbRef("commerce"), (snapshot) =>
      onChange(snapshot.val())
    );
  }

  static unsubscribeFromCommerce() {
    off(Firebase.getDbRef("commerce"));
  }

  // product & customer are RevenueCat objects
  static purchaseSubscriptionRevenueCat(product, customer, shouldPublish) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise((resolve, reject) => {
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/revenuecat-purchase-subscription`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              databaseAppID,
              product,
              customer,
              shouldPublish
            })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  static sendInvitationEmails({
    emails,
    message,
    contacts,
    account,
    resendToInvitationKey
  }) {
    const databaseAppID = School.getDatabaseAppID();
    return new Promise(async (resolve, reject) => {
      const user = await this.fetchAllUserData();
      let appStoreInfo = null;
      let logoURL = null;
      let webLink = null;
      try {
        appStoreInfo = await this.fetchAppStoreInfo(databaseAppID);
      } catch {
        // Continue
      }
      try {
        const metaApp = await this.fetchPrimaryMetaApp();
        webLink = metaApp?.webLink;
      } catch {
        // Continue
      }
      try {
        logoURL = await Firebase.getMediaURLAsync("logo.png");
      } catch {
        // Continue
      }
      Firebase.getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          fetch(`${Firebase.baseAPIRoute()}/send-invitations`, {
            method: "post",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              senderName: `${user.firstName} ${user.lastName}`,
              senderEmail: user.email || "unknown email",
              databaseAppID,
              commaSeparatedEmails: (emails || []).join(","),
              ...(message ? { message } : {}),
              ...(contacts ? { contacts } : {}),
              ...(account ? { account } : {}),
              ...(appStoreInfo ? { appStoreInfo } : {}),
              ...(logoURL ? { logoURL } : {}),
              webLink: webLink || Util.webURL(),
              ...(resendToInvitationKey ? { resendToInvitationKey } : {})
            })
          })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  }

  // /**
  //  * Listens for changes to the school's module directories
  //  * @returns {firebase.Promise<any>|!firebase.Promise.<void>}
  //  */
  // static listenSchoolModuleDirectories(modulePath, callbackFunc) {
  //   const path = `/content/moduleDirectories/${modulePath}`;
  //   Firebase.getDbRef(path).once('value').then((snapshot) => {
  //     const moduleContents = [];
  //     snapshot.forEach((childSnapshot) => {
  //       moduleContents.push(childSnapshot.val());
  //     });
  //     callbackFunc(moduleContents);
  //   }, (error) => {
  //     console.log(error);
  //   });
  // }

  // /**
  //  * Listens for changes to the school's content
  //  * @returns {firebase.Promise<any>|!firebase.Promise.<void>}
  //  */
  // static listenContent(modulePath, callbackFunc) {
  //   const path = `/content/${modulePath}`;
  //   Firebase.getDbRef(path).once('value').then((snapshot) => {
  //     const moduleContents = [];
  //     snapshot.forEach((childSnapshot) => {
  //       moduleContents.push({ navName: childSnapshot.key, url: childSnapshot.val() })
  //     });
  //     callbackFunc(moduleContents);
  //   }, (error) => {
  //     console.log(error);
  //   });
  // }
}
