/* global fetch alert window */
/*
 *  utility.js holds any generic utility/helper functions that can be used throughout the project
 */

import React from "react";
import { Platform, Alert } from "react-native";
import Constants from "expo-constants";
import * as Updates from "expo-updates";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ImagePicker from "expo-image-picker";
import * as Linking from "expo-linking";
import * as StoreReview from "expo-store-review";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import School from "school/school";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import Moment from "moment";

const FIREBASE_CONFIG = Constants.expoConfig.web.config.firebase;
const IS_DEVELOPMENT_MODE = FIREBASE_CONFIG.projectId !== "seabirdmain";

const PORTAL_TYPE_TO_DETAILS = {
  webNav: {
    portalTypeName: "Web Page",
    portalTypeNameVerb: "Link Web Page",
    portalTypeIcon: "web",
    portalTypeExplanation: `You can select any web link and this screen will display it seamlessly as part of your app.

Magical? Definitely ✨`
  },
  webStatic: {
    portalTypeName: "Web Page",
    portalTypeNameVerb: "Link Web Page",
    portalTypeIcon: "web",
    portalTypeExplanation: `You can select any web link and this screen will display it seamlessly as part of your app.

Magical? Definitely ✨`
  },
  native: {
    portalTypeName: "Other",
    portalTypeIcon: "smilingStar",
    portalTypeExplanation: `Want something else?

Select this option to let us know what. The sky is the limit! 🚀`
  },
  dynamic: {
    portalTypeName: "Custom",
    portalTypeNameVerb: "Start from Scratch",
    portalTypeIcon: "tapButton",
    portalTypeExplanation:
      "Start from scratch to make your own screen with our drag-and-drop editor—add text, images, buttons, and more. You can customize this flexible screen to include anything."
  },
  events: {
    portalTypeName: "Events",
    portalTypeNameVerb: "Link Events Calendar",
    portalTypeIcon: "calendar",
    portalTypeExplanation:
      "You can integrate with any public Google Calendar to pull events into your app."
  },
  dynamicForm: {
    portalTypeName: "Form",
    portalTypeIcon: "edit",
    portalTypeExplanation: `We built this special form screen for you.

If you would like this content updated, we would be happy to help!

Please reach out to a member of the Seabird Apps team for assistance.

If you're not sure who to contact, you can always reach us at team@seabirdapps.com.`
  },
  pdfDocument: {
    portalTypeName: "PDF",
    portalTypeNameVerb: "Upload PDF",
    portalTypeIcon: "52ad79ad-58b4-4469-a676-3e00c9e02c3b",
    portalTypeExplanation: "Upload a PDF file that your members can view."
  },
  activityFeed: {
    portalTypeName: "Social",
    portalTypeIcon: "c3cbf6fa-7848-40c8-a04c-6a12656df0c0",
    portalTypeExplanation:
      "This screen allows your members to post messages to a community feed or forum."
  },
  // These two are just a different templates of the activityFeed portal type. Handled in editPortal.js
  activityFeedChatStyle: {
    portalTypeName: "Group Chat",
    portalTypeNameVerb: "Group Chat",
    portalTypeIcon: "8bbff17c-69ee-40b4-9774-5b4db7c1d211",
    portalTypeExplanation:
      "This screen allows your members to chat with one another or discuss a specific topic."
  },
  activityFeedFeedStyle: {
    portalTypeName: "Activity Feed",
    portalTypeNameVerb: "Activity Feed",
    portalTypeIcon: "c3cbf6fa-7848-40c8-a04c-6a12656df0c0",
    portalTypeExplanation:
      "This screen allows posting messages continuous feed. It could be used for updates, forums, discussions, and more."
  },
  listWebNavs: {
    portalTypeName: "List",
    portalTypeIcon: "checklist",
    portalTypeExplanation: ""
  },
  listWebNavsSplit: {
    portalTypeName: "Split List",
    portalTypeIcon: "checklist",
    portalTypeExplanation: ""
  }
  // templateContactUs: {
  //   portalTypeName: "Contact Us",
  //   portalTypeIcon: "call",
  //   portalTypeExplanation:
  //     "Let your members easily get in touch with your organization.",
  //   templateContent: [
  //     {
  //       text: "Get in touch",
  //       type: "text_header"
  //     },
  //     {
  //       text: "We’d love to hear from you!",
  //       type: "text"
  //     },
  //     {
  //       email: "",
  //       title: "Email Us",
  //       type: "button_email"
  //     },
  //     {
  //       number: "",
  //       title: "Call Us",
  //       type: "button_call"
  //     },
  //     {
  //       text: "Or check out our website for more info:",
  //       type: "text"
  //     },
  //     {
  //       title: "About Us",
  //       type: "button_web",
  //       url: "https://www.seabirdapps.com"
  //     }
  //   ]
  // },
  // templateSocialMedia: {
  //   portalTypeName: "Social Media",
  //   portalTypeIcon: "social_media",
  //   portalTypeExplanation: "Have all your social media accounts in one place.",
  //   templateContent: [
  //     {
  //       rows: [
  //         {
  //           title: "Facebook",
  //           url: "https://www.facebook.com"
  //         },
  //         {
  //           title: "Instagram",
  //           url: "https://www.instagram.com"
  //         },
  //         {
  //           title: "Twitter",
  //           url: "https://twitter.com"
  //         },
  //         {
  //           title: "YouTube",
  //           url: "https://www.youtube.com"
  //         },
  //         {
  //           title: "LinkedIn",
  //           url: "https://www.linkedin.com"
  //         }
  //       ],
  //       type: "link_list"
  //     }
  //   ]
  // },
  // templateListOfLinks: {
  //   portalTypeName: "List of Links",
  //   portalTypeIcon: "checklist",
  //   portalTypeExplanation:
  //     "List a collection of web links all in one portal, such as a collection of forms.",
  //   templateContent: [
  //     {
  //       rows: [
  //         {
  //           title: "Link 1",
  //           url: ""
  //         },
  //         {
  //           title: "Link 2",
  //           url: ""
  //         },
  //         {
  //           title: "Link 3",
  //           url: ""
  //         }
  //       ],
  //       type: "link_list"
  //     }
  //   ]
  // },
  // templateDirectory: {
  //   portalTypeName: "Directory",
  //   portalTypeIcon: "people",
  //   portalTypeExplanation:
  //     "Have easily accesible contact info for important people or departments.",
  //   templateContent: [
  //     {
  //       style: {
  //         textAlign: "left"
  //       },
  //       text: "Douglas Adams",
  //       type: "text_header"
  //     },
  //     {
  //       fontSizeMultiplier: 0.8,
  //       style: {
  //         textAlign: "left"
  //       },
  //       text: "Author",
  //       type: "text"
  //     },
  //     {
  //       email: "",
  //       title: "Email",
  //       type: "button_email"
  //     },
  //     {
  //       number: "",
  //       title: "Call",
  //       type: "button_call"
  //     },
  //     {
  //       style: {
  //         textAlign: "left"
  //       },
  //       text: "Arthur Dent",
  //       type: "text_header"
  //     },
  //     {
  //       fontSizeMultiplier: 0.8,
  //       style: {
  //         textAlign: "left"
  //       },
  //       text: "Director of Tea",
  //       type: "text"
  //     },
  //     {
  //       email: "",
  //       title: "Email",
  //       type: "button_email"
  //     },
  //     {
  //       number: "",
  //       title: "Call",
  //       type: "button_call"
  //     },
  //     {
  //       style: {
  //         textAlign: "left"
  //       },
  //       text: "Trillian Astra",
  //       type: "text_header"
  //     },
  //     {
  //       fontSizeMultiplier: 0.8,
  //       style: {
  //         textAlign: "left"
  //       },
  //       text: "Director of Outer Space",
  //       type: "text"
  //     },
  //     {
  //       email: "",
  //       title: "Email",
  //       type: "button_email"
  //     },
  //     {
  //       number: "",
  //       title: "Call",
  //       type: "button_call"
  //     },
  //     {
  //       style: {
  //         textAlign: "left"
  //       },
  //       text: "Ford Prefect",
  //       type: "text_header"
  //     },
  //     {
  //       fontSizeMultiplier: 0.8,
  //       style: {
  //         textAlign: "left"
  //       },
  //       text: "Director of Dolphins",
  //       type: "text"
  //     },
  //     {
  //       email: "",
  //       title: "Email",
  //       type: "button_email"
  //     },
  //     {
  //       number: "",
  //       title: "Call",
  //       type: "button_call"
  //     }
  //   ]
  // },
};

String.prototype.capitalize = function capitalize() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.truncate = function truncate(
  characterLimit,
  removeNewlines = true
) {
  let text = this;
  if (removeNewlines) {
    text = text.replace(/\n/g, "  ");
  }
  return text.length <= characterLimit
    ? text
    : `${text.slice(0, characterLimit)}...`;
};

// https://stackoverflow.com/a/9204568
String.prototype.isEmail = function isEmail() {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this);
};

// https://stackoverflow.com/a/29767609
String.prototype.isPhoneNumber = function isPhoneNumber() {
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    this
  );
};

Array.prototype.asyncMap = async function asyncMap(asyncCallback) {
  return Promise.all(
    this.map((item, index) => Promise.resolve(asyncCallback(item, index)))
  );
};

// https://stackoverflow.com/a/38181008
Array.prototype.insert = function insert(index, newItem) {
  return [
    // part of the array before the specified index
    ...this.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...this.slice(index)
  ];
};

const CELEBRATORY_EMOJIS = [
  "🎉",
  "✅",
  "🙌",
  "😃",
  "👍",
  "😎",
  "👏",
  "🎊",
  "🥳"
];

const sendForm = (
  formID,
  fieldIDs,
  fields,
  fieldIDsWithOtherOption = [],
  submit = true
) => {
  // Set some constants for general URL syntax
  const URL_PREFIX = "https://docs.google.com/forms/d/e/";
  const URL_INFIX = "/formResponse?";
  const FIELD_ID_PREFIX = "&entry.";
  const URL_SUBMIT_SUFFIX = "&submit=SUBMIT";

  // Create prefilled URL
  let urlParts = [`${URL_PREFIX}${formID}${URL_INFIX}`];
  fields.forEach((field, idx) => {
    if (fieldIDsWithOtherOption.includes(fieldIDs[idx])) {
      urlParts.push(`${FIELD_ID_PREFIX}${fieldIDs[idx]}=__other_option__`);
      urlParts.push(
        `${FIELD_ID_PREFIX}${fieldIDs[idx]}.other_option_response=${field}`
      );
    } else {
      urlParts.push(`${FIELD_ID_PREFIX}${fieldIDs[idx]}=${field}`);
    }
  });

  urlParts = urlParts.map((part) => part.replace("#", ""));

  // Submit the form response, if desired
  if (submit) {
    urlParts.push(URL_SUBMIT_SUFFIX);
    return fetch(urlParts.join(""));
  }

  // Otherwise, just return the pre-filled URL
  return urlParts.join("");
};

export default class Util extends React.Component {
  static randomInt(max) {
    return Math.floor(Math.random() * max);
  }

  static range(n) {
    return [...Array(n).keys()];
  }

  static randomCelebratoryEmoji() {
    return CELEBRATORY_EMOJIS[this.randomInt(CELEBRATORY_EMOJIS.length)];
  }

  // Based on this: https://stackoverflow.com/a/49381215
  static fontSizeAdjustedToFit(text, containerWidth, containerHeight) {
    return Math.min(
      Math.sqrt((containerWidth * containerHeight) / text.length),
      48
    );
  }

  // Given a date, add some given number of hours to this date
  static addHours(date, hoursRaw) {
    let hours = parseFloat(hoursRaw);
    if (Number.isNaN(hours)) hours = 0;
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    return date;
  }

  static secondsToFriendlyString(seconds) {
    const hours = seconds / 60 / 60;
    const h = Math.trunc(hours);
    const minutes = (hours - h) * 60;
    const m = Math.trunc((hours - h) * 60);
    const s = Math.round((minutes - m) * 60);
    const hoursText = h > 0 ? `${h} hour${h === 1 ? "" : "s"}` : "";
    const minutesText = m > 0 ? `${m} minute${m === 1 ? "" : "s"}` : "";
    const secondsText = s > 0 ? `${s} second${s === 1 ? "" : "s"}` : "";
    const and1Text = !!hoursText && !!minutesText ? " and " : "";
    const and2Text = !!minutesText && !!secondsText ? " and " : "";
    return `${hoursText}${and1Text}${minutesText}${and2Text}${secondsText}`;
  }

  static portalTypeToDetails(portalType) {
    const name =
      PORTAL_TYPE_TO_DETAILS[portalType]?.portalTypeName || "Unknown";
    return {
      name: PORTAL_TYPE_TO_DETAILS[portalType]?.portalTypeName || "Unknown",
      nameVerb: PORTAL_TYPE_TO_DETAILS[portalType]?.portalTypeNameVerb || name,
      icon: PORTAL_TYPE_TO_DETAILS[portalType]?.portalTypeIcon || "web",
      explanation:
        PORTAL_TYPE_TO_DETAILS[portalType]?.portalTypeExplanation || "",
      templateContent:
        PORTAL_TYPE_TO_DETAILS[portalType]?.templateContent || null
    };
  }

  // Given a list of items and a string value to search for,
  // intelligently filter and sort those items.
  // Sorting is done based on the following prioritization:
  //    1. Items whose name starts with the exact search phrase
  //    2. Items whose name includes the exact search phrase
  //    3. Items whose name includes all words in the search phrase (in any order)
  // Example: for the search phrase "seabird ap", the output might be:
  //    1. "Seabird Apps Internal" (starts with "seabird ap")
  //    2. "Employees of Seabird Apps" (includes "seabird ap")
  //    3. "Applications for Seabirds" (includes "ap" and "seabird")
  static searchItems(allItems, searchPhraseRaw, textField) {
    const searchPhrase = (searchPhraseRaw || "").toLowerCase();
    const searchTerms = searchPhrase.split(" ");

    // filter the given list to only those items whose name includes all terms in our search phrase
    const filteredItems = allItems.filter((item) => {
      if (!item[textField]) return false;
      const itemName = item[textField].toLowerCase();

      // if there is any term in searchTerms that's not in this item's name, then don't show this item
      return searchTerms.filter((t) => itemName.indexOf(t) < 0).length < 1;
    });

    // sort the output as explained in this method's description
    const sortedItems = [...filteredItems].sort((a, b) => {
      const nameA = a[textField].toLowerCase();
      const nameB = b[textField].toLowerCase();
      let valueA;
      let valueB = 0;

      // if nameA starts with the search phrase, prioritize it first
      if (nameA.startsWith(searchPhrase)) valueA = 2;
      // if nameA includes all of the search phrase exactly, prioritize it second
      else if (nameA.indexOf(searchPhrase) > -1) valueA = 1;
      // otherwise, nameA includes all words in the search phrase, so prioritize it last

      // if nameB starts with the search phrase, prioritize it first
      if (nameB.startsWith(searchPhrase)) valueB = 2;
      // if nameB includes all of the search phrase exactly, prioritize it second
      else if (nameB.indexOf(searchPhrase) > -1) valueB = 1;
      // otherwise, nameB includes all words in the search phrase, so prioritize it last

      return valueB - valueA;
    });

    return sortedItems;
  }

  static fillStaticGoogleForm(formName, fields, submit = true) {
    const form = School.staticGoogleForm(formName);

    // Error handling
    if (!form || !form.fieldIDs) return "ERROR: Google Form not found";
    if (form.fieldIDs.length !== fields.length)
      return "ERROR: Google Form field arrays must be the same length";

    return sendForm(form.id, form.fieldIDs, fields, [], submit);
  }

  static fillDynamicForm(form, fields, submit = true) {
    const fieldIDs = form.questions
      .filter((q) => q.fieldID)
      .map((q) => q.fieldID);
    const fieldIDsWithOtherOption = form.questions
      .filter((q) => q.fieldID)
      .filter((q) => q.allowOther)
      .map((q) => q.fieldID);
    return sendForm(form.id, fieldIDs, fields, fieldIDsWithOtherOption, submit);
  }

  // Returns the number of days since year zero
  static getDaysSince0(date = new Date()) {
    return Moment(date).year() * 366 + Moment(date).dayOfYear();
  }

  static friendlyDate(date, { isLong = true } = {}) {
    const daysSince0 = this.getDaysSince0(date);
    const todayDaysSince0 = this.getDaysSince0(new Date());
    let dateString = null;
    // today
    if (daysSince0 === todayDaysSince0) {
      dateString = "Today";
    }
    // tomorrow
    else if (daysSince0 === todayDaysSince0 + 1) {
      dateString = "Tomorrow";
    }
    // yesterday
    else if (daysSince0 === todayDaysSince0 - 1) {
      dateString = "Yesterday";
    }
    // within the next week or previous week
    else if (
      daysSince0 < todayDaysSince0 + 7 &&
      daysSince0 > todayDaysSince0 - 7
    ) {
      dateString = `${Moment(date).format("dddd")}`;
    }
    // further than a week away
    else if (!dateString && !isLong) return Moment(date).format("MMMM Do");
    else if (!dateString) return Moment(date).format("dddd, MMMM Do");

    if (isLong) dateString += ` (${Moment(date).format("MMM D")})`;
    return dateString;
  }

  // Returns a public google sheet as a list of objects
  // More details: https://developers.google.com/sheets/api/guides/migration#v4-api_9
  // Note: All you need to do is share spreadsheet publicly, not actually publish it!
  static fetchGoogleSheetData(googleSheetID, sheetName, asObjects = true) {
    return new Promise((resolve, reject) => {
      fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${googleSheetID}/values/${sheetName}?key=${Glob.get(
          "googleAPIKey"
        )}`
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (!responseJson.values || responseJson.values.length < 1)
            return resolve([]);

          const fieldNames = responseJson.values[0]; // array of column names
          const rows = responseJson.values.slice(1); // array of arrays of cell values

          if (!asObjects) return resolve(rows);

          const allItems = rows.map((row) => {
            const item = {};
            row.forEach((val, i) => {
              item[fieldNames[i]] = val;
            });
            return item;
          });
          return resolve(allItems);
        })
        .catch(reject);
    });
  }

  static alert(title, message, buttons, options) {
    if (Platform.OS === "web") {
      if (message) return alert(`${title}\n\n${message}`);
      return alert(title);
    }
    return Alert.alert(title, message, buttons, options);
  }

  static reloadApp() {
    if (Platform.OS === "web") window.location.reload();
    Updates.reloadAsync();
  }

  static hashKeyForLocalStorage(key) {
    return `${Constants.expoConfig.slug}-${key}`;
  }

  static async localStorageDeleteItemAsync(key) {
    if (Platform.OS === "web") {
      try {
        return AsyncStorage.removeItem(this.hashKeyForLocalStorage(key));
      } catch (error) {
        console.log("error:");
        console.log(error);
      }
    }
    return SecureStore.deleteItemAsync(this.hashKeyForLocalStorage(key));
  }

  static async localStorageSetItemAsync(key, value) {
    if (Platform.OS === "web") {
      try {
        const jsonValue = JSON.stringify(value);
        return AsyncStorage.setItem(
          this.hashKeyForLocalStorage(key),
          jsonValue
        );
      } catch (error) {
        console.log("error:");
        console.log(error);
      }
    }
    return SecureStore.setItemAsync(this.hashKeyForLocalStorage(key), value);
  }

  // Because the regular SecureStore.getItemAsync persists across different apps
  static async localStorageGetItemAsync(key) {
    if (Platform.OS === "web") {
      try {
        const jsonValue = await AsyncStorage.getItem(
          this.hashKeyForLocalStorage(key)
        );
        return jsonValue != null ? JSON.parse(jsonValue) : null;
      } catch (error) {
        console.log("error:");
        console.log(error);
        return null;
      }
    }
    return new Promise((resolve) => {
      SecureStore.getItemAsync(this.hashKeyForLocalStorage(key)).then(
        (hyperLocalValue) => {
          if (hyperLocalValue) return resolve(hyperLocalValue);
          SecureStore.getItemAsync(key).then((normalValue) => {
            resolve(normalValue);
          });
        }
      );
    });
  }

  static alertRequestPermissions(
    message = "This app collects this type of data to enable this feature."
  ) {
    return new Promise((resolve) => {
      this.alert("", message, [
        { text: "Deny", onPress: () => resolve(false) },
        { text: "Accept", onPress: () => resolve(true) }
      ]);
    });
  }

  static pickMedia({ takeNew = false, type = "image" }) {
    return new Promise(async (resolve, reject) => {
      const cameraPermissions = await ImagePicker.getCameraPermissionsAsync();
      if (!cameraPermissions.granted) {
        if (Platform.OS === "android") {
          const allowedToRequest = await this.alertRequestPermissions(
            "This app needs to accesses your camera & media to enable uploading pictures & videos to the app for others to see."
          );
          if (!allowedToRequest) {
            reject();
            return;
          }
        }
        const newCameraPermissions = await ImagePicker.requestCameraPermissionsAsync();
        if (!newCameraPermissions.granted) {
          reject();
          return;
        }
      }

      const mediaTypes =
        type === "image"
          ? ImagePicker.MediaTypeOptions.Images
          : ImagePicker.MediaTypeOptions.Videos;

      let media;
      if (takeNew) {
        media = await ImagePicker.launchCameraAsync({
          mediaTypes,
          allowsEditing: true,
          ...(type === "image" ? { quality: 1 } : {})
        });
      } else {
        // Note: No permissions request is necessary for launching the image library
        media = await ImagePicker.launchImageLibraryAsync({
          mediaTypes,
          allowsEditing: true,
          ...(type === "image" ? { quality: 1 } : {})
        });
      }

      if (!media.cancelled) {
        const { uri } =
          "assets" in (media || {}) ? media.assets[0] : media || {};
        resolve(uri);
      }
    });
  }

  static webURL(options = {}) {
    const { baseURL, openAppStore, embedded } = options;
    return `${baseURL || Glob.get("webURL")}?app=${School.getDatabaseAppID()}${
      openAppStore ? `&openAppStore=true` : ""
    }${embedded ? `&embedded=true` : ""}`;
  }

  static setURLQueryString(queryString) {
    window.history.replaceState(null, null, queryString);
  }

  static cleanPortalContent(content) {
    // If this is a dynamic portal with content in it
    if (content?.content) {
      return {
        ...content,
        content: content.content.map((item) => {
          const cleanItem = { ...item };
          delete cleanItem.key;
          delete cleanItem.justCreated;
          delete cleanItem.dataSourceID;
          return cleanItem;
        })
      };
    }
    return content;
  }

  static addBulletPoints(text) {
    return `\u2022 ${(text || "").replace(/\n(?!\n)/g, "\n\u2022 ")}`;
  }

  // reads through a block of text and formats it correctly, identifying emails, phone numbers, etc.
  // NOTE: uses "{{info}}" as its format, so be careful when using "{{"
  // identifies: "{{p:...}}" as phone number, "{{e:...}}" as email, and "{{w:...}}" as web link
  // TODO (maybe): eventually should identify "{{type: 'phone', link: '...', text: '...'}}" as a more advanced phone/email/web link
  static textParser(
    text,
    { dataSourceRow, navigateToLink, bulletPoints = false } = {}
  ) {
    if (!text) return [];
    const formattedText = bulletPoints ? this.addBulletPoints(text) : text;
    const parts = formattedText.split("{{");

    // if there's at least one part of interest (phone number, email, link, etc)
    if (parts.length > 1) {
      const partsOut = [];
      parts.forEach((part) => {
        if (part.indexOf("}}") > -1) {
          const subParts = part.split("}}");
          const linkPart = subParts[0];

          if (linkPart.indexOf(":") === 1) {
            const linkPartType = linkPart.slice(0, 1); // e.g. "e", "p", "w"
            const link = linkPart.slice(2); // e.g. "somebody@gmail.com", "1-800-123-1234"
            let type = null;
            switch (linkPartType) {
              case "p":
                type = "phone";
                break;
              case "e":
                type = "email";
                break;
              case "w":
                type = "web";
                break;
              default:
                type = "web";
                break;
            }
            partsOut.push(
              <TouchableLink
                type={type}
                link={link}
                text={link}
                navigate={navigateToLink}
              />
            );
          } else {
            // Otherwise, this should be a variable indicating data pulled from a data source
            let value = "";
            if (dataSourceRow) {
              if (linkPart.includes(" || ")) {
                const [variable, backup] = linkPart.split(" || ");
                value = dataSourceRow[variable] || backup || "";
              } else {
                value = dataSourceRow[linkPart] || "";
              }
            }
            partsOut.push(value);
          }
          partsOut.push(subParts[1]);
        } else {
          partsOut.push(part);
        }
      });
      // otherwise: false alarm, this wasn't actually as link
      // TODO: handle more advanced links here
      return partsOut;
    }
    return parts;
  }

  static appIsStandalone() {
    return (
      !Glob.get("appIsOnespotlike") ||
      (Platform.OS === "web" && !!Rex.getConfig()?.isStandalone)
    );
  }

  // Determine which screen to navigate to when trying to join a specific databaseAppID
  static determineScreenWhenJoiningAppAsync(databaseAppID, appMetadata) {
    const { locked } = appMetadata;
    School.setDatabaseAppID(databaseAppID);
    return new Promise((resolve, reject) => {
      Database.fetchGlobalConfig().then((configData) => {
        Rex.setConfig(configData);
        // if the user is logged in
        if (Rex.getLoginStatus()) {
          Database.fetchAllUserData().then((data) => {
            // if they haven't yet created a /user node in this specific community, they won't have an email set
            if (!data?.email && locked && Glob.get("appIsOnespotlike"))
              return resolve("joinLockedApp");
            if (!data?.email) return resolve("welcome");
            return resolve("root");
          });
        } else if (locked && Glob.get("appIsOnespotlike"))
          return resolve("joinLockedApp");
        else return resolve("welcome");
      });
    });
  }

  static startChatWithSuperAdmin(navigation, userIsAdmin, isSamuel = false) {
    const superAdminFirstName = isSamuel ? "Samuel" : "Sean";
    const superAdminLastName = isSamuel
      ? "Buchanan (App Help)"
      : "Cann (App Help)";
    const superAdminUserID = isSamuel
      ? Glob.get("samuelBuchananUserID")
      : Glob.get("seanCannUserID");
    const firstName = userIsAdmin ? superAdminFirstName : "App Help/Feedback";
    const lastName = userIsAdmin ? superAdminLastName : "";
    const superAdminUser = {
      firstName,
      lastName,
      uid: IS_DEVELOPMENT_MODE
        ? "QcjQ7iTqIoX0eYf9lGGrZYv6Wak2"
        : superAdminUserID
    };
    navigation.push("notificationDetails", { chatRecipient: superAdminUser });
  }

  static filterPortals(portals) {
    if (!Rex.getConfig()?.granularSecurityEnabled) return portals;
    return portals.filter(
      (portal) =>
        !portal.allowedAccountTypes ||
        !Rex.getUserType() ||
        portal.allowedAccountTypes[Rex.getUserType()]
    );
  }

  static openURL(url) {
    if (Platform.OS === "web") window.open(url, "_blank");
    else Linking.openURL(url);
  }

  static requestAppStoreReviewIfNeverRequested() {
    this.localStorageGetItemAsync("requestedAppStoreReview").then(
      (requestedAppStoreReview) => {
        if (!requestedAppStoreReview) {
          StoreReview.isAvailableAsync().then((available) => {
            if (available) {
              this.localStorageSetItemAsync("requestedAppStoreReview", "true");
              StoreReview.requestReview();
            }
          });
        }
      }
    );
  }

  // Splits the full name of a user into first and last name
  // Note: If user enters three names, we ignore the middle one
  static splitUserName(fullNameText) {
    const cleanFullName = fullNameText.trim();
    if (cleanFullName.length < 1) return ["", ""];
    const nameList = cleanFullName.split(" ");
    // if there is more than one name entered
    if (nameList.length > 1) {
      return [nameList[0], nameList[nameList.length - 1]];
    }
    return [nameList[0], ""];
  }

  static parseEmailList(text) {
    let numInvalidEmails = 0;
    const emailList = text
      .replace(/ /g, "")
      .replace(/\n/g, ",")
      .replace(/\t/g, ",")
      .split(",")
      .filter((e) => {
        const isEmail = e.isEmail();
        if (!isEmail && e.length > 0) numInvalidEmails += 1;
        return isEmail;
      });
    return [emailList, numInvalidEmails];
  }

  static parsePhoneNumberList(text) {
    let numInvalidNumbers = 0;
    const numberList = text
      .replace(/\n/g, ",")
      .replace(/\t/g, ",")
      .replace(/[\(\)\-\s]/g, "")
      .split(",")
      .filter((n) => {
        const isNumber = n.isPhoneNumber();
        if (!isNumber && n.length > 0) numInvalidNumbers += 1;
        return isNumber;
      });
    return [numberList, numInvalidNumbers];
  }
}
