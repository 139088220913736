import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Platform,
  ActivityIndicator
} from "react-native";
import LottieView from "src/components/Lottie";
import Constants from "expo-constants";
import School from "school/school";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import SettingsItem from "src/components/SettingsItem";
import AppButton from "src/components/AppButton";
import Button from "src/components/Button";
import AlertModal from "src/components/AlertModal";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import SearchBar from "src/components/searchBar";
import {
  Ionicons,
  FontAwesome5,
  MaterialCommunityIcons
} from "@expo/vector-icons";

const NOTIFICATION_ANIMATION = require("resources/animations/notificationPulseRed.json");
const MORNING_ANIMATION = require("resources/animations/morningSun.json");
const AFTERNOON_ANIMATION = require("resources/animations/landscape.json");
const EVENING_ANIMATION = require("resources/animations/nighttime.json");
const BIRDS_ANIMATION = require("resources/animations/flyingBirds.json");

const { height, width } = Glob.get("dimensions");
const IS_ONESPOT_APP = Constants.expoConfig.slug === "onespot";
const IS_MONTESSORI_APP = Constants.expoConfig.slug === "montessori";
const IS_UNE_APP = Glob.get("appIsUphillNewEngland");
const MENU_WIDTH = 0.85 * width;
// const MONTESSORI_NON_SCHOOL_APPS = [
//   "-N2SIvszM1AkKSC5abvd", // Montessori Foundation
//   "-N2SKMfynExUlm3dj3a_", // International Montessori Council
//   "-N2SLlk4YSudCiiFndb_", // Montessori Family Alliance
//   "-N2SMSgW_zSQcnogFur-", // Montessori Foundation Store
//   "-NPe1k6-Xu7pQvuipp8G", // Montessori Marketplace
//   "-NQT7UnVvxrf4j3duySS" // Montessori Job Board
// ];

const HeaderAnimation = ({ currentHour, backgroundColor }) => {
  const [birdsFlew, setBirdsFlew] = useState(false);
  let animation;
  let aspectRatio;
  let loop = true;
  if (currentHour > 17 || currentHour < 4) {
    animation = EVENING_ANIMATION;
    aspectRatio = 1080 / 365;
    loop = false;
  } else if (currentHour < 12) {
    animation = MORNING_ANIMATION;
    aspectRatio = 594 / 222;
  } else {
    animation = AFTERNOON_ANIMATION;
    aspectRatio = 1200 / 675;
  }
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{
        height: MENU_WIDTH / aspectRatio,
        width: MENU_WIDTH,
        backgroundColor
      }}
      disabled={birdsFlew}
      onPress={() => {
        Analytics.logEvent("touch_rootMenu_headerImage");
        setBirdsFlew(true);
      }}
    >
      <LottieView
        style={{ height: "100%", width: "100%" }}
        autoPlay
        loop={loop}
        source={animation}
      />
      {birdsFlew && (
        <View
          style={{
            position: "absolute",
            height: MENU_WIDTH / aspectRatio,
            width: MENU_WIDTH
          }}
        >
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop={false}
            source={BIRDS_ANIMATION}
            resizeMode="cover"
          />
        </View>
      )}
    </TouchableOpacity>
  );
};

export default function RootMenu({
  navigation,
  closeMenu,
  appsJoined,
  appsWithUnopenedNotifications,
  fetchAllAppsSuperAdmin,
  checkRefresh,
  adminPrivileges,
  userIsDemoAccount
}) {
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [appsMetadata, setAppsMetadata] = useState(null);
  const [searchPhrase, setSearchPhrase] = useState(null);
  const [isRemovingApps, setIsRemovingApps] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_rootMenu");
    Database.listenUserFirstName((first) => {
      Database.listenUserLastName((last) => {
        setUserFirstName(first);
        setUserLastName(last);
      });
    });
    Database.onespotFetchAllAppsMetadata().then(setAppsMetadata);
  }, []);

  const navigate = (screenName, props = {}) => {
    closeMenu();
    navigation.push(screenName, props);
  };

  const calculateAppButtonStyle = (appID) => {
    const isCurrentApp = appID === School.getDatabaseAppID();
    if (isRemovingApps) {
      if (isCurrentApp) return { backgroundColor: "lightgray" };
      return {
        borderColor: "red",
        borderWidth: 3
      };
    }
    if (isCurrentApp)
      return { borderColor: Glob.get("primaryColor"), borderWidth: 3 };
    return {};
  };

  const onPressAppButton = (appID) => {
    const app = { ...appsMetadata[appID], key: appID };
    if (appID === School.getDatabaseAppID())
      setAlert({
        title: `You're currently in ${app.name}`
      });
    else if (isRemovingApps) {
      Analytics.logEvent("touch_rootMenu_removeApp", app);
      Database.removeJoinedAppIDFromGlobalUser(appID);
      const newAppsJoined = [...appsJoined];
      const index = newAppsJoined.indexOf(appID);
      if (index > -1) newAppsJoined.splice(index, 1);
      if (newAppsJoined.length < 2) setIsRemovingApps(false);
    } else {
      Analytics.logEvent("touch_rootMenu_joinApp", app);
      navigate("onespotJoin", {
        navigatedFromProfile: true,
        navigateToApp: app,
        fullName: `${userFirstName} ${userLastName}`
      });
    }
  };

  const settingsItemsContainerStyle = {
    paddingHorizontal: 15,
    paddingVertical: 5,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: -5 },
    shadowRadius: 3,
    position: "absolute",
    backgroundColor: "white",
    bottom: 0,
    elevation: 20,
    width: MENU_WIDTH
  };
  if (Platform.OS === "web")
    settingsItemsContainerStyle.boxShadow = "0px -5px 3px rgba(0,0,0,0.15)";

  const currentHour = new Date().getHours();
  let greeting;
  let backgroundColor;
  let textColor = "white";
  let textFontWeight = "normal";
  if (currentHour > 17 || currentHour < 4) {
    greeting = "Good Evening";
    backgroundColor = "#1f0041";
  } else if (currentHour < 12) {
    greeting = "Good Morning";
    backgroundColor = "white";
    textColor = "#002851";
    textFontWeight = "bold";
  } else {
    greeting = "Good Afternoon";
    backgroundColor = "#b3d9f2";
  }

  let filteredAppsJoined = appsJoined;
  if (searchPhrase) {
    const allApps = appsJoined
      .map((appID) => ({ ...appsMetadata[appID], appID }))
      .filter((a) => a.name);
    const filteredApps = Util.searchItems(allApps, searchPhrase, "name");
    filteredAppsJoined = filteredApps.map((a) => a.appID);
  }
  filteredAppsJoined.sort((a, b) => {
    if (appsWithUnopenedNotifications.has(a)) return -1;
    if (appsWithUnopenedNotifications.has(b)) return 1;
    const nameA =
      appsMetadata &&
      appsMetadata[a] &&
      appsMetadata[a]?.name &&
      appsMetadata[a]?.name.toLowerCase();
    const nameB =
      appsMetadata &&
      appsMetadata[b] &&
      appsMetadata[b]?.name &&
      appsMetadata[b]?.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  const userCanPublish =
    !!adminPrivileges && adminPrivileges?.includes("Publish");
  const allowCreatingSpots =
    IS_ONESPOT_APP ||
    (Glob.get("appIsOnespotlike") &&
      !!Rex.getMetaApp()?.appCreation?.enabled &&
      (userCanPublish ||
        !Rex.getMetaApp()?.appCreation?.limitToAdminsWhoCanPublish));

  return (
    <View
      style={{
        position: "absolute",
        width: MENU_WIDTH,
        height: "100%",
        left: 0,
        flex: 1,
        backgroundColor
      }}
    >
      <ScrollView
        contentContainerStyle={[
          { paddingBottom: 250, minHeight: height },
          Platform.OS === "web" ? { alignItems: "center" } : {}
        ]}
        keyboardShouldPersistTaps="always"
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View style={{ maxHeight: "40%", maxWidth: width }}>
          <HeaderAnimation
            currentHour={currentHour}
            backgroundColor={backgroundColor}
          />
          <View
            style={{ position: "absolute", bottom: 0, paddingHorizontal: 15 }}
          >
            <Text
              style={[
                styles.textHeader,
                { color: textColor, fontWeight: textFontWeight }
              ]}
            >
              {userFirstName ? `${greeting}, ${userFirstName}` : `${greeting}`}
            </Text>
          </View>
        </View>
        <View style={styles.settingsContainer}>
          {!!appsMetadata && appsJoined?.length > 8 && !isRemovingApps && (
            <SearchBar
              customWidth="90%"
              placeholder="Search"
              onChangeText={setSearchPhrase}
            />
          )}
          <View style={styles.headerTextContainer}>
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              Your spots:
            </Text>
          </View>
          {!appsMetadata && (
            <ActivityIndicator size="large" style={{ marginVertical: 20 }} />
          )}
          {!!appsMetadata &&
            filteredAppsJoined &&
            filteredAppsJoined.map((appID) =>
              appsMetadata[appID] ? (
                <View
                  style={{
                    paddingHorizontal: 15,
                    width: MENU_WIDTH,
                    maxWidth: 600 // for tablets/web
                  }}
                >
                  <AppButton
                    app={appsMetadata[appID]}
                    onPress={() => onPressAppButton(appID)}
                    style={[{ width: "100%" }, calculateAppButtonStyle(appID)]}
                  />
                  {appsWithUnopenedNotifications.has(appID) && (
                    <LottieView
                      style={styles.newNotificationPulse}
                      autoPlay
                      loop
                      source={NOTIFICATION_ANIMATION}
                    />
                  )}
                </View>
              ) : null
            )}
          {!isRemovingApps && (
            <Button
              text={`Join ${IS_UNE_APP ? "GBA" : "another spot"}`}
              icon="plus"
              color={Glob.get("primaryColor")}
              textStyle={{ fontWeight: "bold" }}
              onPress={() => {
                Analytics.logEvent("touch_rootMenu_joinSpot");
                navigate("onespotJoin", {
                  navigatedFromProfile: true,
                  fullName: `${userFirstName} ${userLastName}`,
                  appsJoined
                });
              }}
            />
          )}
          {!isRemovingApps && IS_MONTESSORI_APP && (
            <>
              <Button
                text="Add a new school"
                icon="building"
                color={Glob.get("onespotYellow")}
                textStyle={{
                  fontWeight: "bold",
                  color: Glob.get("primaryColor")
                }}
                onPress={() => {
                  Analytics.logEvent("touch_rootMenu_addNewSchool");
                  navigate("webNav", {
                    title: "Add Your School",
                    url: Glob.get("formRequestMontessoriApp")
                  });
                }}
              />
              <Button
                text="What's a Montessori school app?"
                icon="9b96f4fa-835b-4f6b-8769-b67e4dc6ce74" // question mark
                flat
                color="#fff8c2"
                textStyle={{
                  fontWeight: "bold",
                  color: Glob.get("primaryColor")
                }}
                onPress={() => {
                  Analytics.logEvent("touch_rootMenu_whatIsASchoolApp");
                  navigate("webNav", {
                    title: "Onespot School Apps",
                    url: "https://youtu.be/avE1mxkjf1k"
                  });
                }}
              />
            </>
          )}
          {!isRemovingApps && allowCreatingSpots && (
            <Button
              text="Make a new spot"
              icon="0a94f591-4df6-4f80-abc5-2c374cf25eb9" // create-outline
              color={Glob.get("onespotYellow")}
              textStyle={{
                fontWeight: "bold",
                color: Glob.get("primaryColor")
              }}
              onPress={() => {
                Analytics.logEvent("touch_rootMenu_makeAnAppBigButton");
                navigate("onespotCreateType", {
                  navigatedFromProfile: true,
                  fullName: `${userFirstName} ${userLastName}`
                });
              }}
            />
          )}
          {!!appsMetadata && appsJoined && appsJoined.length > 1 && (
            <>
              {isRemovingApps ? (
                <Button
                  text="done removing spots"
                  icon="check"
                  color={Glob.get("primaryColor")}
                  onPress={() => {
                    Analytics.logEvent("touch_rootMenu_doneRemovingSpots");
                    setIsRemovingApps(false);
                  }}
                />
              ) : (
                <TouchableLink
                  type="button"
                  text="- remove a spot"
                  textStyle={{
                    color: Glob.get("dangerRed"),
                    textAlign: "center",
                    marginTop: 20
                  }}
                  onPress={() => {
                    Analytics.logEvent("touch_rootMenu_removeSpots");
                    setIsRemovingApps(true);
                    setAlert({
                      title: "🧹 Let's clean up!",
                      message:
                        "Tap any red app to remove it from your list. Don't worry, you can always add it back by joining it again."
                    });
                  }}
                />
              )}
            </>
          )}
        </View>
      </ScrollView>
      <View style={settingsItemsContainerStyle}>
        {allowCreatingSpots && (
          <SettingsItem
            text="Make a new spot"
            icon={
              <Ionicons
                name="create-outline"
                size={22}
                color={Glob.get("gray")}
              />
            }
            onPress={() => {
              Analytics.logEvent("touch_rootMenu_makeAnApp");
              navigate("onespotCreateType", {
                navigatedFromProfile: true,
                fullName: `${userFirstName} ${userLastName}`
              });
            }}
          />
        )}
        <SettingsItem
          text="Share this app"
          icon={
            <Ionicons
              name="share-social-outline"
              size={22}
              color={Glob.get("gray")}
            />
          }
          onPress={() => {
            Analytics.logEvent("touch_rootMenu_shareApp");
            navigate("shareApp");
          }}
        />
        <SettingsItem
          text="Leave a suggestion"
          icon={
            <MaterialCommunityIcons
              name="lightbulb-on-outline"
              size={22}
              color={Glob.get("gray")}
            />
          }
          onPress={() => {
            Analytics.logEvent("touch_rootMenu_suggestionBox");
            navigate("dynamicForm", {
              navName: "SuggestionBox",
              isGlobalForm: true
            });
          }}
        />
        <SettingsItem
          text="Profile & preferences"
          icon={
            <FontAwesome5 name="user-alt" size={22} color={Glob.get("gray")} />
          }
          onPress={() => {
            Analytics.logEvent("touch_rootMenu_profilePreferences");
            navigate("settings", {
              checkRefresh,
              userIsDemoAccount
            });
          }}
        />
        {Database.userIsSuperAdmin() && Platform.OS === "web" && (
          <SettingsItem
            text={`🕶 See ALL apps (super admins only)\nTakes >1 minute to find unread messages`}
            icon={
              <MaterialCommunityIcons
                name="message-text-lock-outline"
                size={22}
                color={Glob.get("gray")}
              />
            }
            onPress={fetchAllAppsSuperAdmin}
          />
        )}
      </View>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  settingsContainer: {
    width: MENU_WIDTH,
    minHeight: "100%",
    backgroundColor: "white",
    alignItems: "center"
  },
  headerTextContainer: {
    paddingHorizontal: 15,
    paddingTop: 0.02 * height
  },
  textHeader: {
    fontSize: 28,
    marginBottom: 0.006 * height
  },
  newNotificationPulse: {
    width: 40,
    height: 40,
    position: "absolute",
    right: 3,
    top: 0,
    elevation: 10
  }
});
