import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Platform,
  ActivityIndicator
} from "react-native";
import {
  Octicons,
  Ionicons,
  MaterialIcons,
  Entypo,
  FontAwesome5
} from "@expo/vector-icons";
import { Stagger, useDisclose } from "native-base";
import LottieView from "src/components/Lottie";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Rex from "src/globalState";
import Util from "src/utility";
import Button from "src/components/Button";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import SearchBar from "src/components/searchBar";
import AlertModal from "src/components/AlertModal";
import FloatingActionButton from "src/components/FloatingActionButton";
import Icon from "src/components/Icon";

const MAILBOX_ANIMATION = require("resources/animations/mailbox.json");

const { height, width, fullWidth } = Glob.get("dimensions");

const ICON_SIZE = Math.min(
  0.07 * width,
  Glob.deviceHasTabletDimensions() ? 42 : 26
);

const Notification = ({
  notification,
  navigation,
  markNotificationAsOpened,
  deleteNotification,
  isDeleting,
  onUpdatePortal
}) => {
  let type = "notification";
  if (notification.chat) type = "chat";
  if (notification.isActivityFeed) type = "activityFeed";
  return (
    <TouchableOpacity
      key={notification.key}
      style={styles.notificationContainer}
      activeOpacity={0.7}
      onPress={() => {
        if (notification.isActivityFeed && notification.portal) {
          const {
            txtName,
            key: navName,
            icon,
            restrictedToFeedSubscribers,
            allowedAccountTypes
          } = notification.portal;
          navigation.push("activityFeed", {
            txtName,
            navName,
            restrictedToFeedSubscribers,
            allowedAccountTypes,
            icon,
            portalType: "activityFeed",
            onUpdatePortal
          });
        } else {
          markNotificationAsOpened(notification.key);
          navigation.push("notificationDetails", {
            notification
          });
        }
      }}
    >
      <TouchableOpacity
        style={styles.notificationLeftContainer}
        disabled={!isDeleting}
        onPress={() => (isDeleting ? deleteNotification(notification.key) : {})}
      >
        {isDeleting && (
          <MaterialIcons
            name="delete"
            size={24}
            color={Glob.get("dangerRed")}
            style={{ marginRight: 8 }}
          />
        )}
        {!isDeleting && !!notification.unopened && (
          <Octicons
            name="dot-fill"
            size={20}
            color={Rex.getConfig()?.colors?.button}
          />
        )}
        {!isDeleting && (
          <View style={{ marginLeft: 4, marginRight: 8 }}>
            {type === "notification" && (
              <Ionicons
                name={`notifications${notification.unopened ? "" : "-outline"}`}
                size={24}
                color={Glob.get("gray")}
              />
            )}
            {type === "chat" && (
              <Ionicons
                name={`chatbubble${notification.unopened ? "" : "-outline"}`}
                size={24}
                color={Glob.get("gray")}
              />
            )}
            {type === "activityFeed" && (
              <Icon
                icon={
                  notification?.portal?.icon ||
                  "8bbff17c-69ee-40b4-9774-5b4db7c1d211"
                }
                size={24}
                color={Rex.getConfig()?.colors?.text}
              />
            )}
          </View>
        )}
      </TouchableOpacity>
      <View style={styles.notificationContentContainer}>
        <View style={styles.notificationTitleContainer}>
          <Text
            style={[
              styles.notificationTitle,
              notification.isActivityFeed
                ? { color: Rex.getConfig()?.colors?.text }
                : {}
            ]}
          >
            {notification?.title?.truncate(24) || "Message"}
          </Text>
          <Text style={styles.notificationDate}>
            {Util.friendlyDate(notification.date, { isLong: false })}
          </Text>
        </View>
        <Text
          style={[
            styles.notificationBody,
            notification.isActivityFeed
              ? { color: Rex.getConfig()?.colors?.text }
              : {}
          ]}
        >
          {notification.isActivityFeed && (
            <Text style={{ fontWeight: "normal" }}>Latest Message: </Text>
          )}
          {(notification.body || "").truncate(
            notification.isActivityFeed ? 72 : 90
          )}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default function Notifications({ navigation, route }) {
  const { adminPrivileges, onUpdatePortal } = route?.params || {};
  const [notifications, setNotifications] = useState(null);
  // const [isSchool, setIsSchool] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState(false);
  const [alert, setAlert] = useState(null);

  const { isOpen, onToggle } = useDisclose();

  const allowedToManageUsers =
    !!adminPrivileges && adminPrivileges?.includes("ManageUsers");
  const allowedToSendNotifications =
    !!adminPrivileges && adminPrivileges?.includes("PushNotifications");

  useEffect(() => {
    Analytics.logEvent("view_notifications");
    // todo: maybe change how notifications for new messages are sent and do it similar to how they're sent for new 1-1 chats
    Database.fetchAllActivityFeeds({ sorted: true }).then(
      ({ activityFeeds = [] }) => {
        const feedsFormatted = activityFeeds
          // todo: handle empty activity feeds (with no mostRecentPost)
          .filter(
            (feed) => feed.userIsSubscribed && feed?.mostRecentPost?.timestamp
          )
          .map((feed) => ({
            key: feed?.activityFeedID,
            title: feed?.title,
            body: `"${feed?.mostRecentPost?.message}"`,
            date: feed?.mostRecentPost?.timestamp,
            portal: feed?.portalMetadata,
            isActivityFeed: true,
            searchableText: `${feed.title} ${feed?.mostRecentPost?.message}`
          }));

        Database.subscribeToUserNotifications((newNotifications) => {
          if (!newNotifications || newNotifications.length < 1)
            setIsDeleting(false);
          const notificationsFormatted = newNotifications.map((n) => ({
            ...n,
            searchableText: `${n.body} ${n.title}`
          }));
          const unsortedNotifications = [
            ...feedsFormatted,
            ...notificationsFormatted
          ];
          const sortedNotifications = unsortedNotifications.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setNotifications(sortedNotifications);
          Database.markUserNotificationsAsOpened();
        });
      }
    );
    // Database.fetchAppMetadata().then((appMetadata) =>
    //   setIsSchool(appMetadata?.communityType === "school")
    // );

    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromUserNotifications();
    };
  }, []);

  // This just updates the state value to mark it as opened
  const markNotificationAsOpened = (notificationID) => {
    setNotifications((prevState) => {
      const notificationIndex = prevState.findIndex(
        (n) => n.key === notificationID
      );
      const newState = [...prevState];
      newState[notificationIndex].unopened = false;
      return newState;
    });
  };

  // Delete this notification
  const deleteNotification = (notificationID) => {
    Analytics.logEvent("touch_notifications_deleteNotification");
    Database.deleteUserNotification(notificationID);
  };

  // Delete ALL the users notifications
  const deleteAllNotifications = () => {
    Analytics.logEvent("touch_notifications_deleteAllNotifications");
    Database.deleteAllUserNotifications();
  };

  const onPressNewNotification = () => {
    Analytics.logEvent("touch_notifications_createNewPushNotification");
    navigation.push("sendPushNotification");
    if (isOpen) onToggle();
  };

  const onPressNewChat = () => {
    Analytics.logEvent("touch_notifications_createNewChat");
    navigation.push("selectUsersForChat");
    if (isOpen) onToggle();
  };

  const onPressChatWithSeabirder = () => {
    Analytics.logEvent("touch_notifications_askForHelp");
    Util.startChatWithSuperAdmin(navigation, !!adminPrivileges, true);
    if (isOpen) onToggle();
  };

  const onPressFloatingActionButton = () => {
    Analytics.logEvent("touch_notifications_pressNewChatButton");
    if (allowedToManageUsers || allowedToSendNotifications) onToggle();
    else onPressChatWithSeabirder();
  };

  const FloatingActionButtonIcon = () => {
    if (isOpen) return <Entypo name="chevron-down" size={40} color="white" />;
    if (allowedToManageUsers || allowedToSendNotifications)
      return <Ionicons name="create-outline" size={40} color="white" />;
    return <FontAwesome5 name="question-circle" size={40} color="white" />;
  };

  const renderFloatingActionButtonComponent = () => {
    return (
      <>
        <View
          style={{
            width: 1, // so it doesn't block user from tapping on messages
            position: Platform.OS === "web" ? "fixed" : "absolute",
            right: 30,
            bottom: 120,
            justifyContent: "center",
            alignItems: "flex-end"
          }}
        >
          <Stagger
            visible={isOpen}
            initial={{
              opacity: 0,
              scale: 0,
              translateY: 34
            }}
            animate={{
              translateY: 0,
              scale: 1,
              opacity: 1,
              transition: {
                type: "spring",
                mass: 0.6,
                stagger: {
                  offset: 30,
                  reverse: true
                }
              }
            }}
            exit={{
              translateY: 34,
              scale: 0.5,
              opacity: 0,
              transition: {
                duration: 100,
                stagger: {
                  offset: 30,
                  reverse: true
                }
              }
            }}
          >
            {allowedToSendNotifications ? (
              <Button
                text="New Notification"
                icon="send"
                onPress={onPressNewNotification}
              />
            ) : (
              <></>
            )}
            {allowedToManageUsers ? (
              <Button text="New Chat" icon="edit" onPress={onPressNewChat} />
            ) : (
              <></>
            )}
            <Button
              text="Ask for Help"
              icon="person"
              onPress={onPressChatWithSeabirder}
            />
          </Stagger>
        </View>

        <FloatingActionButton
          icon={<FloatingActionButtonIcon />}
          onPress={onPressFloatingActionButton}
        />
      </>
    );
  };

  if (notifications === null)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Messages" />
        <View style={styles.noMessagesContainer}>
          <ActivityIndicator size="large" style={{ marginTop: 20 }} />
        </View>
      </View>
    );

  let RightButton = null;
  if (notifications?.length > 0)
    RightButton = (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          alignItems: "flex-end",
          justifyContent: "center",
          paddingRight: 10
        }}
        activeOpacity={0.6}
        onPress={() => {
          if (!isDeleting)
            Analytics.logEvent("touch_notifications_startDeleting");
          else Analytics.logEvent("touch_notifications_doneDeleting");
          setIsDeleting(!isDeleting);
        }}
      >
        {isDeleting ? (
          <Entypo name="check" size={ICON_SIZE} color="white" />
        ) : (
          <MaterialIcons name="delete" size={ICON_SIZE} color="white" />
        )}
      </TouchableOpacity>
    );

  if (notifications?.length < 1)
    return (
      <View style={styles.pageContent}>
        <NavBar
          navigation={navigation}
          text="Messages"
          RightButton={RightButton}
        />
        <View style={{ height: height / 2, width, alignItems: "center" }}>
          <LottieView
            autoPlay
            loop={false}
            style={{ height: "100%", width: "100%" }}
            source={MAILBOX_ANIMATION}
          />
        </View>
        <View style={styles.noMessagesContainer}>
          <Text style={styles.noMessagesTextLarge}>You're all caught up!</Text>
          <Text style={{ color: "gray" }}>
            You haven't received any messages yet.
          </Text>
        </View>
        {renderFloatingActionButtonComponent()}
      </View>
    );

  let notificationsFiltered = notifications;
  if (searchPhrase)
    notificationsFiltered = Util.searchItems(
      notificationsFiltered,
      searchPhrase,
      "searchableText"
    );
  if (isDeleting)
    notificationsFiltered = notificationsFiltered.filter(
      (n) => !n.isActivityFeed
    );

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text="Messages"
        RightButton={RightButton}
      />
      {isDeleting && (
        <View
          style={{
            width: "100%",
            paddingVertical: 20,
            borderBottomWidth: 1,
            borderBottomColor: "lightgray",
            paddingHorizontal: 15
          }}
        >
          <Text style={[styles.notificationBody, { textAlign: "center" }]}>
            Clean up your inbox! Touch the red icon to the left of any message
            to permanently delete it.
          </Text>
          <Button
            text="Done"
            icon="check"
            onPress={() => {
              Analytics.logEvent("touch_notifications_doneDeleting");
              setIsDeleting(false);
            }}
          />
          <TouchableLink
            type="button"
            text="💥 Clear all messages"
            textStyle={{
              color: Glob.get("dangerRed"),
              textAlign: "center",
              marginTop: 10
            }}
            onPress={() => {
              Analytics.logEvent(
                "touch_notifications_pressedDeleteAllMessages"
              );
              setAlert({
                title: "✋ Are you sure?",
                message: "This will permanently delete all your notifications.",
                cancel: { text: "Cancel" },
                confirm: {
                  text: "Permanently Delete All",
                  color: Glob.get("dangerRed"),
                  onPress: deleteAllNotifications
                }
              });
            }}
          />
        </View>
      )}
      <SearchBar placeholder="Search" onChangeText={setSearchPhrase} />
      <FlatList
        contentContainerStyle={{
          paddingBottom: 40,
          width: fullWidth,
          alignItems: "center"
        }}
        scrollIndicatorInsets={{ right: 1 }}
        data={notificationsFiltered}
        renderItem={({ item }) => (
          <Notification
            notification={item}
            navigation={navigation}
            markNotificationAsOpened={markNotificationAsOpened}
            deleteNotification={deleteNotification}
            isDeleting={isDeleting}
            onUpdatePortal={onUpdatePortal}
          />
        )}
        keyExtractor={(item) => item.key}
      />
      {renderFloatingActionButtonComponent()}
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    alignItems: "center",
    backgroundColor: "white",
    height
  },
  formDescription: {
    marginVertical: 20
  },
  noMessagesContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20
  },
  noMessagesTextLarge: {
    fontSize: 30,
    marginVertical: 20,
    color: "gray"
  },
  notificationContainer: {
    flexDirection: "row",
    width
  },
  notificationLeftContainer: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    flex: 2
  },
  notificationContentContainer: {
    borderColor: "lightgray",
    borderBottomWidth: 1,
    paddingVertical: 10,
    flex: 12,
    paddingRight: 5
  },
  notificationTitleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: 10
  },
  notificationTitle: {
    fontSize: 20
  },
  notificationDate: {
    fontSize: 12,
    color: "gray"
  },
  notificationBody: {
    fontSize: 15
  }
});
