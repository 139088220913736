import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import Moment from "moment";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Button from "src/components/Button";
import { ScrollView } from "react-native-gesture-handler";
import CircularProgress from "src/components/CircularProgress";
import HelpText from "src/components/HelpText";

const { width } = Glob.get("dimensions");

const BENCHMARK_VALUES = {
  day: {
    low: 2,
    mid: 5,
    high: 12
  },
  week: {
    low: 5,
    mid: 10,
    high: 15
  },
  month: {
    low: 10,
    mid: 18,
    high: 26
  },
  quarter: {
    low: 15,
    mid: 30,
    high: 40
  }
};

const BENCHMARK_NOTE = {
  veryLow:
    "That retention rate is a bit lower than most other apps. Consider sending some out helpful notifications or using the Banner Message feature more frequently.",
  low: "That retention rate is pretty normal compared to other apps.",
  mid: "That retention rate is higher than most apps. Great work!",
  high: "Wow! That retention rate is way higher than most other apps."
};

const BENCHMARK_COLOR = {
  veryLow: "#F5CB5C",
  low: "#1B9AAA",
  mid: "#2DD881",
  high: "#2DD881"
};

const calculateUsersInLastDays = (users, fieldName, days) => {
  const usersInTimeRange = users
    .filter((user) => {
      const previousDate = Moment().subtract(days, "days");
      const isAfterDays =
        !!user[fieldName] && Moment(user[fieldName]).isAfter(previousDate);
      return isAfterDays;
    })
    .map((user) => user.uid);
  return usersInTimeRange || [];
};

const showAsPercent = (value) => {
  return Math.round(value * 100 || 0);
};

const AnalyticsCard = ({ timePeriod, users, percentOfUsers, navigation }) => {
  const numberOfUsers = users?.length;
  let rating = "veryLow";
  if (percentOfUsers > BENCHMARK_VALUES[timePeriod].high) rating = "high";
  else if (percentOfUsers > BENCHMARK_VALUES[timePeriod].mid) rating = "mid";
  else if (percentOfUsers > BENCHMARK_VALUES[timePeriod].low) rating = "low";

  let note = BENCHMARK_NOTE[rating];
  let title = "Today";
  let timePhrase = "24 hours";
  switch (timePeriod) {
    case "day":
      title = "Today";
      timePhrase = "24 hours";
      if (rating === "veryLow")
        note =
          "That retention rate is a bit low, but daily usage can fluctuate dramatically on any given day.";
      break;
    case "week":
      title = "This Week";
      timePhrase = "7 days";
      break;
    case "month":
      title = "This Month";
      timePhrase = "30 days";
      break;
    case "quarter":
      title = "This Quarter";
      timePhrase = "90 days";
      break;
    default:
      break;
  }
  return (
    <View style={[Style.get("card"), { paddingBottom: 10 }]}>
      <Text style={styles.label}>{title}</Text>
      <Text style={styles.subLabel}>Over the last {timePhrase}</Text>
      <View style={{ alignItems: "center", marginVertical: 20 }}>
        <CircularProgress
          value={percentOfUsers}
          color={BENCHMARK_COLOR[rating]}
        />
      </View>
      <Text>
        <Text style={styles.mediumText}>{numberOfUsers} </Text>
        <Text style={styles.description}>
          {numberOfUsers === 1 ? "person" : "people"} used your app.
        </Text>
      </Text>
      <Text>
        <Text style={styles.mediumText}>{percentOfUsers}% </Text>
        <Text style={styles.description}>of your members used your app.</Text>
      </Text>
      {users?.length > 0 && (
        <Button
          text="View users"
          flat
          small
          outline
          color={BENCHMARK_COLOR[rating]}
          icon="cf8121e5-b4bf-4b32-96cb-fd059c1bd913" // filter
          onPress={() =>
            navigation.push("analyticsViewUsers", {
              filteredUserIDs: users,
              filteredMessageSuffix: `who opened this app within the past ${timePhrase}`
            })
          }
        />
      )}
      <HelpText text={note} noteStyle />
    </View>
  );
};

export default function AppAnalytics({ navigation }) {
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [DAUs, setDAUs] = useState(null);
  const [WAUs, setWAUs] = useState(null);
  const [MAUs, setMAUs] = useState(null);
  const [QAUs, setQAUs] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_appAnalytics");
    Database.fetchAllUsers(false).then((fetchedUsers) => {
      setAllUsers(fetchedUsers);
      setDAUs(
        calculateUsersInLastDays(fetchedUsers, "lastViewedRootAtTimestamp", 1)
      );
      setWAUs(
        calculateUsersInLastDays(fetchedUsers, "lastViewedRootAtTimestamp", 7)
      );
      setMAUs(
        calculateUsersInLastDays(fetchedUsers, "lastViewedRootAtTimestamp", 30)
      );
      setQAUs(
        calculateUsersInLastDays(fetchedUsers, "lastViewedRootAtTimestamp", 90)
      );
      setLoading(false);
    });
  }, []);

  const percentDAUs = showAsPercent(DAUs?.length / allUsers.length);
  const percentWAUs = showAsPercent(WAUs?.length / allUsers.length);
  const percentMAUs = showAsPercent(MAUs?.length / allUsers.length);
  const percentQAUs = showAsPercent(QAUs?.length / allUsers.length);

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="App Usage" />
      {loading ? (
        <ActivityIndicator size="large" style={{ marginTop: 20 }} />
      ) : (
        <ScrollView
          contentContainerStyle={{ alignItems: "center" }}
          style={{ width, height: "100%", paddingHorizontal: 20 }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <AnalyticsCard
            timePeriod="day"
            users={DAUs}
            percentOfUsers={percentDAUs}
            navigation={navigation}
          />
          <AnalyticsCard
            timePeriod="week"
            users={WAUs}
            percentOfUsers={percentWAUs}
            navigation={navigation}
          />
          <AnalyticsCard
            timePeriod="month"
            users={MAUs}
            percentOfUsers={percentMAUs}
            navigation={navigation}
          />
          <AnalyticsCard
            timePeriod="quarter"
            users={QAUs}
            percentOfUsers={percentQAUs}
            navigation={navigation}
          />
          <View style={Style.get("card")}>
            <Text style={styles.label}>All Time</Text>
            <Text style={styles.bigText}>{allUsers.length}</Text>
            <Text style={styles.description}>
              {allUsers.length === 1
                ? "person is a member"
                : "people are members"}{" "}
              of your app.
            </Text>
            <View style={{ marginTop: 15 }}>
              <Button
                text="Invite more members"
                icon="plus"
                onPress={() => navigation.push("inviteUsers")}
              />
            </View>
          </View>
        </ScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    width: "100%",
    height: "100%",
    alignItems: "center",
    backgroundColor: "white"
  },
  card: {
    backgroundColor: "white",
    marginVertical: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 10
  },
  bigText: {
    fontWeight: "bold",
    fontSize: 80,
    marginTop: 10
  },
  mediumText: {
    fontWeight: "bold",
    fontSize: 24,
    marginTop: 10
  },
  description: {
    ...Style.get("subheaderText")
  },
  label: {
    ...Style.get("subheaderText"),
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 20
  },
  subLabel: {
    ...Style.get("subheaderText"),
    textAlign: "left"
  }
});
