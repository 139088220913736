// QR codes need to be formatted as: `{"placeID": "dartmouthskiway"}` or `{"placeID": "dartmouthskiway", "signID": "biggreen1"}`

import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from "react-native";
import moment from "moment";
import ConfettiCannon from "react-native-confetti-cannon";
import * as Haptics from "expo-haptics";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as SecureStore from "expo-secure-store";
import { Ionicons } from "@expo/vector-icons";
import Firebase from "src/backend/firebase";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Style from "src/globalStyles";
import NavBar from "src/components/navBar";
import Util from "src/utility";
import Glob from "src/globalConstants";
import Button from "src/components/Button";
import ButtonItem from "src/components/dynamicContent/ButtonItem";
import DigitalID from "src/components/DigitalID";
import LottieView from "src/components/Lottie";
import StatusMessage from "src/components/StatusMessage";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");
const SCREEN_NAME = "Slope Scanner";
const SNOW_ANIMATION = require("resources/animations/snowFalling.json");

const VALID_MEMBER_ACCOUNT_TYPES = ["member", "staff"];

export default function PlaceCheckin({ navigation, route }) {
  // get onCheckIn from route
  const { onCheckIn = () => {} } = route?.params || {};
  const [alert, setAlert] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [userHasMembership, setUserHasMembership] = useState(false);
  const [hasPermission, setHasPermission] = useState(null);
  const [scanningQRCode, setScanningQRCode] = useState(false);
  const [scanningProblem, setScanningProblem] = useState(false);
  const [allPlaces, setAllPlaces] = useState(null);
  const [allSigns, setAllSigns] = useState(null);
  const [place, setPlace] = useState(null);
  const [sign, setSign] = useState(null);
  const [checkinTime, setCheckinTime] = useState(null);
  const [lapStartTime, setLapStartTime] = useState(null);
  const [justCheckedIn, setJustCheckedIn] = useState(false);
  // const [currentTime, setCurrentTime] = useState(moment());

  const requestCameraPermissions = async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === "granted");
  };

  useEffect(() => {
    Analytics.logEvent("view_placeCheckIn");
    if (Platform.OS === "android") {
      BarCodeScanner.getPermissionsAsync().then(({ status }) => {
        if (status === "granted") setHasPermission(true);
        else
          Util.alertRequestPermissions(
            "This app accesses your camera to scan posted QR codes. We will not store or transmit image data."
          ).then((allowedToAsk) => {
            if (allowedToAsk) requestCameraPermissions();
            else setHasPermission(false);
          });
      });
    } else {
      requestCameraPermissions();
    }
    Database.fetchAllUserData().then((userData) => {
      const { firstName, lastName, email: userEmail, type } = userData;
      setFullName(`${firstName} ${lastName}`);
      setEmail(userEmail);
      setUserHasMembership(VALID_MEMBER_ACCOUNT_TYPES.includes(type));
    });
    const nowET = moment().subtract(5, "hours"); // Eastern Time
    const nowDayOfYear = nowET.dayOfYear();
    // Fetch all resort locations
    Util.fetchGoogleSheetData(
      "1kxZZQZM-DNEbQf9c4K0UeHvKOdyeYeU9fmdquO85fpk",
      "All Locations"
    )
      .then((data) => {
        const locations = {};
        data.forEach((location) => {
          locations[location.ID] = location;
        });
        setAllPlaces(locations);
        SecureStore.getItemAsync("placeCheckInLocation").then(
          (storedPlaceID) => {
            SecureStore.getItemAsync("placeCheckInTime").then((storedTime) => {
              if (storedTime) {
                const storedTimeET = moment(storedTime, "x").subtract(
                  5,
                  "hours"
                ); // Eastern Time
                const storedTimeDayOfYear = storedTimeET.dayOfYear();
                Analytics.logEvent("action_placeCheckIn_fetchedStoredPlace", {
                  storedPlaceID,
                  storedTime,
                  nowDayOfYear,
                  storedTimeDayOfYear
                });
                // If the stored time is yesterday or earlier, clear all stored values
                if (nowDayOfYear !== storedTimeDayOfYear) {
                  onCheckout();
                } else {
                  const placeObj = locations[storedPlaceID] || {};
                  setPlace(placeObj);
                  setCheckinTime(storedTime);
                }
              }
            });
          }
        );
      })
      .catch((error) => {
        console.log("ERROR!!!");
        console.error(error);
      });
    // Fetch all signs
    Util.fetchGoogleSheetData(
      "1kxZZQZM-DNEbQf9c4K0UeHvKOdyeYeU9fmdquO85fpk",
      "All Signs"
    )
      .then((data) => {
        const signs = {};
        data.forEach((signData) => {
          signs[signData.ID] = signData;
        });
        setAllSigns(signs);
        SecureStore.getItemAsync("placeCheckInSign").then((storedSignID) => {
          SecureStore.getItemAsync("placeCheckInLapStartTime").then(
            (storedTime) => {
              if (storedTime) {
                const storedTimeET = moment(storedTime, "x").subtract(
                  5,
                  "hours"
                ); // Eastern Time
                const storedTimeDayOfYear = storedTimeET.dayOfYear();
                Analytics.logEvent("action_placeCheckIn_fetchedStoredPlace", {
                  storedSignID,
                  storedTime,
                  nowDayOfYear,
                  storedTimeDayOfYear
                });
                // If the stored time is yesterday or earlier, clear all stored values
                if (nowDayOfYear !== storedTimeDayOfYear) {
                  onCheckout();
                } else {
                  const signObj = signs[storedSignID] || {};
                  setSign(signObj);
                  setLapStartTime(storedTime);
                }
              }
            }
          );
        });
      })
      .catch((error) => {
        console.log("ERROR!!!");
        console.error(error);
      });
    // setInterval(() => {
    //   setCurrentTime(moment());
    // }, 1000);
  }, []);

  const onBarCodeScanned = ({ data }) => {
    if (scanningQRCode) return;
    setScanningQRCode(true);
    setScanningProblem(false);
    setTimeout(() => {
      setScanningQRCode(false);
      setScanningProblem(false);
    }, 500);
    const userIsCheckedIn = !!place && !!checkinTime;

    if (data.slice(0, 11) !== '{"placeID":') {
      setScanningProblem(true);
    }

    try {
      JSON.parse(data);
    } catch (error) {
      setScanningProblem(true);
      return;
    }

    Analytics.logEvent("action_placeCheckIn_scannedQRCode", {
      data,
      userIsCheckedIn
    });

    const { placeID, signID } = JSON.parse(data);
    const now = moment().format("x");
    const placeObj = allPlaces[placeID] || {};

    let signObj;
    let signPlaceID;
    if (signID) {
      signObj = allSigns[signID] || {};
      signPlaceID = signObj?.LocationID || place?.ID || placeID;
    }
    const userIsAtNewPlace = signPlaceID !== place?.ID;

    // Check user in if they are not already checked in OR if they are checked in at a different resort
    if (!userIsCheckedIn || userIsAtNewPlace) {
      setPlace(placeObj);
      const fields = [
        fullName,
        email,
        "Checked in",
        placeObj?.Name || "UNKNOWN",
        Firebase.getUserID(),
        placeObj?.ID,
        (placeObj || {}).UphillStatus
      ];
      Util.fillStaticGoogleForm("uphillNewEnglandCheckIn", fields);
      setCheckinTime(now);
      SecureStore.setItemAsync("placeCheckInLocation", placeID);
      SecureStore.setItemAsync(
        "placeCheckInLocationName",
        placeObj?.Name || "UNKNOWN"
      );
      SecureStore.setItemAsync("placeCheckInTime", now);
      onCheckIn(placeObj, now);
      setJustCheckedIn(true);
      setTimeout(() => {
        // Wait 1 second to align the haptics with the confetti animation
        if (Platform.OS !== "web")
          Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      }, 1000);
    }

    // Start or finish a lap if the QR code includes a signID
    if (signID) {
      if (signObj?.RouteID && signObj?.Elevation && signObj?.Side) {
        let elevationGain = null;
        let shouldLog = false;
        // If this is the start, log the start of vert IF the user hasn't started a lap OR the user's lap is at a resort they were previously at
        if (signObj?.Side === "Start" && (!sign?.ID || userIsAtNewPlace)) {
          shouldLog = true;
          setSign(signObj);
          setLapStartTime(now);
          SecureStore.setItemAsync("placeCheckInSign", signID);
          SecureStore.setItemAsync(
            "placeCheckInRouteName",
            signObj?.RouteName || "UNKNOWN"
          );
          SecureStore.setItemAsync("placeCheckInLapStartTime", now);
        }
        // If this is the finish sign and the user had started a lap already
        else if (signObj?.Side === "Finish" && sign?.ID) {
          // And if this sign is at the same resort as where they started their lap, log the vert
          if (!userIsAtNewPlace) {
            elevationGain =
              Number(signObj?.Elevation) - Number(sign?.Elevation);
            shouldLog = true;
            setAlert({
              title: `Vertical Gain Logged! ${Util.randomCelebratoryEmoji()}`,
              message: `You gained ${elevationGain} feet on ${signObj?.RouteName}. Great work! Check out your "My Stats" screen to see your progress.`
            });
          }
          // But even if this sign is at a different resort, we still want to clear out that old sign
          setSign(null);
          SecureStore.deleteItemAsync("placeCheckInSign");
          SecureStore.deleteItemAsync("placeCheckInRouteName");
          SecureStore.deleteItemAsync("placeCheckInLapStartTime");
        }

        if (shouldLog) {
          const fields = [
            Firebase.getUserID(),
            fullName,
            email,
            placeObj?.Name || "UNKNOWN",
            placeObj?.ID || "UNKNOWN",
            signID,
            signObj?.RouteName || "UNKNOWN",
            signObj?.RouteID,
            signObj?.Elevation,
            signObj?.Side === "Finish" ? "Finish" : "Start",
            `${elevationGain || ""}`,
            userHasMembership ? "True" : "False"
          ];
          Util.fillStaticGoogleForm("uphillNewEnglandVertLog", fields);
          if (Platform.OS !== "web")
            Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
        }
      }
    }
  };

  const onCheckout = () => {
    Analytics.logEvent("action_placeCheckIn_checkout");
    SecureStore.deleteItemAsync("placeCheckInLocation");
    SecureStore.deleteItemAsync("placeCheckInLocationName");
    SecureStore.deleteItemAsync("placeCheckInTime");
    SecureStore.deleteItemAsync("placeCheckInSign");
    SecureStore.deleteItemAsync("placeCheckInRouteName");
    SecureStore.deleteItemAsync("placeCheckInLapStartTime");
    setPlace(null);
    setSign(null);
    setCheckinTime(null);
    setLapStartTime(null);
    setScanningQRCode(false);
  };

  if (hasPermission === null) {
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={SCREEN_NAME} />
        <View style={{ alignItems: "center" }}>
          <Text style={styles.providePermissionsText}>
            Requesting camera permission...
          </Text>
        </View>
      </View>
    );
  }

  if (hasPermission === false) {
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={SCREEN_NAME} />
        <View style={{ alignItems: "center" }}>
          <Text style={styles.providePermissionsText}>
            Please enable camera permission in order to use this feature.
          </Text>
        </View>
      </View>
    );
  }

  if (place && checkinTime) {
    // const checkinDateTime = moment(checkinTime, "x");
    // const checkinDurationSeconds = currentTime.diff(checkinDateTime, "seconds");
    // const checkinDurationString = Util.secondsToFriendlyString(
    //   checkinDurationSeconds
    // );
    const status = (place || {}).UphillStatus;
    let statusColor = "#787878";
    let statusColorBackground = "#e6e6e6"; // a light version of statusColor for the background
    if (status === "Open") {
      statusColor = "#15803d";
      statusColorBackground = "#bbf7d0";
    } else if (status === "Restricted") {
      statusColor = "#c2410c";
      statusColorBackground = "#ffedd5";
    } else if (status === "Closed") {
      statusColor = "#b91c1c";
      statusColorBackground = "#fee2e2";
    }
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text={SCREEN_NAME} />
        <BarCodeScanner onBarCodeScanned={onBarCodeScanned} style={{ flex: 1 }}>
          <View style={styles.qrIcon}>
            <Ionicons
              name="scan"
              size={180}
              color={scanningProblem ? "red" : "white"}
            />
            {sign?.ID ? (
              <View
                style={{
                  backgroundColor: "rgba(0,0,0,0.4)",
                  padding: 20,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10
                }}
              >
                <Text
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 16,
                    textAlign: "center"
                  }}
                >
                  Your lap is in progress
                  {sign?.RouteName ? ` on ${sign?.RouteName}` : ""}
                </Text>
                <Text style={{ color: "white", textAlign: "center" }}>
                  Scan the QR code at the top of this route to finish & log your
                  vertical gain 🏆
                </Text>
              </View>
            ) : (
              <Text style={styles.qrHelperTextSmall}>
                Scan a slope's QR code to start a lap 🏔️
              </Text>
            )}
          </View>
        </BarCodeScanner>
        <View
          style={{
            flex: 2,
            backgroundColor: "white",
            shadowOpacity: 0.2,
            shadowRadius: 8
          }}
        >
          <ScrollView
            contentContainerStyle={{ alignItems: "center" }}
            style={{
              paddingHorizontal: 15,
              backgroundColor: "#e6f2ff",
              shadowOpacity: 0.2,
              shadowRadius: 8
            }}
            scrollIndicatorInsets={{ right: 1 }}
          >
            <LottieView
              style={{ position: "absolute", height: "100%", width: "100%" }}
              autoPlay
              loop
              source={SNOW_ANIMATION}
            />
            <View style={{ width: "100%", alignItems: "center" }}>
              <StatusMessage
                type={userHasMembership ? "success" : "info"}
                message={`${
                  userHasMembership ? "C" : `${fullName} c`
                }hecked in at ${moment(checkinTime, "x").format(
                  "h:mm A on MMM D"
                )}`}
              />
            </View>
            <View
              style={[
                Style.get("card"),
                {
                  alignItems: "center",
                  backgroundColor: statusColorBackground
                }
              ]}
            >
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: Rex.getConfig()?.colors?.text
                }}
              >
                Mountain Status
              </Text>
              <Text
                style={{
                  fontWeight: "bold",
                  color: statusColor,
                  fontSize: 20,
                  textAlign: "center"
                }}
              >
                {status}
              </Text>
              <Text
                style={{
                  color: statusColor,
                  fontSize: 16,
                  textAlign: "center",
                  marginTop: 16
                }}
              >
                {(place || {}).StatusDetails}
              </Text>
              <Text
                style={{
                  color: "#888",
                  fontSize: 16,
                  textAlign: "center",
                  marginTop: 16
                }}
              >
                Status last updated on {(place || {}).LastUpdated}
              </Text>
            </View>
            <View style={[Style.get("card"), { alignItems: "center" }]}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: Rex.getConfig()?.colors?.text
                }}
              >
                Policies
              </Text>
              <Text
                style={{
                  color: statusColor,
                  fontSize: 16,
                  textAlign: "center"
                }}
              >
                {(place || {}).PoliciesOverview}
              </Text>
              <View style={{ marginTop: 15 }}>
                <ButtonItem
                  item={{
                    type: "button_nav",
                    title: "Full Policies & Info",
                    portal: place?.Screen,
                    color: statusColor,
                    flat: true,
                    small: true
                  }}
                  navigate={navigation.push}
                />
              </View>
            </View>
            <View style={[Style.get("card"), { alignItems: "center" }]}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: Rex.getConfig()?.colors?.text
                }}
              >
                Passholder Benefits
              </Text>
              <Text
                style={{
                  color: statusColor,
                  fontSize: 16,
                  textAlign: "center"
                }}
              >
                {(place || {}).Promotions}
              </Text>
            </View>
            {userHasMembership ? (
              <View style={{ marginTop: 10, width: "100%" }}>
                <DigitalID
                  name="Passholder"
                  subtitle={fullName}
                  bottomText="This pass is valid for uphill skiing on all mountains affiliated with Uphill New England."
                  email={email}
                  image={Glob.get("uphillNewEnglandLogoBadge")}
                  color="#55b9c6"
                />
              </View>
            ) : (
              <View
                style={[
                  Style.get("card"),
                  {
                    alignItems: "center",
                    borderWidth: 3,
                    borderColor: "#55b9c6"
                  }
                ]}
              >
                <Text style={{ fontSize: 16, textAlign: "center" }}>
                  Purchase the multi-mountain uphill pass to gain access to 12+
                  resorts across New England.
                </Text>
                <Button
                  text="Join Now"
                  onPress={() => {
                    navigation.push("webNav", {
                      url: "https://www.uphillnewengland.org",
                      title: "Join Now"
                    });
                  }}
                  style={{ marginTop: 10 }}
                  color="#55b9c6"
                  flat
                  small
                />
              </View>
            )}

            <View
              style={{
                alignItems: "center",
                paddingBottom: 30,
                paddingTop: 5,
                width: "100%"
              }}
            >
              <Button text="Check Out" onPress={onCheckout} small flat />
              {/* <Text>
                You checked in at {checkinDateTime.format("h:mm A")} on{" "}
                {checkinDateTime.format("dddd, MMMM D")}
              </Text> */}
            </View>

            {/* <Text style={{ fontSize: 16, textAlign: "center" }}>
              You have been here for{"\n"}
              {checkinDurationString}
            </Text> */}
          </ScrollView>
        </View>
        {justCheckedIn && (
          <ConfettiCannon
            count={200}
            origin={{ x: width / 2, y: 0 }}
            autoStart
            fadeOut
          />
        )}
        <AlertModal alert={alert} setAlert={setAlert} />
      </View>
    );
  }

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text={SCREEN_NAME} />
      <BarCodeScanner
        onBarCodeScanned={scanningQRCode ? undefined : onBarCodeScanned}
        style={{ flex: 1 }}
      >
        <View style={styles.qrIcon}>
          <Ionicons
            name="scan"
            size={300}
            color={scanningProblem ? "red" : "white"}
          />
          <Text style={styles.qrHelperText}>
            Scan a resort's QR code to check in.
          </Text>
        </View>
      </BarCodeScanner>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    backgroundColor: "white"
  },
  fullName: {
    fontSize: 40,
    fontWeight: "bold",
    textAlign: "center"
  },
  qrIcon: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center"
  },
  qrHelperText: {
    fontSize: 24,
    color: "white",
    width: "60%",
    textAlign: "center"
  },
  qrHelperTextSmall: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
    width: "90%",
    textAlign: "center"
  },
  providePermissionsText: {
    fontSize: 24,
    width: "60%",
    textAlign: "center"
  }
});
