import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  Text,
  TouchableOpacity,
  Platform,
  ActivityIndicator
} from "react-native";
import indefinite from "indefinite";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import NavBar from "src/components/navBar";
import SearchBar from "src/components/searchBar";
import Button from "src/components/Button";
import Dropdown from "src/components/Dropdown";
import ExportUsersButton from "src/components/ExportUsersButton";
import Checkbox from "src/components/Checkbox";

const { width } = Glob.get("dimensions");
const ALL_GROUPS_VALUE = "ALL_GROUPS";
const ALL_ACCOUNT_TYPES_VALUE = "ALL_ACCOUNT_TYPES";

const UserBadge = ({ text, color = "black" }) => (
  <View
    style={{
      borderColor: color,
      borderWidth: 1,
      borderRadius: 5,
      paddingHorizontal: 8,
      paddingVertical: 2
    }}
  >
    <Text style={{ fontSize: 12, color }}>{text}</Text>
  </View>
);

const User = ({
  user,
  navigation,
  onUserUpdated,
  onUserDeleted,
  disableUserEditing,
  onPress,
  hideBadge = false,
  renderCustomTopContent = null,
  showCheckbox = false,
  userIsChecked = false
}) => (
  <TouchableOpacity
    style={{
      marginBottom: 7,
      paddingBottom: 7,
      marginHorizontal: 10,
      borderBottomWidth: 1,
      borderColor: "lightgray",
      flexDirection: "row"
    }}
    disabled={showCheckbox && !!user.privileges?.EditAllPortals}
    onPress={() => {
      if (onPress) onPress({ user });
      else
        navigation.push("manageUser", {
          user,
          onUserUpdated,
          onUserDeleted,
          disableUserEditing,
          renderCustomTopContent
        });
    }}
  >
    {showCheckbox && (
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <Checkbox
          checked={userIsChecked || user.privileges?.EditAllPortals}
          disabled={!!user.privileges?.EditAllPortals}
          onChange={() => {}}
        />
      </View>
    )}
    {user?.email ? (
      <View>
        <Text style={{ ...Style.get("headerText"), color: "#4F4F4F" }}>
          {user?.firstName} {user?.lastName}
        </Text>
        <Text style={Style.get("subheaderText")}>{user?.email}</Text>
      </View>
    ) : (
      <View>
        <Text
          style={{ ...Style.get("headerText"), color: Glob.get("dangerRed") }}
        >
          Unknown Member
        </Text>
        <Text style={Style.get("subheaderText")}>
          This account may have been created in error.
        </Text>
      </View>
    )}
    {!hideBadge && (
      <View
        style={{
          position: "absolute",
          right: 0,
          height: "100%",
          justifyContent: "center"
        }}
      >
        {user?.isInvitation ? (
          <UserBadge text="Invited" color={Glob.get("onespotOrange")} />
        ) : (
          <>
            {!!user?.privileges && (
              <UserBadge
                text={`${!user?.privileges.ManageUsers ? "Limited " : ""}${
                  user?.isSuperAdmin ? "Super " : ""
                }Admin`}
                color={Glob.get("onespotGreen")}
              />
            )}
            {user?.uid === Glob.get("demoAccountUserID") && (
              <UserBadge text="Demo" color="gray" />
            )}
          </>
        )}
      </View>
    )}
  </TouchableOpacity>
);

export default function UserList({
  navigation,
  hideInvitations = false,
  onPress = null,
  hideTopSummary = false,
  filteredUserIDs, // array
  filteredUserIDsToExclude, // array
  showCheckboxes = false,
  userIDsChecked, // object in this format: { userID: true/false }
  filteredMessageSuffix,
  disableUserEditing = false,
  hideUserBadges = false,
  navBarTitle = null,
  onChangeFilteredUsersPhrase = () => {},
  processFilteredUsers = () => {},
  shouldCallProcessFilteredUsers = false,
  onChangeNumberOfUsers = () => {},
  renderCustomTopContent = null
}) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userInvitationsExist, setUserInvitationsExist] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [allAccountTypes, setAllAccountTypes] = useState(null);
  const [allAccountTypesDropdown, setAllAccountTypesDropdown] = useState(null);
  const [allGroups, setAllGroups] = useState(null);
  const [allGroupIDsInOrder, setAllGroupIDsInOrder] = useState(null);
  const [filterGroupID, setFilterGroupID] = useState(ALL_GROUPS_VALUE);
  const [filterAccountTypeID, setFilterAccountTypeID] = useState(
    ALL_ACCOUNT_TYPES_VALUE
  );
  const [filterAdminStatus, setFilterAdminStatus] = useState("all");
  const [filterInvitationStatus, setFilterInvitationStatus] = useState("all");

  useEffect(() => {
    loadAllUsers();
    Database.getAllUserGroupsDisplayOrder().then((displayOrder) => {
      setAllGroupIDsInOrder(displayOrder || []);
    });
    Database.getAllUserGroups().then((groups) => {
      setAllGroups(groups);
    });
    Database.fetchAllAccountTypeDetails().then((accountTypes) => {
      if (accountTypes) {
        const accountTypeIDs = Object.keys(accountTypes);
        // Note: This doesn't handle legacy database structure
        setAllAccountTypes(accountTypes);
        setAllAccountTypesDropdown(
          accountTypeIDs.map((acct) => ({
            value: acct,
            text: accountTypes[acct]?.title || acct
          }))
        );
      }
    });
  }, []);

  const loadAllUsers = async () => {
    const allUsers = await Database.fetchAllUsers();
    let allUsersFormatted = allUsers.map((u) => ({
      ...u,
      searchableText: `${u.firstName} ${u.lastName} ${u.email}`
    }));
    if (userIDsChecked) {
      // Show all checked users first
      allUsersFormatted.sort((a1, a2) => {
        if (userIDsChecked[a1.uid] && !userIDsChecked[a2.uid]) return -1;
        if (!userIDsChecked[a1.uid] && userIDsChecked[a2.uid]) return 1;
        return 0;
      });
    }
    const privileges = await Database.fetchUserPrivileges(false);
    const canManageUsers = privileges?.ManageUsers;
    if (canManageUsers) {
      const allUserInvitations = await Database.fetchAllUserInvitations();
      if (allUserInvitations?.length > 0 && !hideInvitations) {
        setUserInvitationsExist(true);
        const allUserInvitationsFormatted = allUserInvitations.map((u) => ({
          ...u,
          searchableText: `${u.firstName} ${u.lastName} ${u.email}`,
          isInvitation: true
        }));
        allUsersFormatted = [
          ...allUsersFormatted,
          ...allUserInvitationsFormatted
        ].sort((a1, a2) => {
          if (userIDsChecked) {
            // If we have checkboxes, show all checked users first
            if (userIDsChecked[a1.uid] && !userIDsChecked[a2.uid]) return -1;
            if (!userIDsChecked[a1.uid] && userIDsChecked[a2.uid]) return 1;
          }
          // sort alphabetically
          if (a1.firstName < a2.firstName) return -1;
          if (a1.firstName > a2.firstName) return 1;
          return 0;
        });
      }
    }
    setLoading(false);
    setUsers(allUsersFormatted);
  };

  const onUserUpdated = (newUser) => {
    const newUsers = users.map((u) => (u.uid === newUser.uid ? newUser : u));
    setUsers(newUsers);
  };

  const onUserDeleted = (userID) => {
    const newUsers = users.filter((u) => u.uid !== userID);
    setUsers(newUsers);
  };

  const topContainerShadow =
    Platform.OS === "web" ? { boxShadow: "0px 5px 5px rgba(0,0,0,0.2)" } : {};

  let usersFiltered = users;
  if (filterAccountTypeID !== ALL_ACCOUNT_TYPES_VALUE)
    usersFiltered = users.filter((u) => u.type === filterAccountTypeID);
  if (filterGroupID !== ALL_GROUPS_VALUE)
    usersFiltered = usersFiltered.filter(
      (u) => u.groups && u.groups[filterGroupID]
    );
  if (filterAdminStatus === "admin")
    usersFiltered = usersFiltered.filter((u) => !!u.privileges);
  else if (filterAdminStatus === "non-admin")
    usersFiltered = usersFiltered.filter((u) => !u.privileges);
  if (filterInvitationStatus === "current")
    usersFiltered = usersFiltered.filter((u) => !u.isInvitation);
  else if (filterInvitationStatus === "invited")
    usersFiltered = usersFiltered.filter((u) => u.isInvitation);

  if (filteredUserIDs?.length > 0) {
    usersFiltered = usersFiltered.filter((u) =>
      filteredUserIDs.includes(u.uid)
    );
  }

  if (filteredUserIDsToExclude?.length > 0) {
    usersFiltered = usersFiltered.filter(
      (u) => !filteredUserIDsToExclude.includes(u.uid)
    );
  }

  const didFilterAccountTypes = filterAccountTypeID !== ALL_ACCOUNT_TYPES_VALUE;
  const didFilterGroups = filterGroupID !== ALL_GROUPS_VALUE;
  const didFilterAdminStatus = filterAdminStatus !== "all";
  const didFilterInvitationStatus = filterInvitationStatus !== "all";
  const didFilter =
    didFilterAccountTypes ||
    didFilterGroups ||
    filteredUserIDs?.length > 0 ||
    filteredUserIDsToExclude?.length > 0 ||
    didFilterAdminStatus ||
    didFilterInvitationStatus;
  const numberOfUsers = didFilter ? usersFiltered.length : users.length;
  const filterAccountTypeName = allAccountTypes
    ? (
        allAccountTypes[filterAccountTypeID]?.title ||
        filterAccountTypeID ||
        ""
      ).toLowerCase()
    : "";
  if (searchPhrase)
    usersFiltered = Util.searchItems(
      usersFiltered,
      searchPhrase,
      "searchableText"
    );

  const filteredUsersPhrase = `${
    didFilterInvitationStatus ? `${filterInvitationStatus} ` : ""
  }${didFilter ? "" : "total "}${
    didFilterAdminStatus ? `${filterAdminStatus}` : "member"
  }${numberOfUsers === 1 ? "" : "s"}${
    didFilterAccountTypes
      ? ` with ${
          numberOfUsers === 1
            ? indefinite(filterAccountTypeName)
            : filterAccountTypeName
        } account${numberOfUsers === 1 ? "" : "s"}`
      : ""
  }${
    didFilterGroups
      ? ` in the group "${allGroups[filterGroupID]?.name || filterGroupID}"`
      : ""
  }${filteredMessageSuffix ? ` ${filteredMessageSuffix}` : ""}`;

  useEffect(() => {
    onChangeFilteredUsersPhrase(filteredUsersPhrase);
  }, [filteredUsersPhrase]);

  useEffect(() => {
    onChangeNumberOfUsers(numberOfUsers);
  }, [numberOfUsers]);

  useEffect(() => {
    if (shouldCallProcessFilteredUsers) processFilteredUsers(usersFiltered);
  }, [shouldCallProcessFilteredUsers]);

  if (loading)
    return (
      <>
        {!!navBarTitle && <NavBar navigation={navigation} text={navBarTitle} />}
        <ActivityIndicator size="large" style={{ marginTop: 20 }} />
      </>
    );

  return (
    <>
      {!!navBarTitle && (
        <NavBar
          navigation={navigation}
          text={navBarTitle}
          RightButton={
            Platform.OS === "web" ? (
              <ExportUsersButton
                title={didFilter ? "Filtered Members" : "All Members"}
                users={usersFiltered}
              />
            ) : null
          }
        />
      )}
      <View
        style={{
          width: "100%",
          backgroundColor: "white",
          paddingBottom: 10,
          shadowOpacity: 0.15,
          shadowRadius: 5,
          shadowOffset: { width: 0, height: 8 },
          elevation: 7,
          ...topContainerShadow
        }}
      >
        {!hideTopSummary ? (
          <View style={{ width: "80%", alignSelf: "center" }}>
            <Text
              style={[
                Style.get("headerText"),
                { fontSize: 80, textAlign: "center", marginTop: -10 }
              ]}
            >
              {numberOfUsers}
            </Text>
            <Text
              style={[
                Style.get("subheaderText"),
                {
                  textAlign: "center",
                  marginTop: Platform.OS === "web" ? -10 : -15
                }
              ]}
            >
              {filteredUsersPhrase}
            </Text>
          </View>
        ) : (
          <View style={{ marginTop: 20 }} />
        )}
        {!showFilters ? (
          <Button
            text="Show filters"
            icon="cf8121e5-b4bf-4b32-96cb-fd059c1bd913" // filter
            small
            outline
            flat
            style={{ marginBottom: 0 }}
            onPress={() => {
              Analytics.logEvent("touch_userList_showFilters");
              setShowFilters(true);
            }}
          />
        ) : (
          <>
            {!!allAccountTypesDropdown && (
              <Dropdown
                center
                value={filterAccountTypeID}
                items={[
                  {
                    value: ALL_ACCOUNT_TYPES_VALUE,
                    text: "Any account type"
                  },
                  ...allAccountTypesDropdown
                ]}
                onSelect={(value) => {
                  Analytics.logEvent("touch_userList_filterAccountType", {
                    value
                  });
                  setFilterAccountTypeID(value);
                }}
              />
            )}
            {!!allGroupIDsInOrder && !!allGroups && (
              <Dropdown
                center
                value={filterGroupID}
                items={[
                  { value: ALL_GROUPS_VALUE, text: "Any group" },
                  ...allGroupIDsInOrder.map((g) => ({
                    value: g,
                    text: allGroups[g]?.name || g
                  }))
                ]}
                onSelect={(value) => {
                  Analytics.logEvent("touch_userList_filterGroup", {
                    value
                  });
                  setFilterGroupID(value);
                }}
              />
            )}
            <Dropdown
              center
              value={filterAdminStatus}
              items={[
                {
                  value: "all",
                  text: "All admins & non-admins"
                },
                {
                  value: "admin",
                  text: "Admins"
                },
                {
                  value: "non-admin",
                  text: "Non-admins"
                }
              ]}
              onSelect={(value) => {
                Analytics.logEvent("touch_userList_filterAdminStatus", {
                  value
                });
                setFilterAdminStatus(value);
              }}
            />
            {userInvitationsExist && (
              <Dropdown
                center
                value={filterInvitationStatus}
                items={[
                  {
                    value: "all",
                    text: "All current & invited members"
                  },
                  {
                    value: "current",
                    text: "Current"
                  },
                  {
                    value: "invited",
                    text: "Invited"
                  }
                ]}
                onSelect={(value) => {
                  Analytics.logEvent("touch_userList_filterInvitationStatus", {
                    value
                  });
                  setFilterInvitationStatus(value);
                }}
              />
            )}
          </>
        )}
        <SearchBar
          placeholder="Search by name or email"
          onChangeText={setSearchPhrase}
        />
      </View>
      <FlatList
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="always"
        style={styles.rowList}
        contentContainerStyle={{ paddingBottom: 200 }}
        scrollIndicatorInsets={{ right: 1 }}
        data={usersFiltered}
        renderItem={({ item }) => (
          <User
            user={item}
            navigation={navigation}
            onUserUpdated={onUserUpdated}
            onUserDeleted={onUserDeleted}
            onPress={onPress}
            disableUserEditing={disableUserEditing}
            hideBadge={hideUserBadges}
            renderCustomTopContent={renderCustomTopContent}
            showCheckbox={!!showCheckboxes}
            userIsChecked={userIDsChecked && userIDsChecked[item.uid]}
          />
        )}
        keyExtractor={(item) => item.uid}
      />
    </>
  );
}

const styles = StyleSheet.create({
  rowList: {
    width,
    height: "100%",
    paddingTop: 10
  }
});
