import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity
} from "react-native";
import indefinite from "indefinite";
import { MaterialIcons } from "@expo/vector-icons";
import LottieView from "src/components/Lottie";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import AlertModal from "src/components/AlertModal";
import IconPicker from "src/components/IconPicker";
import UserTypeButton from "src/components/UserTypeButton";
import Checkbox from "src/components/Checkbox";

const USER_GRAPH_ANIMATION = require("resources/animations/userGraph.json");

const { width } = Glob.get("dimensions");

const AccountType = ({
  type,
  details,
  passcode,
  updatePasscode,
  deleteAccountType,
  allowedToEditSecurity
}) => {
  return (
    <View style={[Style.get("card"), { paddingTop: 0 }]}>
      <View style={{ alignItems: "center", marginTop: 20 }}>
        <IconPicker
          icon={details.icon}
          onSelectIcon={(icon) =>
            Database.setAccountTypeDetails(type, { ...details, icon })
          }
        />
      </View>
      <InputBox
        placeholder="Title"
        onChangeText={(title) =>
          Database.setAccountTypeDetails(type, { ...details, title })
        }
        value={details.title}
        style={{ marginTop: 0, alignItems: "center", marginBottom: 10 }}
      />
      {!!allowedToEditSecurity && (
        <Checkbox
          key={`locked-checkbox-${type}`}
          checked={details.locked}
          text={details.locked ? "🔒 Secure" : "🔓 Public"}
          onChange={(locked) =>
            Database.setAccountTypeDetails(type, { ...details, locked })
          }
        />
      )}
      {!!allowedToEditSecurity && details.locked && (
        <InputBox
          key={`passcode-${type}`}
          header="Set a passcode"
          placeholder="Passcode"
          description={`You'll have to give this secret password to anyone who you want to be able to join your app as ${
            details.title
              ? indefinite(details.title).toLowerCase()
              : "this type"
          }. You can change it at any time.`}
          value={passcode}
          onChangeText={updatePasscode}
        />
      )}
      <TouchableOpacity
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          backgroundColor: "white",
          padding: 8
        }}
        onPress={() => deleteAccountType(type)}
      >
        <MaterialIcons name="delete" size={26} color={Glob.get("dangerRed")} />
      </TouchableOpacity>
    </View>
  );
};

export default function EditAccountTypes({ navigation }) {
  const [accountTypes, setAccountTypes] = useState(null);
  const [accountTypePasscodes, setAccountTypePasscodes] = useState(null);
  const [isAddingAccountType, setIsAddingAccountType] = useState(false);
  const [alert, setAlert] = useState(null);
  const allAccountTypes = Glob.getAllAccountTypes()
    .filter((type) => !type.hideOption)
    .sort((a, b) => a.buttonText > b.buttonText);
  const adminPrivileges = Rex.getSessionMemory("adminPrivileges");
  const allowedToEditSecurity =
    !!adminPrivileges &&
    adminPrivileges?.includes("Publish") && // This is necessary to edit the security node
    Rex.getConfig()?.granularSecurityEnabled;

  useEffect(() => {
    Analytics.logEvent("view_editAccountTypes");
    Database.addTask("viewAccountTypes", "done");
    Database.subscribeToAccountTypes(setAccountTypes);
    Database.setAccountTypesIfLegacyDb();
    if (allowedToEditSecurity)
      Database.fetchSecurityInfo().then((security) => {
        setAccountTypePasscodes(security?.accountTypes);
      });

    // cleanup called when component is unmounting
    return () => {
      Database.unsubscribeFromAccountTypes();
    };
  }, []);

  if (!accountTypes)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Account Types" />
        <View style={styles.loadingContainer}>
          <Text style={styles.loadingTextLarge}>Loading...</Text>
        </View>
      </View>
    );

  const deleteAccountType = (accountTypeID) => {
    Analytics.logEvent("touch_editAccountTypes_pressDeleteAccountType", {
      accountTypeID
    });
    const title = accountTypes[accountTypeID]?.title;
    if (Object.keys(accountTypes).length < 2) {
      setAlert({
        title: "You can't delete all account types",
        message: `Your app needs to have at least one account type, so you would need to make a new account type before you can delete this ${
          title ? title.toLowerCase() : ""
        } type.${"\n\n"}Note that if there's only one account type, we won't actually show it to your users when they sign up; we will just assume they are signing up as ${
          title ? indefinite(title).toLowerCase() : "this type"
        }.`,
        confirm: { text: "Got it" }
      });
    } else {
      setAlert({
        title: "Are you sure?",
        message: `Deleting this account type will mean that every member who's currently ${
          title ? indefinite(title).toLowerCase() : "this type"
        } will be switched to a default type.`,
        confirm: { text: "Cancel" },
        cancel: {
          text: "💥 Delete",
          onPress: () => {
            Analytics.logEvent(
              "touch_editAccountTypes_confirmDeleteAccountType",
              {
                accountTypeID
              }
            );
            Database.setAccountTypeDetails(accountTypeID, null);
          }
        }
      });
    }
  };

  const addAccountType = (type) => {
    const { buttonText, iconName } = type;
    Analytics.logEvent("touch_editAccountTypes_addAccountType", {
      title: buttonText,
      icon: iconName
    });
    const defaultPortals =
      Object.values(accountTypes)[0]?.defaultPortals || null;
    Database.addAccountType({
      title: buttonText,
      icon: iconName,
      defaultPortals
    });
    setIsAddingAccountType(false);
  };

  const updatePasscode = (type, passcode) => {
    const newAccountTypePasscodes = {
      ...accountTypePasscodes,
      [type]: { lockedPasscode: passcode }
    };
    setAccountTypePasscodes(newAccountTypePasscodes);
    Database.setSecurityInfo({ accountTypes: newAccountTypePasscodes });
  };

  const allAccountButtonRows = [];
  let temporaryRow = [];
  allAccountTypes.forEach((type, idx) => {
    const { key } = type;
    if (idx % 4 === 3) {
      temporaryRow.push(
        <UserTypeButton
          key={key}
          backgroundColor="white"
          textColor="gray"
          type={key}
          selected
          onPress={() => addAccountType(type)}
        />
      );
      allAccountButtonRows.push(
        <View style={styles.buttonRowContainer}>{temporaryRow}</View>
      );
      temporaryRow = [];
    } else {
      temporaryRow.push(
        <UserTypeButton
          key={key}
          backgroundColor="white"
          textColor="gray"
          type={key}
          selected
          onPress={() => addAccountType(type)}
        />
      );
    }
  });
  if (temporaryRow.length > 0) {
    allAccountButtonRows.push(
      <View style={styles.buttonRowContainer}>{temporaryRow}</View>
    );
  }

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Account Types" />

      <ScrollView
        style={{ paddingHorizontal: 15, width }}
        showsVerticalScrollIndicator={false}
        keyboardDismissMode="on-drag"
      >
        <View style={{ alignItems: "center", marginBottom: 20 }}>
          {/* <LottieView
            autoPlay
            loop
            style={{ width: "100%" }}
            source={USER_GRAPH_ANIMATION}
          /> */}
        </View>
        <Text style={Style.get("headerText")}>
          Edit which account types users can be
        </Text>
        <Text style={[Style.get("subheaderText"), { marginBottom: 20 }]}>
          When new users join your app, they select an account type. This
          affects their default portal configuration and also lets you send
          notifications to specific account types.
        </Text>

        {Object.entries(accountTypes).map(([key, details]) => (
          <AccountType
            type={key}
            details={details}
            passcode={
              accountTypePasscodes && accountTypePasscodes[key]?.lockedPasscode
            }
            deleteAccountType={deleteAccountType}
            allowedToEditSecurity={allowedToEditSecurity}
            updatePasscode={(passcode) => updatePasscode(key, passcode)}
          />
        ))}
        {isAddingAccountType ? (
          <View>
            <Text style={Style.get("headerText")}>
              Select an account type template to add
            </Text>
            <Text style={[Style.get("subheaderText"), { marginBottom: 20 }]}>
              You can fully customize it once you've added it.
            </Text>
            {allAccountButtonRows}
          </View>
        ) : (
          <Button
            text="+ New Account Type"
            style={{ marginBottom: 20 }}
            onPress={() => setIsAddingAccountType(true)}
          />
        )}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  buttonRowContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 10
  },
  loadingContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20
  },
  loadingTextLarge: {
    fontSize: 30,
    marginVertical: 20,
    color: "gray"
  }
});
