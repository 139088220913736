import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from "react-native";
import LottieView from "src/components/Lottie";
import {
  Feather,
  FontAwesome,
  FontAwesome5,
  AntDesign,
  MaterialCommunityIcons,
  Octicons
} from "@expo/vector-icons";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import Constants from "expo-constants";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import Firebase from "src/backend/firebase";
import NavBar from "src/components/navBar";
import SettingsItem from "src/components/SettingsItem";
import Icon from "src/components/Icon";
import HelpButton from "src/components/HelpButton";
import Util from "src/utility";
import OnespotProBadge from "src/components/OnespotProBadge";

const { height, width } = Glob.get("dimensions");
const NAVBAR_TEXT = "Superpowers";
const PERKS_LINK_SCHOOLS =
  "https://airtable.com/appA5ArU7RlSXz1tq/shrn7Tx9snEpMTMDQ";
const PERKS_LINK_NONSCHOOLS =
  "https://airtable.com/appA5ArU7RlSXz1tq/shrE8EeE5kNSmvufY";
const PUBLISHED_VERSION = Constants.expoConfig.extra.publishedVersion;

const MEDITATION_ANIMATION = require("resources/animations/meditationBooks.json");

const Subheader = ({ text, isPro = false }) => {
  return (
    <Text
      style={[styles.textSubheader, { color: Rex.getConfig()?.colors?.text }]}
    >
      {text} {isPro && <OnespotProBadge />}
    </Text>
  );
};

export default function Admin({ route, navigation }) {
  const [userIsCreator, setUserIsCreator] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [billing, setBilling] = useState(null);
  const [commerceIsConfigured, setCommerceIsConfigured] = useState(false);
  const [tutorial, setTutorial] = useState(
    Rex.getSessionMemory("currentTutorialTask")
  );
  const [isSchool, setIsSchool] = useState(false);
  const {
    adminPrivileges,
    turnOffAdminPrivileges,
    checkRefresh,
    onUpdatePortal
  } = route?.params || {};
  const userHasPublishPrivilege = (adminPrivileges || []).includes("Publish");
  const userHasManageUsersPrivilege = (adminPrivileges || []).includes(
    "ManageUsers"
  );

  useEffect(() => {
    Analytics.logEvent("view_admin", { adminPrivileges });
    Database.addTask("viewAdmin", "done");
    Database.fetchAppMetadata().then((appMetadata) => {
      setUserIsCreator(appMetadata?.creator === Firebase.getUserID());
    });
    Database.fetchAllUserData().then((data) => {
      setUserEmail(data?.email);
    });
    Database.fetchBillingInfo().then((billingInfo) => {
      setBilling(billingInfo);
    });
    Database.fetchPrimaryMetaApp().then(({ key }) => {
      if (key === "montessori" || key === "onespotk12") setIsSchool(true);
    });
    Database.fetchCommerce().then(async (commerce) => {
      if (commerce?.config?.stripeConnect?.accountID) {
        const stripeAccount = await Database.fetchStripeConnectedAccount();
        const accountIsConfigured =
          stripeAccount?.charges_enabled && stripeAccount?.payouts_enabled;
        setCommerceIsConfigured(accountIsConfigured);
      }
    });
  }, []);

  const showPaywall = () => {
    navigation.push("onespotCreatorOnboarding", {
      shouldPublish: false,
      title: "Ready to make your app?"
    });
  };

  const onPressPaywalledItem = (screen, props = {}) => {
    Rex.setSessionMemory("currentTutorialTask", null);
    setTutorial(null);
    const showSubscriptionPaywall =
      Rex.getConfig()?.showSubscriptionPaywall || false;
    if (showSubscriptionPaywall && screen !== "publish") showPaywall();
    else {
      Analytics.logEvent("touch_admin_item", { screen });
      navigation.push(screen, props);
    }
  };

  const billingIsStripe = !!billing?.subscription?.stripeID;
  let revenueCatSubscriptionManagementURL = null;
  if (
    !billingIsStripe &&
    billing?.customer?.details?.originalAppUserId === Firebase.getUserID()
  )
    revenueCatSubscriptionManagementURL =
      billing?.customer?.details?.managementURL;

  const renderAdminList = () => {
    const allPrivileges = Glob.get("allAdminPrivileges");
    if (
      !Rex.getConfig()?.textMessagingEnabled &&
      !Rex.getConfig()?.phoneCallsEnabled
    ) {
      delete allPrivileges.TextAndCall;
    }
    if (!Rex.getConfig()?.commerceEnabled) {
      delete allPrivileges.Commerce;
    } else if (!commerceIsConfigured) {
      // If the user has not yet finished setting up their Stripe Connect account
      allPrivileges.Commerce.name = "Start selling & accepting payments";
      allPrivileges.Commerce.icon = (
        <Icon
          icon="154bd4cf-4afb-45f3-9da9-7c8f791a5c40"
          color="#5D5D5C"
          size={22}
        />
      );
    } else {
      allPrivileges.Commerce.name = "Settings & dashboard";
      allPrivileges.Commerce.icon = (
        <Icon icon="money" color="#5D5D5C" size={22} />
      );
    }
    const allPrivilegeCategories = Glob.get("allAdminPrivilegeCategories");

    const privilegesSorted = [...adminPrivileges].sort((priv1, priv2) => {
      if (
        Object.keys(allPrivileges).includes(priv1) &&
        Object.keys(allPrivileges).includes(priv2)
      )
        return allPrivileges[priv1].sortOrder - allPrivileges[priv2].sortOrder;
      return Infinity;
    });

    const privilegesByCategory = {};
    privilegesSorted.forEach((privilege) => {
      if (privilege in allPrivileges) {
        const { category } = allPrivileges[privilege];
        if (category in privilegesByCategory)
          privilegesByCategory[category].push(privilege);
        else privilegesByCategory[category] = [privilege];
      }
    });
    const components = [];
    allPrivilegeCategories.forEach((category) => {
      if (category in privilegesByCategory) {
        components.push(
          <>
            <Subheader
              text={category}
              isPro={category === "Onespot Payments"}
            />
            {privilegesByCategory[category].map((privilege) => (
              <>
                {privilege === "Commerce" && !!commerceIsConfigured && (
                  <>
                    <SettingsItem
                      text="Products & services"
                      icon={
                        <Icon
                          icon="61a6848a-8f51-4edc-9d3d-ab70c90887a8"
                          color="#5D5D5C"
                          size={22}
                        />
                      }
                      onPress={() => onPressPaywalledItem("commerceProducts")}
                    />
                  </>
                )}
                <SettingsItem
                  text={allPrivileges[privilege].name}
                  icon={allPrivileges[privilege].icon}
                  onPress={() =>
                    onPressPaywalledItem(allPrivileges[privilege].screen, {
                      checkRefresh,
                      onUpdatePortal
                    })
                  }
                  showNotification={tutorial?.privilege === privilege}
                />
                {privilege === "ManageUsers" && (
                  <SettingsItem
                    text="Invite members"
                    icon={
                      <Icon
                        icon="76874264-4548-4dac-b620-e9014d0ac94d"
                        color="#5D5D5C"
                        size={22}
                      />
                    }
                    onPress={() =>
                      onPressPaywalledItem("inviteUsers", {
                        checkRefresh
                      })
                    }
                    showNotification={tutorial?.privilege === "INVITE_MEMBERS"}
                  />
                )}
                {privilege === "EditAllPortals" && (
                  <SettingsItem
                    text="New screen"
                    icon={
                      <Icon
                        icon="6a91910e-2aa0-4999-adc6-a15e8c9a15a9"
                        color="#5D5D5C"
                        size={22}
                      />
                    }
                    onPress={() =>
                      onPressPaywalledItem("editPortal", {
                        onUpdatePortal
                      })
                    }
                    showNotification={tutorial?.privilege === "MAKE_NEW_SCREEN"}
                  />
                )}
                {privilege === "PushNotifications" && (
                  <SettingsItem
                    text="Notification history"
                    icon={
                      <Icon
                        icon="2afaba6c-c639-4b75-8144-06778549a2c4"
                        color="#5D5D5C"
                        size={22}
                      />
                    }
                    onPress={() => onPressPaywalledItem("notificationHistory")}
                  />
                )}
              </>
            ))}
          </>
        );
      }
    });
    return components;
  };

  const showAnalyticsSection =
    userHasManageUsersPrivilege &&
    (Database.userIsSuperAdmin() || !!Rex.getConfig()?.analyticsEnabled);

  return (
    <View style={{ flex: 1, backgroundColor: "white" }}>
      <NavBar
        navigation={navigation}
        text={NAVBAR_TEXT}
        RightButton={
          <View style={{ alignItems: "flex-end" }}>
            <HelpButton
              title="Your Superpowers"
              message="This screen gives you all the tools you need to manage your app and engage your community!"
              navigation={navigation}
              videoUrl="https://youtu.be/5MFwveFGDbc"
            />
          </View>
        }
      />
      <ScrollView
        contentContainerStyle={{ alignItems: "center" }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View style={{ width, height: height / 4, alignItems: "center" }}>
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop
            source={MEDITATION_ANIMATION}
          />
        </View>
        <View style={styles.headerTextContainer}>
          <Text
            style={[
              styles.textHeader,
              { color: Rex.getConfig()?.colors?.text }
            ]}
          >
            Administrative Superpowers
          </Text>
          <Text style={styles.textDescription}>
            All your special admin privileges to help you manage and engage your
            community.
          </Text>
        </View>

        <View style={styles.sectionDivider} />

        <View style={styles.sectionTextBoxesContainer}>
          {showAnalyticsSection && (
            <>
              {Rex.getConfig()?.analyticsEnabled ? (
                <Subheader text="Analytics" isPro />
              ) : (
                <Subheader text="🕶 Analytics (super admins only)" />
              )}
              <SettingsItem
                text="App usage"
                icon={
                  <MaterialCommunityIcons
                    name="chart-donut"
                    size={22}
                    color={Glob.get("gray")}
                  />
                }
                onPress={() => navigation.push("appAnalytics")}
              />
              {!!Rex.getConfig()?.advancedAnalyticsLink && (
                <SettingsItem
                  text="Advanced analytics"
                  icon={
                    <AntDesign
                      name="linechart"
                      size={22}
                      color={Glob.get("gray")}
                    />
                  }
                  onPress={() => {
                    navigation.push("webNav", {
                      title: "Advanced Analytics",
                      url: Rex.getConfig()?.advancedAnalyticsLink
                    });
                  }}
                />
              )}
            </>
          )}
          {renderAdminList()}
          {!!billing &&
            userHasPublishPrivilege &&
            (billingIsStripe || !!revenueCatSubscriptionManagementURL) && (
              <SettingsItem
                text="Billing"
                icon={
                  <FontAwesome
                    name="credit-card"
                    size={22}
                    color={Glob.get("gray")}
                  />
                }
                onPress={() =>
                  Util.openURL(
                    billingIsStripe
                      ? `${Glob.get(
                          "stripeCustomerPortalLink"
                        )}?prefilled_email=${userEmail}`
                      : revenueCatSubscriptionManagementURL
                  )
                }
              />
            )}
          <Subheader text="Help" />
          <SettingsItem
            text={userIsCreator ? "Tutorial" : "Tutorials"}
            icon={<AntDesign name="book" size={22} color={Glob.get("gray")} />}
            onPress={() =>
              navigation.push("tutorial", {
                hideProgress: !userIsCreator,
                setTutorialToCurrentTask: () =>
                  setTutorial(Rex.getSessionMemory("currentTutorialTask"))
              })
            }
          />
          <SettingsItem
            text="Help me!"
            icon={
              <FontAwesome5
                name="question-circle"
                size={22}
                color={Glob.get("gray")}
              />
            }
            onPress={() => navigation.push("help")}
          />
          <Subheader text="More" />
          <SettingsItem
            text="View app as member"
            icon={
              <FontAwesome5 name="eye" size={22} color={Glob.get("gray")} />
            }
            onPress={() =>
              navigation.push("viewAsMember", { turnOffAdminPrivileges })
            }
          />
          {Platform.OS !== "web" && (
            <SettingsItem
              text="Open app on your computer"
              icon={
                <MaterialCommunityIcons
                  name="desktop-mac"
                  size={22}
                  color={Glob.get("gray")}
                />
              }
              onPress={() => {
                Database.fetchPrimaryMetaApp().then((metaApp) => {
                  if (metaApp?.webLink) {
                    Util.alert(
                      "Manage from your computer",
                      "Open the web version of your app in your phone's browser. Then, copy the page link and send it to your computer so you can edit your app from there. Make sure to bookmark the page for easy access!",
                      [
                        {
                          text: "Cancel",
                          onPress: () => {},
                          style: "cancel"
                        },
                        {
                          text: "Open in Browser",
                          onPress: () => {
                            Analytics.logEvent("touch_admin_openInBrowser", {
                              url: metaApp.webLink
                            });
                            Util.openURL(metaApp.webLink);
                          }
                        }
                      ]
                    );
                  } else
                    Util.alert(
                      "No web version",
                      "Sorry, no web version available."
                    );
                });
              }}
            />
          )}
          <SettingsItem
            text="Onespot perks & plugins"
            icon={<Feather name="gift" size={22} color={Glob.get("gray")} />}
            onPress={() => {
              navigation.push("webNav", {
                title: "Perks & Plugins",
                url: isSchool ? PERKS_LINK_SCHOOLS : PERKS_LINK_NONSCHOOLS
              });
            }}
          />
          <SettingsItem
            text={`Onespot update history (currently on #${PUBLISHED_VERSION})`}
            icon={<Octicons name="rocket" size={22} color={Glob.get("gray")} />}
            onPress={() => {
              navigation.push("webNav", {
                title: "Update History",
                url: Glob.get("onespotChangelogURL")
              });
            }}
          />
          {Database.userIsSuperAdmin() && (
            <SettingsItem
              text="Super admin zone"
              icon={
                <MaterialCommunityIcons
                  name="lightning-bolt-circle"
                  size={22}
                  color={Glob.get("gray")}
                />
              }
              onPress={() => navigation.push("superAdminDashboard")}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  /* Style for the container holding the section header text */
  headerTextContainer: {
    paddingHorizontal: 15,
    marginTop: 0.03 * height,
    width
  },

  /* Style for a section header text */
  textHeader: {
    fontSize: 24,
    marginBottom: 8
  },

  /* Style for a section subheader text */
  textSubheader: {
    fontSize: 17,
    marginTop: 10,
    opacity: 0.7
  },

  /* Style for the upper description text */
  textDescription: {
    fontSize: 14,
    color: "gray",
    marginBottom: 15
  },

  /* Style for a section of text boxes */
  sectionTextBoxesContainer: {
    paddingHorizontal: 15,
    marginBottom: 20,
    width
  },

  /* Style for a divider to go between sections on the page */
  sectionDivider: {
    height: 1,
    width,
    backgroundColor: "rgba(206, 206, 206, 1)"
  },

  /* Style for a container to go around a full-width rectangular button */
  buttonContainer: {
    flexDirection: "column",
    alignItems: "center",
    marginTop: 0.03 * height
  }
});
