import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import moment from "moment";
import Icon from "src/components/Icon";

export default function Message({
  msg,
  navigation,
  isNotifications = false,
  justUpdated = false
}) {
  const numberSentTo = isNotifications
    ? msg?.numberUsersSentTo || 0
    : msg?.phoneNumbers?.length || 0;
  return (
    <View
      style={{
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: "#ccc"
      }}
    >
      <View
        key={msg.timestamp}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              marginRight: 10
            }}
          >
            {moment(msg.timestamp).year() === moment().year()
              ? moment(msg.timestamp).format("MMM D • h:mm a")
              : moment(msg.timestamp).format("MMM D, YYYY • h:mm a")}
          </Text>
          {!!msg.url && (
            <TouchableOpacity
              onPress={() =>
                navigation.push("webNav", {
                  title: msg?.title || "Link",
                  url: msg.url
                })
              }
            >
              <Icon
                icon="link"
                color="black"
                size={18}
                style={{ marginRight: 6 }}
              />
            </TouchableOpacity>
          )}
          {!!msg.sentPushNotification && (
            <Icon
              icon="46a76db7-22f8-4366-bc36-b24a669ed8a5" // notification
              color="black"
              size={18}
              style={{ marginRight: 6 }}
            />
          )}
          {!!msg.sentEmail && (
            <Icon
              icon="email"
              color="black"
              size={18}
              style={{ marginRight: 6 }}
            />
          )}
          {!!msg.sentTextMessage && (
            <Icon
              icon="ca1e2481-ebbc-4de1-858c-e65d8194ee7b" // chatbubble-outline
              color="black"
              size={18}
              style={{ marginRight: 6 }}
            />
          )}
          {!!msg.sentPhoneCall && (
            <Icon
              icon="495f4f3e-5f15-4376-83e6-05365e07950c" // call-outline
              color="black"
              size={18}
              style={{ marginRight: 6 }}
            />
          )}
        </View>
        <View style={{ alignItems: "flex-end" }}>
          <Text
            style={{ color: justUpdated ? "#54d66f" : "#646464", fontSize: 14 }}
          >
            Sent to{" "}
            <Text style={{ fontWeight: "bold" }}>
              {numberSentTo.toLocaleString()}
            </Text>{" "}
            {numberSentTo !== 1 ? "people" : "person"}
          </Text>
        </View>
      </View>
      <Text
        style={{
          color: "#646464",
          fontSize: 14,
          fontStyle: "italic"
        }}
      >
        {msg.message}
      </Text>
    </View>
  );
}
