import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator
} from "react-native";
import * as Haptics from "expo-haptics";
import ConfettiCannon from "react-native-confetti-cannon";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Util from "src/utility";
import School from "school/school";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import ButtonSwitches from "src/components/buttonSwitches";
import ButtonItem from "src/components/dynamicContent/ButtonItem";
import InputBox from "src/components/InputBox";
import HelpText from "src/components/HelpText";

const APP_VERSION_SUPPORTS_IAP = Database.compareVersions(
  Glob.get("appVersion"),
  Glob.get("revenueCatMinimumAppVersion")
);
const SHOW_IN_APP_PURCHASE =
  Glob.get("appIsOnespot") && APP_VERSION_SUPPORTS_IAP;

const { height, width } = Glob.get("dimensions");
const randomCelebratoryEmoji = Util.randomCelebratoryEmoji();

export default function Publish({ navigation }) {
  const [isPublic, setIsPublic] = useState(null);
  const [isLocked, setIsLocked] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const [justPublished, setJustPublished] = useState(false);
  const [billing, setBilling] = useState(null);
  const [communityType, setCommunityType] = useState("community");

  const databaseAppID = School.getDatabaseAppID();
  const nameOfStandaloneApp =
    Rex.getMetaApp()?.title && !Glob.get("appIsOnespot")
      ? Rex.getMetaApp()?.title
      : "Onespot";
  const publishingDisabled = Rex.getMetaApp()?.appCreation?.publishingDisabled;

  useEffect(() => {
    Analytics.logEvent("view_publish");
    Database.fetchAppMetadata().then((appMetadata) => {
      setIsPublic(appMetadata?.public);
      setIsLocked(appMetadata?.locked);
    });
    Database.fetchSecurityInfo().then((security) => {
      const { lockedPasscode } = security || {};
      setPasscode(lockedPasscode);
    });
    Database.fetchBillingInfo().then((billingInfo) => {
      setBilling(billingInfo);
    });
    Database.fetchAppCommunityType().then((type) => {
      setCommunityType(type);
    });
  }, []);

  const togglePublish = (shouldPublish) => {
    if (shouldPublish) {
      Analytics.logEvent("touch_publish_publish");
      Database.addTask("publishApp", "done");
    } else {
      Analytics.logEvent("touch_publish_unpublish");
    }
    Database.setAppMetadata({ public: shouldPublish });
    setIsPublic(shouldPublish);
    if (shouldPublish) {
      if (Platform.OS !== "web")
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      setJustPublished(true);
      // Remove the confetti after it falls so it doesn't slow down rendering
      setTimeout(() => {
        setJustPublished(false);
      }, 5000);
    }
  };

  const toggleLocked = (shouldLock) => {
    if (shouldLock) Analytics.logEvent("touch_publish_lock");
    else Analytics.logEvent("touch_publish_unlock");
    Database.setAppMetadata({ locked: shouldLock });
    setIsLocked(shouldLock);
  };

  const updatePasscode = (newPasscode) => {
    setPasscode(newPasscode);
    Database.setSecurityInfo({ lockedPasscode: newPasscode.toUpperCase() });
  };

  let appLockedHeader = "Your app is public";
  if (isLocked) {
    if (!passcode) appLockedHeader = "Add a passcode";
    else appLockedHeader = "Your app is locked with a passcode";
  }

  let appLockedDescription = `Anyone can access your app from within ${nameOfStandaloneApp}, including visitors or people who are interested in checking out your organization.`;
  if (isLocked) {
    if (!passcode)
      appLockedDescription =
        "Write a passcode to make sure random visitors can't access your app.";
    else
      appLockedDescription = `No one can access your app unless they first enter the passcode "${passcode}"`;
  }

  const mustSubscribeToPublish =
    !isPublic && !billing && (Glob.get("appIsOnespot") || publishingDisabled);

  if (mustSubscribeToPublish && !communityType?.key)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Publishing & Privacy" />
      </View>
    );

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Publishing & Privacy" />

      {isPublic === null || isLocked === null ? (
        <ActivityIndicator size="large" style={{ marginTop: 80 }} />
      ) : (
        <ScrollView
          style={{ paddingHorizontal: 15, width }}
          scrollIndicatorInsets={{ right: 1 }}
          keyboardDismissMode="on-drag"
        >
          <View style={{ marginTop: 0.03 * height }} key="publishSection">
            <Text style={Style.get("headerText")}>
              {isPublic
                ? `Your app is live! ${randomCelebratoryEmoji}`
                : "Ready to go live?"}
            </Text>
            {mustSubscribeToPublish ? (
              <>
                {publishingDisabled ? (
                  <Text style={Style.get("subheaderText")}>
                    {`Reach out to the Onespot team to publish ${
                      Rex.getConfig()?.names?.full
                    } within the ${nameOfStandaloneApp} app.`}
                  </Text>
                ) : (
                  <>
                    {Platform.OS === "web" ? (
                      <>
                        <Text style={Style.get("subheaderText")}>
                          {`Let's publish ${
                            Rex.getConfig()?.names?.full
                          } to the world!`}
                        </Text>
                        <Button
                          text="Subscribe & publish 🚀"
                          onPress={() => {
                            Analytics.logEvent("touch_publish_subscribeButton");
                            navigation.push("subscribePay", {
                              communityType:
                                communityType?.key === "montessoriSchool"
                                  ? "school"
                                  : communityType?.key
                            });
                          }}
                          style={{ marginTop: 20 }}
                        />
                      </>
                    ) : (
                      <>
                        {SHOW_IN_APP_PURCHASE ? (
                          <>
                            <Text style={Style.get("subheaderText")}>
                              {`Let's launch ${
                                Rex.getConfig()?.names?.full
                              } to the world!`}
                            </Text>
                            <Button
                              text="Subscribe & publish 🚀"
                              onPress={() => {
                                Analytics.logEvent(
                                  "touch_publish_subscribeButton"
                                );
                                navigation.push("onespotCreatorOnboarding", {
                                  shouldPublish: true
                                });
                              }}
                            />
                            <HelpText
                              text={`Your app isn't yet published. This means that people can only find ${
                                Rex.getConfig()?.names?.full
                              } if you invite them or share it with them directly, and they can only use it in their web browser.`}
                            />
                            <View style={{ marginTop: 30 }}>
                              <Text style={Style.get("headerText")}>
                                Check out your web app too
                              </Text>
                              <Text style={Style.get("subheaderText")}>
                                {`Log into your ${
                                  Rex.getConfig()?.names?.full
                                } app here to manage it on Onespot Web.`}
                              </Text>
                              <ButtonItem
                                navigate={navigation.push}
                                item={{
                                  type: "button_web",
                                  url: `https://www.1spot.app?app=${databaseAppID}`,
                                  title: "www.1spot.app",
                                  openExternally: true,
                                  outline: true
                                }}
                              />
                            </View>
                          </>
                        ) : (
                          <>
                            {/* Legacy code to handle Onespot versions lower than 17.3.0 */}
                            <Text style={Style.get("subheaderText")}>
                              {`Log into Onespot's web platform to publish ${
                                Rex.getConfig()?.names?.full
                              } to the world.`}
                            </Text>
                            <ButtonItem
                              navigate={navigation.push}
                              item={{
                                type: "button_web",
                                url: `https://www.1spot.app?app=${databaseAppID}`,
                                title: "www.1spot.app",
                                openExternally: true
                              }}
                            />
                            <HelpText
                              text={`Your app isn't yet published. This means that people can only find ${
                                Rex.getConfig()?.names?.full
                              } if you invite them or share it with them directly, and they can only use it in their web browser.`}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {Database.userIsSuperAdmin() && (
                  <View style={{ marginTop: 30 }} key="secretSection">
                    <Text style={Style.get("headerText")}>
                      🕶 Only visible to super admins:
                    </Text>
                    <Button
                      text="🕶 Publish app"
                      outline
                      onPress={() => togglePublish(true)}
                      style={{ marginTop: 20 }}
                    />
                  </View>
                )}
              </>
            ) : (
              <>
                <Text style={Style.get("subheaderText")}>
                  {isPublic
                    ? `Congrats! Your app is available for your whole community to join. Just tell your members to install ${nameOfStandaloneApp} and then to join the Spot called "${
                        Rex.getConfig()?.names?.full
                      }".`
                    : `Publish your app within ${nameOfStandaloneApp} by switching it to "Published". This will make it available for anyone to join. It's currently "Unpublished", so only you can access it (and anyone else who has already joined it).`}
                </Text>
                <ButtonSwitches
                  initialButtonState={isPublic ? "right" : "left"}
                  leftOption="Unpublished"
                  rightOption="Published"
                  leftAction={() => togglePublish(false)}
                  rightAction={() => togglePublish(true)}
                />
              </>
            )}
          </View>

          {isPublic && (
            <Button
              text="Share your app"
              onPress={() => navigation.push("shareApp")}
            />
          )}

          {isPublic && (
            <View style={{ marginTop: 30 }} key="lockedSection">
              <Text style={Style.get("headerText")}>{appLockedHeader}</Text>
              <Text style={styles.formDescription}>{appLockedDescription}</Text>
              <ButtonSwitches
                initialButtonState={isLocked ? "left" : "right"}
                leftOption="Private"
                rightOption="Public"
                leftAction={() => toggleLocked(true)}
                rightAction={() => toggleLocked(false)}
              />
              {isLocked && (
                <InputBox
                  key="passcode"
                  header="Passcode"
                  placeholder="ABC123"
                  description="You'll have to give this secret password to anyone who you want to be able to enter your app. You can change it at any time."
                  value={passcode}
                  onChangeText={updatePasscode}
                />
              )}
            </View>
          )}

          <View
            style={{ marginTop: 30, marginBottom: 40 }}
            key="standaloneSection"
          >
            <Text style={Style.get("headerText")}>Have questions?</Text>
            <Text style={Style.get("subheaderText")}>
              We're always happy to help with anything you need.
            </Text>
            <Button
              text="✨ Instant Chat ✨"
              outline
              onPress={() => {
                Analytics.logEvent("touch_publish_chatMessage");
                Util.startChatWithSuperAdmin(navigation, true);
              }}
            />
            <Button
              text="Email Us"
              type="email"
              outline
              options={{ emailAddress: "team@seabirdapps.com" }}
            />
            <Button
              text="Call Us"
              type="call"
              outline
              options={{ phoneNumber: "4407817739" }}
            />
            <Button
              text="Text Us"
              type="sms"
              outline
              options={{
                smsRecipient: "4407817739",
                smsContent: `Hey, Sean! I want help publishing my app called "${
                  Rex.getConfig()?.names?.full
                }". Here's the link to my app:\nhttps://www.1spot.app?app=${databaseAppID}`
              }}
            />
          </View>
        </ScrollView>
      )}
      {justPublished && (
        <ConfettiCannon
          count={200}
          origin={{ x: width / 2, y: height }}
          autoStart
          fadeOut
        />
      )}
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  formDescription: {
    marginTop: 20,
    marginBottom: 5
  }
});
