import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Button from "src/components/Button";
import HelpText from "src/components/HelpText";
import AlertModal from "src/components/AlertModal";
import Product from "src/components/Product";

const { height, width } = Glob.get("dimensions");

export default function CommerceProducts({ navigation }) {
  const [loadedProducts, setLoadedProducts] = useState(false);
  const [products, setProducts] = useState(null);
  const [isEditingAProduct, setIsEditingAProduct] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_commerceProducts");
    fetchStripeProducts();
  }, []);

  const fetchStripeProducts = async () => {
    const stripeProducts = await Database.fetchStripeConnectedAccountProducts();
    if (stripeProducts?.data && Array.isArray(stripeProducts.data))
      setProducts(stripeProducts.data);
    setLoadedProducts(true);
  };

  const createProduct = () => {
    Analytics.logEvent("touch_commerceProducts_startCreatingProduct");
    setIsEditingAProduct(true);
    setProducts((oldProducts) => {
      return [
        ...(oldProducts || []),
        {
          name: "",
          price: 0,
          description: "",
          shippable: false,
          isNew: true,
          isEditing: true
        }
      ];
    });
  };

  const onStartEditingProduct = (productID) => {
    Analytics.logEvent("touch_commerceProducts_startEditingProduct", {
      productID
    });
    setIsEditingAProduct(true);
    setProducts((oldProducts) => {
      return oldProducts.map((oldProduct) => {
        const newProduct = { ...oldProduct };
        if (oldProduct.id === productID) {
          newProduct.isEditing = true;
        }
        return newProduct;
      });
    });
  };

  const onCancelEditingProduct = (productID, isNew = false) => {
    Analytics.logEvent("touch_commerceProducts_cancelEditingProduct", {
      productID
    });
    setIsEditingAProduct(false);
    if (isNew) {
      // Remove the new product
      setProducts((oldProducts) => {
        return oldProducts.filter((oldProduct) => !oldProduct.isNew);
      });
    } else {
      // Mark all products as not being edited
      setProducts((oldProducts) => {
        return oldProducts.map((oldProduct) => ({
          ...oldProduct,
          isEditing: false
        }));
      });
    }
  };

  const onSaveProduct = async (product) => {
    Analytics.logEvent("touch_commerceProducts_saveProduct", {
      name: product?.name,
      description: product?.description,
      shippable: product?.shippable,
      price: product?.price,
      isEditing: product?.isEditing,
      isNew: product?.isNew,
      productID: product?.productID || "new"
    });
    setIsEditingAProduct(false);
    setLoadedProducts(false);
    if (product.isNew) {
      await Database.stripeConnectedAccountCreateProduct({
        name: product?.name || "New Product",
        description: product?.description || "",
        shippable: product?.shippable || false,
        price: product?.price ? parseFloat(product.price) * 100 : 100
      });
    } else if (product.isEditing && product.productID) {
      await Database.stripeConnectedAccountEditProduct(product.productID, {
        ...(product.name ? { name: product.name } : {}),
        ...(product.description ? { description: product.description } : {}),
        ...(product.shippable ? { shippable: product.shippable } : {}),
        ...(product.price ? { price: parseFloat(product.price) * 100 } : {})
      });
    }
    fetchStripeProducts();
  };

  const onPressDeleteProduct = (productID) => {
    Analytics.logEvent("touch_commerceProducts_pressDeleteProduct");
    setAlert({
      title: "Delete this product?",
      message:
        "This will archive the product. Make sure you're not using it in any screens.",
      cancel: {
        text: "✋ Cancel",
        onPress: () => {}
      },
      confirm: {
        text: "💥 Delete",
        onPress: () => archiveProduct(productID)
      }
    });
  };

  const archiveProduct = async (productID) => {
    Analytics.logEvent("touch_commerceProducts_archiveProduct", {
      productID
    });
    setIsEditingAProduct(false);
    setLoadedProducts(false);
    await Database.stripeConnectedAccountEditProduct(productID, {
      active: false
    });
    fetchStripeProducts();
  };

  if (!loadedProducts)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Products & Services" />
        <ScrollView style={{ paddingHorizontal: 20, width }}>
          <ActivityIndicator size="large" style={{ marginTop: 80 }} />
        </ScrollView>
      </View>
    );

  if (!products || products.length < 1)
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="Products & Services" />
        <ScrollView
          style={{ paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <Image
            source={Glob.get("cardboardBoxOpen")}
            style={{
              height: height * 0.3,
              width: height * 0.3,
              resizeMode: "contain",
              alignSelf: "center"
            }}
          />
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 24,
              textAlign: "center"
            }}
          >
            No products or services yet
          </Text>
          <Button
            text="Add a product/service"
            style={{ marginTop: 70 }}
            textStyle={{ fontWeight: "bold" }}
            icon="plus"
            color={Glob.get("primaryColor")}
            onPress={createProduct}
          />
        </ScrollView>
      </View>
    );

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar navigation={navigation} text="Products & Services" />
      <ScrollView style={{ width }} scrollIndicatorInsets={{ right: 1 }}>
        <HelpText
          text={
            <Text>
              Touch any product to edit it. You can add any of these products to
              screens in your app.{"\n\n"}
              <Text style={{ fontWeight: "bold" }}>Important:</Text> To comply
              with Apple & Google app store requirements, you can only sell
              goods or services that will be consumed{" "}
              <Text style={{ fontStyle: "italic" }}>outside</Text> of your app
              (like t-shirts, event tickets, etc.). You cannot charge users to
              unlock content within your app.
            </Text>
          }
          noteStyle
          style={{
            marginTop: 20,
            marginHorizontal: 20,
            opacity: isEditingAProduct ? 0.3 : 1
          }}
        />
        {isEditingAProduct ? (
          <View style={{ marginBottom: 50 }}>
            {products.map(
              ({
                name,
                default_price,
                description,
                shippable,
                images,
                isEditing,
                isNew,
                id
              }) => (
                <Product
                  key={id}
                  productID={id}
                  name={name}
                  price={isNew ? 0 : default_price?.unit_amount}
                  description={description}
                  shippable={shippable}
                  image={images?.length > 0 ? images[0] : null}
                  isEditing={isEditing}
                  isNew={isNew}
                  isEditingAProduct={isEditingAProduct}
                  onSave={onSaveProduct}
                  onArchive={() => onPressDeleteProduct(id)}
                  onCancel={() => onCancelEditingProduct(id, isNew)}
                />
              )
            )}
          </View>
        ) : (
          <View style={{ marginBottom: 50 }}>
            {products.map(
              ({ name, default_price, description, shippable, images, id }) => (
                <TouchableOpacity
                  onPress={() => {
                    onStartEditingProduct(id);
                  }}
                >
                  <Product
                    key={id}
                    productID={id}
                    name={name}
                    price={default_price?.unit_amount}
                    description={description}
                    shippable={shippable}
                    image={images?.length > 0 ? images[0] : null}
                  />
                </TouchableOpacity>
              )
            )}
          </View>
        )}
        {!isEditingAProduct && (
          <Button
            text="Add another"
            style={{ marginBottom: 50 }}
            textStyle={{ fontWeight: "bold" }}
            icon="plus"
            color={Glob.get("primaryColor")}
            onPress={createProduct}
          />
        )}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  bodyText: {
    fontSize: 16,
    marginTop: 15
  }
});
