import React, { useState, useEffect, useRef } from "react";
import {
  Text,
  TextInput,
  Dimensions,
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Keyboard,
  Platform
} from "react-native";
import tinycolor from "tinycolor2";
import {
  FontAwesome,
  FontAwesome5,
  Feather,
  MaterialIcons
} from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";

const { width } = Dimensions.get("window");

export default function TextItem({
  item: originalItem,
  editable,
  onChangeItem,
  navigate,
  isEditingAnItem,
  setIsEditingAnItem = () => {},
  dataSourceRow,
  setKeyboardToolbarComponents = () => {},
  allowChangeType = true,
  backgroundColor = null
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [textInputHeight, setTextInputHeight] = useState(0);
  const [item, setItem] = useState(originalItem);
  const isHeader = item.type === "text_header";
  const fontSizeMultiplier = item.fontSizeMultiplier || 1;
  const fontSize = isHeader ? 32 : 20; // Uses the golden ratio

  const isEditingADifferentItem = isEditingAnItem && !isEditing;
  const textColor =
    backgroundColor && tinycolor(backgroundColor).isDark() ? "white" : "black";

  const isEditingRef = useRef();
  useEffect(() => {
    isEditingRef.current = isEditing;
  }, [isEditing]);

  useEffect(() => {
    const hideSubscription = Keyboard.addListener("keyboardWillHide", () => {
      const isEditingThisItem = isEditingRef.current;
      // To ensure we don't toggle editing EVERY time the keyboard is dismissed
      if (isEditingThisItem) toggleEditing(false);
    });
    return () => {
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    onChangeItem(item);
  }, [item]);

  const generateKeyboardToolbarComponents = () => {
    const components = [
      <TouchableOpacity style={styles.editingTextButton} onPress={toggleBold}>
        <FontAwesome5 name="bold" size={24} color="black" />
      </TouchableOpacity>,
      <TouchableOpacity style={styles.editingTextButton} onPress={toggleItalic}>
        <Feather name="italic" size={24} color="black" />
      </TouchableOpacity>,
      <TouchableOpacity
        style={styles.editingTextButton}
        onPress={toggleUnderline}
      >
        <FontAwesome name="underline" size={24} color="black" />
      </TouchableOpacity>,
      <TouchableOpacity
        style={styles.editingTextButton}
        onPress={() => changeFontSize(1.3)}
      >
        <Text style={{ fontSize: 26, fontWeight: "500" }}>A+</Text>
      </TouchableOpacity>,
      <TouchableOpacity
        style={styles.editingTextButton}
        onPress={() => changeFontSize(0.8)}
      >
        <Text style={{ fontSize: 18, fontWeight: "500" }}>A-</Text>
      </TouchableOpacity>,
      <TouchableOpacity
        style={styles.editingTextButton}
        onPress={() => changeTextAlign("left")}
      >
        <FontAwesome5 name="align-left" size={24} color="black" />
      </TouchableOpacity>,
      <TouchableOpacity
        style={styles.editingTextButton}
        onPress={() => changeTextAlign("center")}
      >
        <FontAwesome5 name="align-center" size={24} color="black" />
      </TouchableOpacity>,
      <TouchableOpacity
        style={styles.editingTextButton}
        onPress={() => changeTextAlign("right")}
      >
        <FontAwesome5 name="align-right" size={24} color="black" />
      </TouchableOpacity>,
      <TouchableOpacity
        style={styles.editingTextButton}
        onPress={() => toggleBulletPoints()}
      >
        <MaterialIcons name="format-list-bulleted" size={28} color="black" />
      </TouchableOpacity>
    ];
    if (allowChangeType) {
      components.unshift(
        <TouchableOpacity
          style={styles.editingTextButton}
          onPress={() => changeType("text_header")}
        >
          <FontAwesome
            name="header"
            size={24}
            color={Rex.getConfig()?.colors?.text}
          />
        </TouchableOpacity>,
        <TouchableOpacity
          style={styles.editingTextButton}
          onPress={() => changeType("text")}
        >
          <Text style={{ fontSize: 26 }}>p</Text>
        </TouchableOpacity>
      );
    }
    return components;
  };

  const toggleEditing = (startEditing) => {
    if (!startEditing) {
      Keyboard.dismiss();
    } else {
      setKeyboardToolbarComponents(generateKeyboardToolbarComponents());
    }
    setIsEditingAnItem(startEditing);
    setIsEditing(startEditing);
  };

  const changeType = (newType) =>
    setItem((prev) => ({ ...prev, type: newType }));

  const changeFontSize = (newFontSizeMultiplier) =>
    setItem((prev) => ({
      ...prev,
      fontSizeMultiplier: (prev.fontSizeMultiplier || 1) * newFontSizeMultiplier
    }));

  const changeTextAlign = (textAlign) =>
    setItem((prev) => ({ ...prev, style: { ...prev.style, textAlign } }));

  const toggleBold = () =>
    setItem((prev) => {
      if (prev?.style?.fontWeight === "bold")
        return { ...prev, style: { ...prev.style, fontWeight: "normal" } };
      return { ...prev, style: { ...prev.style, fontWeight: "bold" } };
    });

  const toggleItalic = () =>
    setItem((prev) => {
      if (prev?.style?.fontStyle === "italic")
        return { ...prev, style: { ...prev.style, fontStyle: "normal" } };
      return { ...prev, style: { ...prev.style, fontStyle: "italic" } };
    });

  const toggleUnderline = () =>
    setItem((prev) => {
      if (prev?.style?.textDecorationLine === "underline")
        return {
          ...prev,
          style: { ...prev.style, textDecorationLine: "normal" }
        };
      return {
        ...prev,
        style: { ...prev.style, textDecorationLine: "underline" }
      };
    });

  const toggleBulletPoints = () =>
    setItem((prev) => ({
      ...prev,
      bulletPoints: !prev.bulletPoints
    }));

  const style = [
    styles.mainText,
    { color: textColor },
    isHeader
      ? {
          textAlign: "center",
          fontWeight: "bold",
          marginTop: 20,
          marginBottom: 0,
          color: Rex.getConfig()?.colors?.text
        }
      : {},
    {
      fontSize: Glob.deviceHasiPhoneXDimensions()
        ? 0.75 * fontSizeMultiplier * fontSize
        : fontSizeMultiplier * fontSize
    },
    item.style
  ];

  const editingStyle = {
    width: "97%",
    alignSelf: "center",
    shadowOpacity: 1,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 200,
    elevation: 20,
    backgroundColor: "white",
    borderRadius: 5
  };
  if (Platform.OS === "web")
    editingStyle.boxShadow = "0px 0px 200px rgba(0,0,0,1)";

  if (editable && !isEditingADifferentItem) {
    const formattedText =
      !isEditing && item.bulletPoints
        ? Util.addBulletPoints(item.text)
        : item.text;
    return (
      <View removeClippedSubviews={false} style={isEditing ? editingStyle : {}}>
        <TextInput
          editable={!isEditingADifferentItem}
          placeholder="Type here..."
          multiline
          onContentSizeChange={(event) => {
            setTextInputHeight(event.nativeEvent.contentSize.height);
          }}
          scrollEnabled={false}
          style={[
            style,
            isEditing && !isHeader ? { color: "black" } : {},
            Platform.OS === "web" ? { height: textInputHeight } : {}
          ]}
          value={formattedText}
          onChangeText={(text) => setItem((prev) => ({ ...prev, text }))}
          selectionColor={Rex.getConfig()?.colors?.primary}
          onFocus={() => toggleEditing(true)}
        />
        {Platform.OS !== "ios" && isEditing && (
          <View
            style={{
              width: "100%",
              flex: 1,
              backgroundColor: "white",
              flexDirection: "row"
            }}
          >
            <ScrollView
              horizontal
              keyboardShouldPersistTaps="always"
              scrollIndicatorInsets={{ right: 1 }}
            >
              {generateKeyboardToolbarComponents()}
            </ScrollView>
            <View style={{ alignItems: "flex-end", justifyContent: "center" }}>
              <TouchableOpacity
                style={[
                  {
                    backgroundColor: Rex.getConfig()?.colors?.button,
                    borderRadius: 1000,
                    width: 36,
                    height: 36,
                    margin: 10,
                    justifyContent: "center",
                    alignItems: "center"
                  }
                ]}
                onPress={() => toggleEditing(false)}
              >
                <Text
                  style={{ fontSize: 18, color: "white", fontWeight: "bold" }}
                >
                  ✓
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {!!isEditing && !!item.bulletPoints && (
          <View style={styles.floatingIcon}>
            <MaterialIcons
              name="format-list-bulleted"
              size={32}
              color="black"
            />
          </View>
        )}
      </View>
    );
  }

  const navigateToLink = (title, url) => {
    navigate("webNav", { title, url });
  };

  return (
    <Text key={item.key} style={style}>
      {Util.textParser(item.text, {
        dataSourceRow,
        navigateToLink,
        bulletPoints: item.bulletPoints
      })}
    </Text>
  );
}

const styles = StyleSheet.create({
  mainText: {
    textAlign: "left",
    marginTop: 15,
    marginBottom: 15,
    paddingLeft: 0.05 * width,
    paddingRight: 0.05 * width
  },
  floatingIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    opacity: 0.3,
    justifyContent: "center",
    alignItems: "center"
  },
  editingTextButton: {
    marginVertical: 5,
    paddingHorizontal: 15,
    justifyContent: "center",
    alignItems: "center"
  },
  editTextToolbar: {
    padding: 5
  },
  editTextToolbarRow: {
    height: 50,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  }
});
